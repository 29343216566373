import React, { useEffect, useState } from "react";
import LanguageSelector from "./dropDownLang";
import "../contentviewer.css";
export default function TranscriptionGenerator({
   transcriptionData,
   id,
   type = "video",
   selectedLanguage,
   setSelectedLanguage,
}) {
   const [isPaused, setIsPaused] = useState(true);
   const isVideo = type === "video" ? true : false;
   const bgColor = isVideo ? "bg-color-video-caption" : "bg-color-audio-caption";
   const captionColor = isVideo ? "caption-color-video" : "caption-color-audio";
   const normalCaptionsColor = isVideo ? "normal-color-video" : "normal-color-audio";
   const element = document.getElementById(id);
   const container = document.getElementById("overflow-container");
   const containerScroll = !isPaused ? document.getElementById("overflow-container") : false;
   const [currentCaptionArr, setCurrentCaptionArr] = useState([]);

   useEffect(() => {
      if (element) {
         const handleTimeUpdate = () => {
            const captions = transcriptionData.transcription[selectedLanguage.code];
            const currentTime = element.currentTime;
            const availableTracks = element.textTracks;
            if (availableTracks) {
               for (const track of availableTracks) {
                  if (track.activeCues !== null) {
                     const objLang = {
                        code: track.activeCues[0].track.language,
                        lang: track.activeCues[0].track.label,
                     };
                     if (selectedLanguage.code !== objLang.code) {
                        setSelectedLanguage(objLang);
                     }
                  }
               }
            }

            const newCaptionIndex = captions.findIndex(
               (caption) => currentTime >= caption.start && currentTime < caption.end
            );
            if (container && newCaptionIndex > -1) {
               const currentCaptionElement = container.children[newCaptionIndex];
               if (currentCaptionElement) {
                  container.scrollTo({
                     top: currentCaptionElement.offsetTop - 260,
                     behavior: "smooth",
                     duration: "1500",
                  });
               } else {
                  container.scrollTo({
                     top: 0,
                     behavior: "smooth",
                     duration: "1500",
                  });
               }
            }
            if (newCaptionIndex > -1) {
               const arr = Array.from({ length: newCaptionIndex + 1 }, (_, index) => index);
               setCurrentCaptionArr(arr);
            }
         };
         const handleManualScroll = (event) => {
            event.preventDefault();
         };
         const videoPaused = () => {
            setIsPaused(true);
         };
         const videoPlayed = () => {
            setIsPaused(false);
         };
         element.addEventListener("timeupdate", handleTimeUpdate);
         if (containerScroll) {
            containerScroll.addEventListener("wheel", handleManualScroll);
         }
         element.addEventListener("pause", videoPaused);
         element.addEventListener("play", videoPlayed);
         return () => {
            element.removeEventListener("timeupdate", handleTimeUpdate);
            if (containerScroll) {
               containerScroll.removeEventListener("wheel", handleManualScroll);
            }
            element.removeEventListener("pause", videoPaused);
            element.removeEventListener("play", videoPlayed);
         };
      }
   }, [element, containerScroll]);

   const transcriptionList = transcriptionData.transcription[selectedLanguage.code];
   const isSingleLang = transcriptionData?.fileBlobs?.length > 1;

   return (
      <div className={` ${bgColor} px-2 h-full `}>
         <div
            className={` flex align-items-center ${
               isVideo ? "justify-content-between" : "justify-content-center"
            } pb-4 pt-3 `}
         >
            <div className="header-transcription">{isVideo && "Transcription"}</div>
            {isSingleLang && (
               <LanguageSelector
                  isVideo={isVideo}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  transcriptionData={transcriptionData}
               />
            )}
         </div>
         <div
            className={` ${isSingleLang ? " pb-[90px] " : " pb-[60px] "}     ${
               isVideo ? "custom-padding-container" : "custom-padding-container-audio"
            }  ${isVideo?"h-[90vh] sm:h-[67.3vh]":"h-[90vh] sm:h-[65vh]"} `}
            id="overflow-container"
         >
            {transcriptionList.map((s, i) => {
               const showColorCaption = currentCaptionArr.includes(i);
               return (
                  <div
                     className={` captions-default-style ${
                        showColorCaption ? captionColor : normalCaptionsColor
                     } ${bgColor} d-flex`}
                  >
                     {s.text}
                  </div>
               );
            })}
         </div>
      </div>
   );
}
