import React, { useState } from "react";
import "./switchAccountPopup.jsx";
import defaultLogo from "assets/logo-icon.png";
import dropdownArrow from "assets/Arrow.svg";
import SwitchAccountPopup from "./switchAccountPopup.jsx";
import { connect } from "react-redux";
import SwitchAccountOptions from "./switchAccountOptions.jsx";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as commenActions from "redux/actions/commonAction.js";

const SwitchAccountDroupdown = ({ datas, auth, isLoading, getAccounts, showPopup, setShowPopup }) => {
   const navigate = useNavigate();
   const [openDropdown, setOpenDropdown] = useState(false);
   const { user } = auth?.userCredentials;
   const name = user?.name || "";
   const clickHandler = (data) => {
      setShowPopup(data);
      setOpenDropdown(false);
   };

   const isArrowVisible = datas?.patientAccounts?.length + datas?.careTeam?.length > 0;

   return (
      <div class="relative inline-block text-left">
         <div className="flex justify-center items-center gap-[20px]">
            <div
               onClick={() => {
                  navigate("/");
               }}
               className="md:h-[48px] md:w-[48px] h-[42px] w-[42px] custom-shadow-logo bg-[#fff] rounded-full flex justify-center items-center cursor-pointer "
            >
               <img src={defaultLogo} alt={"imag-logo"} className="md:w-[26px] md:h-[26px] w-[20px] h-[20px]" />
            </div>
            <div className="flex items-center justify-center">
               <div
                  class={`flex items-center w-[170px] sm:w-[200px] md:[250px] lg:w-[550px] xl:w-full ${
                     isArrowVisible ? "cursor-pointer" : " "
                  }`}
                  onClick={() => {
                     if (isArrowVisible) {
                        setOpenDropdown(!openDropdown);
                     }
                  }}
               >
                  <span className="font-bold leading-[28px] md:text-[26px] text-[22px] text-[#000] tracking-[0.35px] truncate">
                     {name}
                  </span>
                  {isArrowVisible && (
                     <img src={dropdownArrow} alt={"arrow"} className=" mt-[-3px] sm:mt-0 pl-1 w-[27px] h-[27px] " />
                  )}
               </div>
            </div>
         </div>
         {openDropdown && (
            <SwitchAccountOptions
               getAccounts={getAccounts}
               accountObject={datas}
               clickHandler={clickHandler}
               setOpenDropdown={setOpenDropdown}
               isLoading={isLoading}
            />
         )}
         {showPopup !== null && (
            <SwitchAccountPopup
               data={showPopup}
               onModalTapped={() => {
                  setShowPopup(null);
               }}
               setShowPopup={setShowPopup}
            />
         )}
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      showPopup:state?.common?.showPopup
   };
};

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         setShowPopup: commenActions.showDobPopup,
      },
      dispatch
   );
};


export default connect(mapStateToProps, mapDispatchToProps)(SwitchAccountDroupdown);
