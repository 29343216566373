import React, { useEffect, useState } from "react";
import Modal from "shared/components/modal/modal";
import closeImage from "images/newdashbord/mdi_close.svg";
import SingleAppointment from "./SingleAppointment";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import { GET_DASHBOARD_APPOINTMENTS_DATA } from "../constants/DashboardConst";
import { connect } from "react-redux";
import { getAppointmentTime } from "modules/dashboard/actions/AppointmentTime";
import { AppointmentText } from "shared/constants/const";
import LoadingIndicator from "shared/components/loader";
import { showAddress } from "shared/actions/helpers";

const SeeAllAppointmentPopup = ({ onModalTapped, setShowPopup, auth }) => {
   const [appointmentList, setAppointmentList] = useState([]);
   const [loading, setLoading] = useState(true);
   const id = auth?.userCredentials?.user?.id || null;
   const getAppointmentsList = () => {
      setLoading(true);
      let obj = { offset: 0, limit: 10, options: { id: id } };
      fetchQuery(GET_DASHBOARD_APPOINTMENTS_DATA, obj, (res) => {
         let data = res?.data?.getAppointments?.appointments || [];
         setAppointmentList(data);
         setLoading(false);
      });
   };
   useEffect(() => {
      getAppointmentsList();
   }, []);

   const renderLoader = () => {
      return (
         <div 
            className="flex justify-center items-center h-[100vh]"
            onClick={(e) => {
               e.stopPropagation();
            }}
         >
            <LoadingIndicator className=" w-20 h-20" />
         </div>
      );
   };

   return (
      <Modal id="AllAppointmentPopup" onModalTapped={onModalTapped}>
         <div className="w-[350px] lg:h-[653px] h-[560px] lg:w-[960px] bg-[#fff] rounded-[20px] card-shadow lg:px-[30px] lg:pt-[20px] px-[16px] pt-[16px] flex flex-col relative ">
            <img
               src={closeImage}
               alt={"close"}
               className="absolute right-5 top-4 w-[24px] h-[24px] cursor-pointer"
               onClick={() => {
                  setShowPopup(null);
               }}
            />
            <div className="text-[#1D1D1F] font-semibold text-[26px] flex justify-start">
               {AppointmentText?.appointments}
            </div>
            <div className="divider mt-[20px] lg:ml-[-30px] md:ml-[-15px] ml-[-16px] lg:w-[106.6%] md:w-[110%] w-[110.3%] h-[1px] bg-[#E0E0E0]"></div>
            {loading ? (
               renderLoader()
            ) : (
               <div className="no-scrollbar overflow-y-auto mt-[1px] pt-[20px]">
                  {appointmentList?.map((a, index) => {
                     const color = a?.provider?.colorCode || window.initialColors[0];
                     const initialsApi = a?.provider?.name?.initials || false;
                     const name = a?.provider?.name?.fullName || "";
                     const info = a?.provider?.contactInformation || ""
                     const isAddress =  a?.provider?.contactInformation?.address !== null ? true : false
                     const hospitalName = a?.hospital?.name + " " + showAddress(info) || ""
                     const reason = a?.reason || "";
                     const mobileNumber = a?.provider?.contactInformation?.mobileNumber || "";
                     const appointmentTime = a !== null ? getAppointmentTime(a?.startDateTime) : null;
                     return (
                        <div key={index} className="card-shadow m-[1px] mb-[20px] rounded-[10px] ">
                           <SingleAppointment
                              seeAll={true}
                              color={color}
                              initialsApi={initialsApi}
                              name={name}
                              hospitalName={hospitalName}
                              reason={reason}
                              mobileNumber={mobileNumber}
                              appointmentTime={appointmentTime}
                              info={info}
                              isAddress={isAddress}
                           />
                        </div>
                     );
                  })}
               </div>
            )}
         </div>
      </Modal>
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
   };
};

export default connect(mapStateToProps, null)(SeeAllAppointmentPopup);
