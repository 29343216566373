import React from 'react'

const TableLoading = ({sharingloading}) => {
  return (
    <div className={`post-card bg-slate-500 ${sharingloading ? "mt-[30px]" : "pt-4 mt-2 "}`}>
    {Array(10)
       .fill()
       .map((index) => (
          <div
             key={index}
             className="flex animate-pulse flex-row items-center h-full justify-center space-x-1 rounded-lg "
          >
             <div className="flex flex-col space-y-3 w-full px-2 py-1 rounded-lg">
                <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
             </div>
          </div>
       ))}
 </div>
  )
}

export default TableLoading