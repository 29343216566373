import React, { useEffect, useState } from "react";
import DropdownToggle from "shared/components/dropDown";
import { dropDownOptions, options, dropDownOptionsRender } from "shared/constants/const";
import BannerCont from "modules/banner/container/bannerCont";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "actions/index";
import PanelResponsive from "./xsOption";
// import search from "images/dashboard/search.svg";
import { updateUser, getPatientBanner } from "actions/login/loginActions";
import { initiaLize } from "shared/actions/thirdPartyVendors";
import FreshChatComp from "modules/freshChat/FreshChat";
import SwitchAccountCont from "modules/switchAccount/container/switchAccountCont";

export default function Navbar(props) {
   const { auth, darkly } = props;
   const id = auth.userCredentials.user.id;
   const [banner, setBanner] = useState(null);
   const [refetch, setRefetch] = useState(false);
   const navigate = useNavigate();
   const path = window.location.pathname;
   const isSettings = path !== "/settings";
   const showNavBar = path.includes("/sharing/");
   const showBanner = banner && banner !== null;
   const showNotificationSettings = darkly.ldFeatureFlags?.graphqlNotification || false;
   const optionsToRender = showNotificationSettings ? dropDownOptionsRender : dropDownOptions;
   const getBanner = () => {
      setRefetch(true);
      if (!refetch) {
         getPatientBanner((data) => {
            setBanner(data);
         });
      }
   };

   useEffect(() => {
      checkAuth();
      updateUser(id);
   }, []);

   useEffect(() => {
      initiaLize(auth.userCredentials.user);
   }, []);

   // const openSearch = () => {
   //    document.getElementById("search-button-xs") && document.getElementById("search-button-xs").click();
   // };
   return (
      <div className={`${showNavBar && "hidden sm:block"} max-w-[1143px] mx-auto px-[16px] md:px-8 xl:px-0`}>
         <FreshChatComp user={auth.userCredentials.user} />
            <nav id="navbar" className="flex items-center justify-between w-full pb-6 pt-5">
               <SwitchAccountCont getBanner={getBanner} />
               <div className="flex items-center gap-2">
                  {options.map((map) => (
                     <div
                        key={map.id}
                        onClick={() => {
                           navigate(map.link);
                        }}
                        className={`hidden sm:flex ${
                           map.link === path ? "color-playback-base font-bold" : "font-medium"
                        } mr-7 font-17 cursor-pointer `}
                     >
                        {map.text}
                     </div>
                  ))}
{/* 
                  <img
                     onClick={openSearch}
                     src={search}
                     alt="search-icon"
                     className={`sm:hidden`}
                  /> */}
                  <DropdownToggle options={optionsToRender} />
               </div>
            </nav>
         {isSettings && <PanelResponsive path={path} navigate={navigate} />}
         {showBanner && (
            <div className="mt-3">
               <BannerCont banner={banner} />
            </div>
         )}
      </div>
   );
}
