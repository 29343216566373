import { gql } from "@apollo/client";
import { showSwal } from "shared/actions/alert";

export const GET_FAMILY_FRIENDS_DATA = gql`
   query Query($user: UserInput, $type: UserListOptions) {
      getCareTeam(user: $user, type: $type) {
         careMember {
            id
            name {
               fullName
               initials
            }
            colorCode
            contactInformation {
               email
               mobileNumber
               isCallEnable
               officeNumber
               address
            }
            providerInfo {
               settings
               degree
               department
               title
            }
            departments {
               id
               name
             }
            profileImage
            hospitals {
               id
               name
             }
         }
         relationship
         isCareTeam
      }
   }
`;

export const INVITE_PATIENT = gql`
   mutation Mutation(
      $patient: UserInput
      $familyInvite: Boolean
      $selectedPatientId: ID
      $reInvite: Boolean
      $relationship: String
   ) {
      addPatient(
         patient: $patient
         familyInvite: $familyInvite
         selectedPatientId: $selectedPatientId
         reInvite: $reInvite
         relationship: $relationship
      ) {
         status {
            code
            message
         }
         user {
            id
         }
      }
   }
`;

export const ADD_REMOVE_FAMILY_FRIENDS = gql`
   mutation AddCareMember($careTeam: AddCareTeamInput!, $queryType: String, $careMemberId: String) {
      addCareMember(careTeam: $careTeam, queryType: $queryType, careMemberId: $careMemberId) {
         message
         response
      }
   }
`;

export const removeFriendsFamily = (e, caremember, remove, setSelectedPatient) => {
   const { careMember } = caremember;
   e?.stopPropagation();
   const titleText = "Confirm";
   let showClose;
   const contentText = `Are you sure you want to remove this member?`;
   setSelectedPatient(careMember.id);
   showSwal(titleText, contentText, () => remove(careMember), showClose);
};

export const filterFamilyMembers = (tab, familyList) => {
   return familyList.filter((filter) => {
      if (tab === 0) return filter.isCareTeam;
      else return !filter.isCareTeam;
   });
};
