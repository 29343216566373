import moment from "moment";
import hospital from "images/iconsNotif/hospital.svg";
import five from "images/library/new (5).svg";
import textIcon from "images/media-icons/media-icon-text.svg";
import audio from "images/media-icons/media-icon-audio.svg";
import document from "images/media-icons/media-icon-document.svg";
import pdf from "images/media-icons/media-icon-document.svg";
import image from "images/media-icons/media-icon-image.svg";
import video from "images/media-icons/media-icon-video.svg";
var urlRegex =
   /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\00a1\ffff0-9]-*)*[a-z\00a1\ffff0-9]+)(?:\.(?:[a-z\00a1\ffff0-9]-*)*[a-z\00a1\ffff0-9]+)*(?:\.(?:[a-z\00a1\ffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/gm;

export const getMediaIconNew = (fileType, getHospital, hospitalId) => {
   if (getHospital) {
      if (hospitalId) {
         return `${process.env.REACT_APP_PROFILE_URL}/${hospitalId}`;
      } else {
         return hospital;
      }
   }
   if (fileType?.includes("image")) {
      return image;
   } else if (fileType?.includes("audio")) {
      return audio;
   } else if (fileType?.includes("pdf")) {
      return pdf;
   } else if (fileType?.includes("application")) {
      return document;
   } else if (fileType?.includes("video")) {
      return video;
   } else if (
      fileType?.includes("txt") ||
      fileType?.includes("text") ||
      fileType === null ||
      fileType === undefined ||
      fileType?.includes("content")
   ) {
      return textIcon;
   } else if (fileType?.includes("folder") || fileType?.includes("directory")) {
      return five;
   } else return document;
};

export const calculateDateDay = (dateValue) => {
   const date = new Date(dateValue);
   let diffInDays = Math.round(moment().diff(dateValue, "days", true));
   if (diffInDays === 0) {
      return "Today";
   } else if (diffInDays === 1) {
      return "Yesterday";
   } else {
      return moment(date).format("MM/DD/YYYY");
   }
};

export const calculateDateLabel = (dateValue) => {
   const date = new Date(dateValue);
   let oneDate = moment(date);
   let dayName = oneDate.format("dddd");
   let diffInDays = Math.round(moment().diff(dateValue, "days", true));
   if (diffInDays > 1 && diffInDays < 8) {
      return dayName + " @ " + moment(date).format("hh:mm a");
   } else if (diffInDays === 0) {
      return (dayName = "Today  @ " + moment(date).format("hh:mm a"));
   } else if (diffInDays === 1) {
      return (dayName = "Yesterday  @ " + moment(date).format("hh:mm a"));
   } else {
      return moment(date).format("MM/DD/YYYY") + " @ " + moment(date).format("hh:mm a");
   }
};
export const calculateDatetime = (dateValue) => {
   const date = new Date(dateValue);
   let oneDate = moment(date);
   let dayName = oneDate.format("dddd");
   let diffInDays = Math.round(moment().diff(dateValue, "days", true));
   if (diffInDays > 1 && diffInDays < 8) {
      return dayName ;
   } else if (diffInDays === 0) {
      return (dayName = moment(date).format("hh:mm A"));
   } else if (diffInDays === 1) {
      return (dayName = "Yesterday");
   } else {
      return moment(date).format("MM/DD/YYYY");
   }
};

export const emailReg =
   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const nameRegex = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/;
export const checkEmail = (email) => {
   return emailReg.test(email);
};

export const unformatNumber = (number) => {
   return number.replace(/\D/g, "");
};

export function formatPhoneNumber(phoneNumberString) {
   let number = phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
   return number || "";
}

export function formatPhoneNumberWithPlusOne(phoneNumberString) {
   let number = phoneNumberString.replace(/(\+\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
   return number || "";
}

export function formatPhoneNumberBackend(phoneNumberString) {
   let filteredNumber = getParam(phoneNumberString);
   if (filteredNumber === "-") return filteredNumber;
   let number = filteredNumber.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
   return "+1 " + number || "";
}

export const formatDate = (dateValue, format = false) => {
   const date = new Date(dateValue);

   return moment(date).format(format ? "yyyy-MM-DD" : "yyyyMMDD");
};

export const getParam = (param) => {
   if (param === "" || param === undefined || param === null || param === 0) return "-";
   else return param;
};

export const getDobFormat = (date) => {
   return moment(date, "YYYYMMDD").format("MM/DD/YYYY");
};

export const openDirection = (eachCare) => {
   let pAddrs = eachCare.address;
   let address = `${pAddrs?.address1}+${pAddrs?.address2}+${pAddrs?.city}+${pAddrs?.zip}`;
   window.open(`http://maps.google.com/maps/place/${address}`);
};

export const openMapDirectionGql = (info) => {
   const addrs = info?.address;
   let address = ""
   if(addrs?.streetAddress?.length){
      address+=`${addrs?.streetAddress} `
   }if(addrs?.city?.length){
     address+=`${addrs?.city} `
   }if(addrs?.zip?.length){
     address+=`${addrs?.zip} `
   }
   window.open(`http://maps.google.com/maps/place/${address}`);
};
export const showAddress = (info) => {
   const addrs = info?.address;
   let address = ""
   if(addrs?.streetAddress?.length){
      address+=`${addrs?.streetAddress} `
   }if(addrs?.city?.length){
     address+=`${addrs?.city} `
   }if(addrs?.zip?.length){
     address+=`${addrs?.zip} `
   }
   return address
};

export const openDirectionGql = (info) => {
   const addrs = info?.address;
   let address = `${addrs?.streetAddress}+${addrs?.city}+${addrs?.zip}`;
   window.open(`http://maps.google.com/maps/place/${address}`);
};

export const isDirection = (eachCare) => {
   let pAddrs = eachCare?.address;
   if (
      eachCare?.address &&
      (pAddrs?.address1 !== "" || pAddrs?.address2 !== "" || pAddrs?.city !== "" || pAddrs?.zip !== "")
   )
      return true;
   else return false;
};

export const isDirectionGql = (info) => {
   const address = info?.address;
   const checkAddress = address && (address?.streetAddress !== "" || address?.zip !== "" || address?.city !== "");
   if (checkAddress) return true;
   else return false;
};

export const isValidUsNumber = (email) => {
   return /^\d/.test(email) || email.startsWith("+");
};
export const ageForDob = (dateValue) => {
   if (!dateValue || dateValue === "-") return "-";
   let dobForAge = moment(dateValue);
   dobForAge = new Date(dobForAge);
   // dobForAge = moment().diff(dobForAge, "years");
   let ageLabel = "";
   let ageInDays = moment().diff(dobForAge, "days");
   let ageInWeeks = moment().diff(dobForAge, "weeks");
   let ageInMonths = moment().diff(dobForAge, "months");
   let ageInYears = moment().diff(dobForAge, "years");
   if (ageInDays > 6 && ageInWeeks > 4 && ageInMonths >= 12) {
      return (ageLabel = ageInYears + "y");
   } else if (ageInDays > 6 && ageInWeeks > 4 && ageInMonths < 12) {
      return (ageLabel = ageInMonths + "m");
   } else if (ageInDays > 6 && ageInWeeks < 5) {
      return (ageLabel = ageInWeeks + "w");
   } else {
      return (ageLabel = ageInDays + "d");
   }
};

export const getFormattedDate = (unix) => {
   if (!unix) return "";
   if (window.dates && window.dates[unix]) {
      return window.dates[unix];
   }
   let unixDate = parseInt(unix);
   // var pattern = /(\d{4})(\d{2})(\d{2})/;
   let date = unix.replace(/(\d{4})(\d{2})(\d{2})/, "$2/$3/$1");
   // let date = new Date(unixDate).toLocaleDateString();
   if (!window.dates) {
      window["dates"] = {};
   }
   let str = unixDate.toString();
   window.dates[str] = date;
   return date;
};

export const isIOS = () => {
   return /iPhone|iPod/.test(navigator.userAgent) || false;
};

export const errorToDisplay = (err) => {
   if (Array.isArray(err?.networkError?.result?.errors) && err?.networkError?.result?.errors?.length > 0) {
      let errMessage = err?.networkError?.result?.errors[0]?.message;
      return errMessage;
   } else {
      return "Something went wrong!";
   }
};

export const isValidUrl = (url) => {
   return urlRegex.test(url);
};

export const isNumber = (num) => {
   if (!isNaN(num) && num.length >= 10) {
      return true;
   }
   return false;
};
export const isValidEmail = (email) => {
   return emailReg.test(email);
};
export const isValidMob = (mobile) => {
   let mobReg = /^(\+1|)?(\d{3})(\d{3})(\d{4})$/;
   return mobReg.test(mobile);
};

export const callMobileNumber = (number) => {
   window.location.href = `tel:${number}`;
};
export const sendMail = (mail) => {
   window.location.href = `mailto:${mail}`;
};
export const openLink = (url) => {
   const addHttp = url.includes("https://") ? url : "https://" + url;
   window.open(addHttp, "_blank");
};

export const capitalizeFirstLetter = (input) => {
   return input
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
};

export const calculateHospInitials = (str = "") => {
   let plainStr = str?.replace(/[\])}[{(]/g, "") || "";
   let name = plainStr.split(" ");
   if (name && name.length > 1) {
      return name[0][0].toUpperCase() + name[1][0]?.toUpperCase() || "";
   } else {
      return name[0].substring(0, 2).toUpperCase() || "";
   }
};

export const getBrowser = () => {
   var navigator = window.navigator;
   var nAgt = navigator.userAgent;
   var browserName = navigator.appName;

   // In Opera, the true version is after "Opera" or after "Version"
   if (nAgt.indexOf("Opera") !== -1) {
      browserName = "Opera";
   }
   // In MSIE, the true version is after "MSIE" in userAgent
   else if (nAgt.indexOf("MSIE") !== -1) {
      browserName = "Microsoft Internet Explorer";
   }
   // In Chrome, the true version is after "Chrome"
   else if (nAgt.indexOf("Chrome") !== -1) {
      browserName = "Chrome";
   }
   // In Safari, the true version is after "Safari" or after "Version"
   else if (nAgt.indexOf("Safari") !== -1) {
      browserName = "Safari";
   }
   // In Firefox, the true version is after "Firefox"
   else if (nAgt.indexOf("Firefox") !== -1) {
      browserName = "Firefox";
   }
   return browserName;
};
