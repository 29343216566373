import React, { useEffect, useState } from "react";
import AppointmentSectionComp from "../component/AppointmentSectionComp";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import { connect } from "react-redux";
import { GET_DASHBOARD_APPOINTMENTS_DATA } from "../constants/DashboardConst";

const AppointmentSectionCont = ({ auth, setShowAppointment }) => {
   const [appointmentList, setAppointmentList] = useState([]);
   const id = auth?.userCredentials?.user?.id || null
   const getAppointmentsList = () => {
      let obj = { offset: 0, limit: 10, options: { id: id } };
      fetchQuery(
         GET_DASHBOARD_APPOINTMENTS_DATA,
         obj,
         (res) => {
            let data = res?.data?.getAppointments?.appointments || [];
            let length = res?.data?.getAppointments?.appointments?.length || 0
            if (length === 0) {
               setShowAppointment(false);
            }
            setAppointmentList(data);
         },
      );
   };
   useEffect(() => {
      getAppointmentsList();
   }, []);

   return <>{appointmentList?.length > 0 && <AppointmentSectionComp appointmentList={appointmentList} />}</>;
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
   };
};

export default connect(mapStateToProps, null)(AppointmentSectionCont);
