import React from "react";

const renderMarkdown = (markdownText) => {
   // Split the Markdown content into lines
   if (!markdownText) return "";
   const lines = markdownText.split("\n");

   // Process each line and create React elements
   const elements = lines.map((line, index) => {
      if (line.startsWith("###")) {
         // Render headers with strong tag
         return (
            <h3 key={index} style={{ paddingBottom: "10px", paddingTop: "10px" }}>
               <strong>{line.substring(4)}</strong>
            </h3>
         );
      } else if (line.startsWith("  - **")) {
         // Render bold list items with proper indentation
         const listItemText = line.substring(6).replace("**", ""); // Remove the double asterisks
         return (
            <li key={index} style={{ marginLeft: "20px" }}>
               {listItemText.split(":").map((s, i) => {
                  return i === 0 ? <strong>{" - " + s + " : "}</strong> : s;
               })}
            </li>
         );
      } else if (line.startsWith("    - **")) {
         // Render bold nested list items with proper indentation
         const listItemText = line.substring(8).replace("**", ""); // Remove the double asterisks
         return (
            <li key={index} style={{ marginLeft: "40px" }}>
               {listItemText}
            </li>
         );
      } else if (line.startsWith("- **")) {
         // Render bold items without bullets with proper indentation
         const paragraphText = line.substring(4).replace("**", ""); // Remove the double asterisks
         return (
            <p key={index} style={{ marginLeft: "20px" }}>
               {paragraphText}
            </p>
         );
      } else {
         // Render paragraphs without strong tag
         return <p key={index}>{line}</p>;
      }
   });

   // Return the assembled React elements
   return elements;
};

const MedicalReport = ({ content }) => {
   return (
      <div style={{ fontSize: "17px" }}>
         {!content?.includes("###") ? (
            <pre
               style={{
                  whiteSpace: "pre-wrap",
                  fontFamily: "inherit",
                  fontSize: "17px",
               }}
            >
               {content}
            </pre>
         ) : (
            renderMarkdown(content)
         )}
      </div>
   );
};

export default MedicalReport;
