import React from "react";
import { loginConst } from "shared/constants/loginConst";

const SwitchAccountLoader = () => {
   const TextStyle = "text-[#4F4F4F] text-[17px] font-medium leading-[20px] my-[21.5px] ml-2"
   const CardStyle = "flex gap-2 justify-center items-center"
   const LoaderStyle = "animate-pulse h-[17px] w-[80%] bg-[#ECECED] my-4 rounded-full";
   const UserLoader = "animate-pulse h-[38px] w-[38px] bg-[#ECECED] my-4 rounded-full"
   const myAccountsLabel = loginConst?.patientOnboard?.myAccounts;
   const sharingWithMeLabel = loginConst?.patientOnboard?.sharingWithMe;

   return (
      <>
         <div className={`${TextStyle}`}>{myAccountsLabel}</div>
         <div className={`${CardStyle}`}>
            <div className={`${UserLoader}`}></div>
            <div className={`${LoaderStyle}`}></div>
         </div>
         <div className={`${CardStyle}`}>
            <div className={`${UserLoader}`}></div>
            <div className={`${LoaderStyle}`}></div>
         </div>
         <div className="h-[0.5px] my-[24px] divider-color"></div>
         <div className={`${TextStyle}`}>{sharingWithMeLabel}</div>
         <div className={`${CardStyle}`}>
            <div className={`${UserLoader}`}></div>
            <div className={`${LoaderStyle}`}></div>
         </div>
         <div className={`${CardStyle}`}>
            <div className={`${UserLoader}`}></div>
            <div className={`${LoaderStyle}`}></div>
         </div>
      </>
   );
};

export default SwitchAccountLoader;