import React, { memo } from "react";
import care from "images/careTeam/care.svg";
import Avatar from "shared/components/avatar";

const SharingUserDetails = memo(
   ({
      title,
      post,
      color,
      initialsApi,
      name,
      className = false,
      details = [],
      showCare = false,
      src = "",
      sharingWithMeSM = false,
   }) => {
      const detailslength = details?.some((obj) => obj?.title === "") || null
      return (
         <div className={`flex items-center w-[73%]`}>
            <div className={`flex items-center justify-content-start ${className} table-data`}>
               <Avatar
                  src={src}
                  isProvider={true}
                  className={`md:flex-shrink-0 pointer mr-4 md:mt-0 ${!detailslength && "mb-[5px]"}`}
                  bgColor={color}
                  radius={38}
                  initialsApi={initialsApi}
                  name={name}
               />
            </div>
            <div
               className={`pl-2 w-[100%] flex-row justify-center items-cente ${detailslength && "py-[10px]"} ${
                  !detailslength && sharingWithMeSM && "py-[10px]"
               }`}
            >
               <div title={title} className={`text-[17px] w-[100%] truncate items-center`}>
                  {title}
               </div>
               {post && (
                  <div title={post} className="text-xs text-grey-cus">
                     {post}
                  </div>
               )}
               {details?.map((obj, index) => {
                  const hasCareGroup = index === 1 && showCare;
                  const className = hasCareGroup && "flex items-end -mt-1";
                  return (
                     <div
                        title={obj?.title}
                        key={index}
                        className={`text-[13px] text-[#8A8A8E] font-normal w-[100%] truncate ${className}`}
                     >
                        {obj?.title} {hasCareGroup && <img src={care} alt="careGroup" className="inline ml-2" />}
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }
);

export default SharingUserDetails;
