import React, { useEffect, useState, useRef } from "react";
import NewPost from "shared/components/newPost/NewPost";
import leftSliderArrow from "images/newdashbord/leftSliderArrow.svg";
import rightSliderArrow from "images/newdashbord/rightSliderArrow.svg";
import star from "images/newdashbord/star.svg";
import { getReferralDetails } from "actions";
import { useNavigate } from "react-router-dom";
import Modal from "shared/components/modal/modal";
import ReferralDetailsView from "shared/components/post/referalView";
import FolderContainer from "shared/components/folderContainer/FolderContainer";
import Post from "shared/components/post/Post";

const SpotlightContainerComp = ({
   reactHeart,
   showAllSpotlight,
   spotlightContent,
   getSpotlight = () => {},
   showDirectory,
   setFolderName,
   setSearchKey = "",
}) => {
   const [eachPost, setEachPost] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [showReferal, setShowReferalModal] = useState(false);
   const [showArrows, setShowArrows] = useState(false);
   const divRef = useRef(null);
   const [position, setPosition] = useState(0);
   const spotlightLength = spotlightContent?.totalCount || 0;
   const spotlight = spotlightContent?.list || [];
   const container = document.getElementById("box");
   const navigate = useNavigate();
   const showHide = () => {
      setShowModal(false);
   };
   useEffect(() => {
      const divElement = divRef.current;
      if (divElement) {
         divElement.addEventListener("scroll", handleScroll);
      }
      return () => {
         if (divElement) {
            divElement.removeEventListener("scroll", handleScroll);
         }
      };
   }, []);
   const handleScroll = () => {
      if (divRef?.current) {
         setPosition(divRef?.current?.scrollLeft);
      }
   };
   const toggleReferal = () => {
      setShowReferalModal(!showReferal);
   };
   const openModal = async (data) => {
      if (data?.type === "referral") {
         let res = await getReferralDetails({ referralId: data?.id });
         setEachPost(res?.data?.data);
         toggleReferal();
      } else {
         setShowModal(true);
         setEachPost(data);
      }
   };
   const sideScroll = (element, direction) => {
      const screenLength = window.innerWidth;
      let scrollAmount = 780;
      if (screenLength < 1291) {
         scrollAmount = 690;
      }
      element.scrollBy({
         left: direction === "left" ? -scrollAmount : scrollAmount,
         behavior: "smooth",
      });
   };
   const handleButtonClick = (direction) => {
      sideScroll(container, direction);
   };
   const handleMouseEnter = () => {
      setShowArrows(true);
   };
   const handleMouseLeave = () => {
      setShowArrows(false);
   };
   const handleNavigation = () => {
      navigate("/all-spotlight");
      setSearchKey("");
   };
   const containerSize = container?.scrollWidth - container?.clientWidth;
   const showLeftArrow = position !== 0 && showArrows;
   const showRightArrow = position + 0.5 < containerSize && showArrows;
   return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
         <div className={`${showAllSpotlight ? "mt-5" : ""} flex justify-between mb-[15px]`}>
            <div className="flex gap-[8px]">
               <img src={star} alt="star" className="h-[24px] w-[24px]" />
               <div onClick={() => setSearchKey("")} className="text-[#000] text-container cursor-pointer">
                  Your Spotlight
               </div>
            </div>
            {spotlightLength > 1 && (
               <div
                  className={`${
                     spotlightLength === 2 ? "block md:hidden" : spotlightLength === 3 ? "block lg:hidden" : " "
                  } text-[#043293] text-[17px] leading-[-0.24px] font-semibold cursor-pointer`}
                  onClick={handleNavigation}
               >
                  See all
               </div>
            )}
         </div>
         <div className="relative ">
            <div className="w-[105%] sm:w-[104%] md:w-[106%] lg:w-[105%] xl:w-[112%] h-[300px] absolute z-1 ml-[-0.7rem] sm:ml-[-13px] md:ml-[-1.9rem] xl:ml-[-70px]" />
            {spotlightLength > 1 && showLeftArrow && (
               <div
                  className={`
                  ${spotlightLength === 2 ? "block md:hidden" : spotlightLength === 3 ? "block lg:hidden" : " "}
                   flex justify-start absolute top-[100px] left-[-24px] md:left-[-30px] bottom-0`}
                  onClick={() => handleButtonClick("left")}
               >
                  <img src={leftSliderArrow} alt={"leftSliderArrow"} className="w-[30px] h-[44px] left" id="left" />
               </div>
            )}
            <div ref={divRef} className="overflow-hidden h-[275px] pt-[1px] mx-auto max-w-[1143px]" id="box">
               <div className="flex gap-[30px] max-w-[710px]  min-w-[105px] md:max-w-[940px] md:min-w-[694.3px] lg:max-w-[100%] lg:min-w-[80%] xl:max-w-full xl:min-w-[30px]">
                  {spotlight?.map((map) => {
                     const isFolder = map?.type === "directory";
                     return (
                        <>
                           {isFolder ? (
                              <FolderContainer
                                 getSpotlight={getSpotlight}
                                 showAllSpotlight={true}
                                 key={map?.id}
                                 data={map}
                                 openModal={openModal}
                                 reactHeart={reactHeart}
                                 yourSpotlight={true}
                                 showDirectory={showDirectory}
                                 setFolderName={setFolderName}
                                 setSearchKey={setSearchKey}
                              />
                           ) : (
                              <NewPost
                                 getSpotlight={getSpotlight}
                                 showAllSpotlight={true}
                                 key={map?.id}
                                 data={map}
                                 openModal={openModal}
                                 reactHeart={reactHeart}
                                 yourSpotlight={true}
                                 showDirectory={showDirectory}
                              />
                           )}
                        </>
                     );
                  })}
               </div>
            </div>
            {spotlightLength > 1 && showRightArrow && (
               <div
                  className={`${
                     spotlightLength === 2 ? "block md:hidden" : spotlightLength === 3 ? "block lg:hidden" : ""
                  } flex justify-end absolute top-[100px] right-[-24px] md:right-[-30px] bottom-0`}
                  onClick={() => handleButtonClick("right")}
               >
                  <img src={rightSliderArrow} alt={rightSliderArrow} className="w-[30px] h-[44px] right" id="right" />
               </div>
            )}
         </div>
         {showModal && (
            <Modal onModalTapped={showHide}>
               <Post
                  setEachPost={setEachPost}
                  yourSpotlight={true}
                  getSpotlight={getSpotlight}
                  showAllSpotlight={true}
                  isPreview={true}
                  modal
                  data={eachPost}
                  onModalTapped={showHide}
                  reactHeart={reactHeart}
               />
            </Modal>
         )}
         {showReferal && (
            <Modal onModalTapped={toggleReferal}>
               <ReferralDetailsView isPreview={true} modal referralDetails={eachPost} onModalTapped={toggleReferal} />
            </Modal>
         )}
      </div>
   );
};

export default SpotlightContainerComp;
