import React, { Component } from "react";

let linkExpired = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   height: "80vh",
   lineHeight: "30px",
   textAlign: "center",
};

export class LinkExpired extends Component {
   render() {
      return (
         <div style={linkExpired}>
            <div>
               <div style={{ fontSize: "22px", fontWeight: 600 }}>Link has expired!</div>
               <div>You can access the content always in our app.</div>
               If you are a patient or family member of the patient click here {"->"}{" "}
               <a href="https://patient.playbackhealth.com">patient.playbackhealth.com</a>
               <div>
                  If you are a provider click here {"-> "}
                  <a href="https://provider.playbackhealth.com">provider.playbackhealth.com</a>{" "}
               </div>
            </div>
         </div>
      );
   }
}


export default LinkExpired;
