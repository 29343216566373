import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SwitchAccountDroupdown from "../component/switchAccountDropdown";
import { socketActions, socketSubActions } from "socket/Websocket";
import { getToken } from "actions";

function SwitchAccountCont(props) {
   const { getBanner } = props;
   const [datas, setDatas] = useState([{ careTeam: [], patientAccounts: [] }]);
   const [isLoading, setIsLoading] = useState(true);
   const getAccounts = async () => {
      setIsLoading(true)
      const token = await getToken();
      var obj = {
         action: socketActions.auth,
         subAction: socketSubActions.getPatientAccounts,
         firebaseToken: token,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            setDatas(resultStatus.data);
            getBanner();
         } else {
            getBanner();
         }
         setIsLoading(false);
      });
   };
   useEffect(() => {
      getAccounts();
   }, []);

   return <SwitchAccountDroupdown getAccounts={getAccounts} datas={datas} {...props} isLoading={isLoading} />;
}

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(SwitchAccountCont);
