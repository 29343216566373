import React, { useState } from "react";
import calendar from "images/newdashbord/calendar.svg";
import SingleAppointment from "modules/dashboard/component/SingleAppointment";
import MultipleAppointment from "modules/dashboard/component/MultipleAppointment";
import SeeAllAppointmentPopup from "modules/dashboard/component/SeeAllAppointmentPopup";
import { getAppointmentTime } from "modules/dashboard/actions/AppointmentTime";
import { AppointmentText } from "shared/constants/const";
import { showAddress } from "shared/actions/helpers";

const AppointmentSectionComp = ({ appointmentList }) => {
   const [showPopup, setShowPopup] = useState(null);
   const appointmentLength = appointmentList?.length || 0
   const clickHandler = () => {
      setShowPopup();
   };

   return (
      <>
         <div className="mb-[30px]">
            <div className="flex justify-between mb-[15px]">
               <div className="flex justify-center items-center gap-[9px]">
                  <img src={calendar} alt="calendar" className="w-[22px] h-[20px]" />
                  <div className="text-[#000] text-[20px] font-semibold leading-[30px] tracking-[0.442px]">
                     {AppointmentText?.appointments}
                  </div>
               </div>
               {appointmentLength > 1 && (
                  <div
                     className={`${
                        appointmentLength === 2 ? "block sm:hidden" : appointmentLength === 3 ? "block lg:hidden" : " "
                     }
                     text-[17px] font-semibold tracking-[-0.24px] cursor-pointer text-[#043293]`}
                     onClick={() => {
                        if (appointmentLength > 1) {
                           clickHandler();
                        }
                     }}
                  >
                     {AppointmentText?.seeAll}
                  </div>
               )}
            </div>
            <div
               className={`${
                  appointmentLength > 1
                     ? "relative flex flex-wrap gap-[30px] mx-auto max-w-[1143px] h-[132.5px] overflow-hidden"
                     : " "
               }`}
            >
               {appointmentList?.map((a, index) => {
                  const color = a?.provider?.colorCode || window.initialColors[0];
                  const initialsApi = a?.provider?.name?.initials || false;
                  const name = a?.provider?.name?.fullName || "";
                  const info = a?.provider?.contactInformation || ""
                  const hospitalName = a?.hospital?.name + " " + showAddress(info) || ""
                  const isAddress =  a?.provider?.contactInformation?.address !== null ? true : false
                  const reason = a?.reason || ""
                  const mobileNumber = a?.provider?.contactInformation?.mobileNumber || ""
                  const appointmentTime = a !== null ? getAppointmentTime(a?.startDateTime) : null;
                  return (
                     <>
                        {/* To hendle small & large screan */}
                        {appointmentLength > 1 ? (
                           <MultipleAppointment
                              color={color}
                              initialsApi={initialsApi}
                              name={name}
                              hospitalName={hospitalName}
                              reason={reason}
                              key={index}
                              appointmentLength={appointmentLength}
                              mobileNumber={mobileNumber}
                              appointmentTime={appointmentTime}
                              info={info}
                              isAddress={isAddress}
                           />
                        ) : (
                           <>
                              {/* <SingleaAppointmentSkeleton/> */}
                              <div className="lg:block hidden">
                                 <SingleAppointment
                                    color={color}
                                    initialsApi={initialsApi}
                                    name={name}
                                    hospitalName={hospitalName}
                                    reason={reason}
                                    mobileNumber={mobileNumber}
                                    appointmentTime={appointmentTime}
                                    info={info}
                                    isAddress={isAddress}
                                 />
                              </div>
                              <div className="lg:hidden block">
                                 <MultipleAppointment
                                    color={color}
                                    initialsApi={initialsApi}
                                    name={name}
                                    hospitalName={hospitalName}
                                    reason={reason}
                                    appointmentLength={appointmentLength}
                                    mobileNumber={mobileNumber}
                                    appointmentTime={appointmentTime}
                                    singleAppoint={true}
                                    info={info}
                                    isAddress={isAddress}
                                 />
                              </div>
                           </>
                        )}
                     </>
                  );
               })}
            </div>
         </div>
         {showPopup !== null && (
            <SeeAllAppointmentPopup
               data={showPopup}
               onModalTapped={() => {
                  setShowPopup(null);
               }}
               setShowPopup={setShowPopup}
            />
         )}
      </>
   );
};

export default AppointmentSectionComp;
