import React, { useState } from "react";
import { connect } from "react-redux";
import Avatar from "./avatar";
import { useNavigate } from "react-router-dom";
import DropOtions from "./dropOtions/dropDownOptions";
function Dropdowntoggle(props) {
   const navigate = useNavigate();
   const [show, setShow] = useState(false);
   const { options = [], auth } = props;
   const { user } = auth.userCredentials;
   const initialsApi = user.initials;
   const color = user.colorCode || window.initialColors[0];
   const name = user.name;

   const setDrop = () => {
      setShow(!show);
   };

   return (
      <div className="relative">
         <div className="cursor-pointer select-none" onClick={setDrop}>
            <Avatar
               isProvider={true}
               src={`${process.env.REACT_APP_PROFILE_URL}/${user?.id}?ver=${Math.random()}`}
               className="shrink-0 pointer mr-4"
               bgColor={color}
               radius={38}
               initialsApi={initialsApi}
               name={name}
            />
         </div>
         {show && (
            <div
               id="dropdownDivider"
               className={` drop-down ${
                  show ? "" : "hidden"
               } absolute right-7 top-[50px] z-[70] w-[230px] sm:w-[300px] bg-white rounded shadow `}
            >
               {true && (
                  <div className="flex items-center p-3 pl-4">
                     <Avatar
                        isProvider={true}
                        src={`${process.env.REACT_APP_PROFILE_URL}/${user?.id}?ver=${Math.random()}`}
                        className="flex-shrink-0 pointer mr-4"
                        bgColor={color}
                        radius={48}
                        initialsApi={initialsApi}
                        name={name}
                     />
                     <div className="text-[17px] sm:text-[18px] ml-2.5 -mr-5 leading-7 color-[#4f4f4f] font-bold">
                        {name}
                     </div>
                  </div>
               )}
               <div className="w-full border-line h-0" />
               <DropOtions options={options} setDrop={setDrop} navigate={navigate} />
            </div>
         )}
      </div>
   );
}
const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(Dropdowntoggle);
