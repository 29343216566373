import React, { useEffect, useState } from "react";
import { getRelationshipFirebase } from "shared/actions/auth/authActions";
import cross from "images/common/cross.svg";
import { textConst, textLabels } from "shared/constants/const";
import InputBox from "shared/components/inputBox";
import Button from "shared/components/button";
import ButtonCancel from "shared/components/cancelButton";
import SelectEfficient from "shared/components/select/select";
import { ShowAlert } from "shared/actions/alert";
import { checkEmail, nameRegex, isValidUsNumber, unformatNumber } from "shared/actions/helpers";
import LoadingIndicator from "shared/components/loader";

const style = " w-full sm:w-3/4 py-2";
export default function InviteFamily({
   invitePerson,
   onModalTapped,
   name,
   setName,
   email,
   setEmail,
   relation,
   setRelation,
   loading,
   selected = null,
}) {
   const isDisabled = selected !== null ? "pointer-events-none bg-gray-100 text-[#8c8c8c]" : false;
   const [options, setOptions] = useState([]);

   useEffect(() => {
      getOptions();
   }, []);

   const getOptions = () => {
      getRelationshipFirebase((res) => {
         var arr = res?.relations?.map((s) => {
            return { label: s, text: s };
         });
         setOptions(arr);
      });
   };
   const validate = () => {
      if (!nameRegex?.test(name) && !isDisabled) {
         ShowAlert(textConst?.fullname, textConst?.error);
      } else if (email === "") {
         ShowAlert(textConst?.empty, textConst?.error);
      } else if (!isValidUsNumber(email) && !checkEmail(email)) {
         ShowAlert(textConst?.email, textConst?.error);
      } else if (isValidUsNumber(email) && unformatNumber(email).length < 10) {
         ShowAlert(textConst?.phone, textConst?.error);
      } else {
         invitePerson();
      }
   };
   const isNumber = isValidUsNumber(email) || false

   return (
      <div className="text-center w-[85%] sm:w-[550px] post-card flex items-center justify-center flex-col relative p-5 pb-5">
         <img onClick={onModalTapped} src={cross} alt="cross-btn" className="absolute right-5 top-5 cursor-pointer" />
         <div className="text-[21px] font-medium text-black pb-4">
            {isDisabled ? textLabels?.edit : textLabels?.invite}
         </div>
         <div className={style}>
            <InputBox
               className={isDisabled}
               placeholder={textLabels?.name}
               type="text"
               value={name}
               onChange={setName}
            />
         </div>
         <div className={style}>
            <InputBox
               className={isDisabled}
               type={isNumber ? "number" : "text"}
               placeholder={textLabels?.number}
               value={email}
               onChange={(e) => {
                  if (isNumber && e?.target?.value === "+1 (___) ___-____") {
                     setEmail("");
                  } else {
                     setEmail(e?.target?.value);
                  }
               }}
            />
         </div>
         <div className={style}>
            <SelectEfficient
               onChange={(e) => setRelation(e)}
               value={relation}
               options={options}
               placeholder={textLabels?.rel}
            />
         </div>
         <div className="sm:flex mt-8 w-full sm:w-max  ">
            {loading ? (
               <LoadingIndicator />
            ) : (
               <>
                  <ButtonCancel className="hidden sm:table h-[44px]  sm:w-[120px] sm:h-[50px]" onClick={onModalTapped}>
                     {textLabels?.cancel}
                  </ButtonCancel>
                  <Button className=" w-[95%] mt-2 sm:mt-0 sm:w-[120px] sm:ml-3 h-[50px]" onClick={validate}>
                     {isDisabled ? textLabels?.update : textLabels?.sendInvite}
                  </Button>
               </>
            )}
         </div>
      </div>
   );
}
