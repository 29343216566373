import React from "react";
export default function Button({ id = "", onClick, disabled, children, className }) {
   return (
      <button
         id={id}
         className={`primary-bg text-white text-sm font-semibold w-full p-2 rounded-md ${className}`}
         disabled={disabled}
         onClick={onClick}
      >
         {children}
      </button>
   );
}
