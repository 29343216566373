import React from "react";
import Avatar from "shared/components/avatar";
import goArrow from "assets/Back.svg";

const AccountSection = ({ datas, label, clickHandler ,customWidth=false}) => {
   return (
      <>
         <div className={`text-[#4F4F4F] text-[17px] mt-[20px] ${!customWidth?"mb-[3px]":"mb-[18px]"} mb-[18px] flex justify-start pl-2`}>{label}</div>
         {datas?.map((data, index) => {
            const color = data?.colorCode || window.initialColors[0];
            const title = data?.initials || false;
            const name = data?.name || "";
            const contentCount = data?.contentCount || 0;
            return (
               <div
                  onClick={() => {
                     clickHandler(data);
                  }}
                  key={index}
                  className="bg-[#fff] flex w-[100%] h-[60px] items-center cursor-pointer hover-selected rounded-[8px] pl-2"
               >
                  <div className="flex gap-5 flex-1 items-center ">
                     <Avatar
                        src={null}
                        isProvider={true}
                        className="sm:flex-shrink-0 pointer mr-4"
                        bgColor={color}
                        radius={38}
                        initialsApi={title}
                        name={name}
                     />
                     <div className={`text-[#000] flex flex-1 sm:flex-auto items-center justify-between ${customWidth?"w-[245px]":"w-[210px]"} text-left font-semibold text-[17px]`}>
                        <div className="sm:flex-1 w-[80vw] sm:w-auto truncate">{name}</div>
                        {contentCount > 0 && (
                           <div className="text-[#8A8A8E] sm:w-auto text-[13px] items-center mx-2">{contentCount}</div>
                        )}
                     </div>
                  </div>
                     <img src={goArrow} alt="arrow" className="items-center text-[#8C8C8C] w-[24px] h-[24px]" />
                 
               </div>
            );
         })}
      </>
   );
};

export default AccountSection;
