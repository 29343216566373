import axios from "axios";
import { store } from "redux/store";
import * as actions from "redux/actions/auth.action";
import jwt_decode from "jwt-decode";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { callSocket } from "shared/actions/socketAction";
import { socketActions, socketSubActions } from "socket/Websocket";

let redux_store = store.getState();
let northwelluser = redux_store.auth?.northwelluser;
let stsTokens = northwelluser?.user?.stsTokenManager;
async function callApi(url, method, headers, queryparams, body, isMediaUpload = false, uploadProgress, tokenSource) {
   let accessToken = await getToken();
   let config;

   if (isMediaUpload) {
      config = {
         method: method,
         onUploadProgress: (progressEvent) => uploadProgress && uploadProgress(progressEvent.loaded),
         data: body,
         headers: {
            "Content-Type": "application/json",
            ...headers,
         },
      };
   } else {
      config = {
         method: method,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            ...headers,
         },
         data: body,
         params: {
            key: process.env.REACT_APP_FIREBASEAPIKEY,
            uuid: window.navigator.userAgent,
            "api-version": 1.2,
            ...queryparams,
         },
      };
   }
   if (tokenSource) {
      config["cancelToken"] = tokenSource?.token;
   }
   return axios(url, config).catch(async (error) => {
      if (
         error?.status === 403 &&
         error.data?.message?.toLowerCase() === "forbidden" &&
         (!localStorage.getItem("forbidden") ||
            (localStorage.getItem("forbidden") && localStorage.getItem("forbidden") !== 3))
      ) {
         let count = localStorage.getItem("forbidden");
         if (count) {
            localStorage.setItem("forbidden", parseInt(count) + 1);
         } else {
            localStorage.setItem("forbidden", 1);
         }
         if (parseInt(count) !== 2) {
            let newStsTokens = await renewToken();
            stsTokens = newStsTokens;
            northwelluser.user.stsTokenManager = newStsTokens;
            store.dispatch(actions.savenorthwelluserobj(northwelluser));
            callApi(url, method, headers, queryparams, body, isMediaUpload, uploadProgress, tokenSource);
         } else {
            localStorage.removeItem("forbidden");
         }
      }
      return Promise.reject(error);
   });
}

export function getAccessToken() {
   let redux_store = store.getState();
   let northwelluser = redux_store.auth?.northwelluser;
   let stsTokens = northwelluser?.user?.stsTokenManager;
   if (stsTokens) {
      return stsTokens.accessToken;
   } else {
      logout();
   }
}

export async function getToken() {
   let redux_store = store.getState();
   let northwelluser = redux_store.auth?.northwelluser;
   let stsTokens = northwelluser?.user?.stsTokenManager;
   if (stsTokens) {
      const accessTokenExpTime = jwt_decode(stsTokens.accessToken).exp;
      // const accessTokenExpTime = JSON.parse(atob(stsTokens.accessToken.split(".")[1])).exp;
      const now = parseInt(Date.now() / 1000);
      if (now >= accessTokenExpTime) {
         let newStsTokens = await renewToken();
         stsTokens = newStsTokens;
         northwelluser.user.stsTokenManager = newStsTokens;
         store.dispatch(actions.savenorthwelluserobj(northwelluser));
         return newStsTokens.accessToken;
      }
      return stsTokens.accessToken;
   } else if (stsTokens !== null && stsTokens !== undefined) {
      logout();
   }
}

export async function logout() {
   const userId = store.getState().auth?.userCredentials?.user?.id;
   let str = {
      action: socketActions.auth,
      subAction: socketSubActions.logoutUser,
      id: userId,
   };
   callSocket(
      str,
      () => {
         callBackLogout();
      },
      () => {
         callBackLogout();
      }
   );
}
export const callBackLogout = () => {
   firebase
      .auth()
      .signOut()
      .then(() => {
         window.location.replace("/login");
      });
   localStorage.clear();
   indexedDB.deleteDatabase("firebaseLocalStorageDb");
};

export async function renewToken() {
   let redux_store = store.getState();
   let northwelluser = redux_store.auth?.northwelluser;
   let stsTokens = northwelluser?.user?.stsTokenManager;
   var refreshToken = stsTokens.refreshToken;
   var bodyFormData = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
   };
   return axios({
      method: "post",
      url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASEAPIKEY}`,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
   })
      .then((response) => {
         var stsTokenManager = {
            apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
            refreshToken: response.data.refresh_token,
            accessToken: response.data.access_token,
            expirationTime: response.data.expires_in,
         };
         return stsTokenManager;
      })
      .catch((errorNew) => {
         console.log("refresh token error " + errorNew);
         sessionStorage.clear();
         localStorage.clear();
         // store.dispatch(actions.logout());
         window.location.replace("/login");
      });
}

export const checkAuth = () => {
   firebase.auth().onAuthStateChanged(async function (user) {
      if (!user) {
         logout();
      }
   });
};

export async function reactHeart(userId) {
   let url = `${process.env.REACT_APP_URL}/user/${userId}/v2/activity`;
   return callApi(url, "get", null, {}, null, false, null);
}
export async function getCareTeam() {
   let url = `${process.env.REACT_APP_URL}/user/v3/careteam`;
   return callApi(url, "get", null, {}, null, false, null);
}

export async function getMediaSignedURL(params) {
   let url = `${process.env.REACT_APP_URL}/media/${params.location}`;
   return callApi(url, "get", null, params, null);
}
export async function giveReaction(body) {
   let url = `${process.env.REACT_APP_URL}/visits/mediaItem/activityUpdate`;
   return callApi(url, "post", null, null, body);
}
export async function getFriendsFamily(params) {
   let url = `${process.env.REACT_APP_URL}/user/careteam`;
   return callApi(url, "get", null, params, null);
}

export async function removeFamilyFriends(params) {
   let url = `${process.env.REACT_APP_URL}/user/careteam/${params.careTeamId}?userId=${params.userId}`;
   return callApi(url, "delete");
}

export async function inviteFamilyFriends(body) {
   let url = `${process.env.REACT_APP_URL}/user/shareWithPatient`;
   return callApi(url, "post", null, null, body);
}

export async function getPatientData(params) {
   let url = `${process.env.REACT_APP_URL}/user/patientProfile`;
   return callApi(url, "get", null, params, null);
}

export async function uploadProfilePhoto(params) {
   let url = `${process.env.REACT_APP_URL}/user/signedurl/${params.loggedInUserId}?operationType=${params.operationType}`;
   return callApi(url, "get", null, null, null);
}
export async function uploadmedia(url, body, uploadProgress) {
   return callApi(url, "put", { "Content-Type": body.type }, null, body, true, uploadProgress);
}
export async function deleteProfilePhoto(params) {
   let url = `${process.env.REACT_APP_URL}/user/signedurl/${params.loggedInUserId}?operationType=${params.operationType}`;
   return callApi(url, "get", null, null, null);
}
export async function getReferralDetails(params) {
   let url = `${process.env.REACT_APP_URL}/user/patientReferral/${params.referralId}`;
   return callApi(url, "get", null, params, null);
}
export async function removeFromCareTeam(params) {
   let url = `${process.env.REACT_APP_URL}/user/careteam/${params.careTeamId}`;
   return callApi(url, "delete");
}

export async function getPatinetActivity(userId, params, token) {
   let url = `${process.env.REACT_APP_URL}/user/${userId}/v2/activity`;
   return callApi(url, "get", null, params, null, false, null, token);
}
//PATIENT PROFILE DATA
export async function getPatientProfileData(params, token) {
   let url = `${process.env.REACT_APP_URL}/user/patientProfile`;
   return callApi(url, "get", null, params, null, false, null, token);
}
export async function getContentList(queryParams, token) {
   let url = `${process.env.REACT_APP_URL}/user/${queryParams.userId}/v2/activity`;
   return callApi(url, "get", null, queryParams, null, false, null, token);
}
export async function getCareteamProviders(queryParams, token) {
   let url = `${process.env.REACT_APP_URL}/user/careteamProviders`;
   return callApi(url, "get", null, queryParams, null, false, null, token);
}
export async function getCareteam(queryParams, token) {
   let url = `${process.env.REACT_APP_URL}/user/careteam`;
   return callApi(url, "get", null, queryParams, null, false, null, token);
}
export async function getPatient(params) {
   let url = `${process.env.REACT_APP_URL}/user`;
   return callApi(url, "get", null, params, null, false, null);
}
export async function updateItemStatus(body) {
   let url = `${process.env.REACT_APP_URL}/user/activityLogs`;
   return callApi(url, "post", null, null, body);
}
