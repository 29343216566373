import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as contentActions from "../actions/DashboardAct";
import SpotlightContainerComp from "../component/SpotlightContainerComp";
import { ShowAlert } from "shared/actions/alert";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import { LIMIT } from "shared/constants/sharedPatient";
import { GET_PATIENT_CONTENT_ACTIVITY } from "../constants/DashboardConst";

const SpotlightContainer = (props) => {
   const { auth, contentActivitylist, showAllSpotlight, setShowSpotlight, showDirectory, folder_id, setSearchKey="" } = props;
   const [spotlightContent, setSpotlightContent] = useState({
      list: [],
      loading: true,
      limit: LIMIT,
      offset: 0,
      totalCount: 0,
      searchTerm: "",
      hasMore: false,
   });
   const [spotlightError, setSpotlightError] = useState(false);
   const getSpotlight = () => {
      const userId = auth?.userCredentials?.user?.id || null;
      let activityObj = {};
      activityObj = {
         limit: 50,
         offset: 0,
         ...(userId && {
            user: {
               id: userId,
            },
         }),
         patientOnly: true,
         providerOnly: false,
         isSpotlight: true,
         contentFilter: "my",
         allowDirectory: true,
         directoryId: folder_id,
         search: "",
         allowBreadcrumb: true,
      };
      fetchQuery(
         GET_PATIENT_CONTENT_ACTIVITY,
         activityObj,
         (res) => {
            const contentData = res?.data?.getPatientContent?.contents || [];
            const totalCount = res?.data?.getPatientContent?.totalCount || null
            const totalResults = contentData;
            if (totalCount === 0) {
               setShowSpotlight(false);
            }
            setSpotlightContent({
               ...spotlightContent,
               list: totalResults,
               loading: false,
               offset: contentActivitylist?.offset + contentActivitylist?.limit,
               totalCount: totalCount,
               hasMore: totalResults?.length < totalCount,
            });
         },
         (err) => {
            setSpotlightContent({
               ...spotlightContent,
               loading: false,
            });

            let msg = setSpotlightError(err);
            ShowAlert(msg, "error");
         }
      );
   };
   useEffect(() => {
      getSpotlight(folder_id);
   }, []);

   return (
      <>
         {spotlightContent?.totalCount > 0 && (
            <SpotlightContainerComp
               getSpotlight={getSpotlight}
               spotlightContent={spotlightContent}
               showAllSpotlight={showAllSpotlight}
               showDirectory={showDirectory}
               folder_id={folder_id}
               setSearchKey={setSearchKey}
               {...props}
            />
         )}
      </>
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      contentActivitylist: state?.contentActivitylist,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getPatientContentActivity: contentActions.getPatientContentActivity,
         setSearchTerm: contentActions.setContentSearchTerm,
         setPatientContent: contentActions.setPatientContent,
         reactHeart: contentActions.reactHeart,
         setInitialState: contentActions.setInitialState,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotlightContainer);
