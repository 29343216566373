import defaultLogo from "assets/img/pbh-logo.png";

export default function NavbarLogout(props) {
   // const path = window.location.pathname;

   // const showNavBar = path.includes("/sharing/");


   return (
      // <div className={`${showNavBar && "hidden sm:block"} `}>
      <div className={`bg-white `}>
  
         <div className="sm:border-bottom-cus">
            <nav id="navbar" className="flex items-center justify-between w-full pb-6 pt-5 pl-2 md:pl-0">
               <img
                  className="sm:w-[150px] ml-0 sm:ml-3 cursor-pointer h-[24px] sm:h-full"
                  src={defaultLogo}
                  alt={"imag-logo"}
               />
           
            </nav>
         </div>
    
      </div>
   );
}
