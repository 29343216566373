import React, { useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_FAMILY_FRIENDS_DATA } from "modules/shareFamilyFriends/actions/familyActions";
import { errorToDisplay } from "shared/actions/helpers";
import { ShowAlert } from "shared/actions/alert";
import NewCareTeam from "modules/newCareTeam/component/NewCareTeam";

const NewCareTeamCont = (props) => {
   const { auth, id = false } = props;
   const patientId = auth?.userCredentials?.user?.id || null
   const [careTeam, setCareTeam] = useState([]);
   const { loading } = useQuery(GET_FAMILY_FRIENDS_DATA, {
      fetchPolicy: "no-cache",
      variables: {
         user: {
            id: id ? id : patientId,
         },
         type: {
            isActive: true,
            type: "provider",
         },
      },
      onCompleted(result) {
         const data = result?.getCareTeam || [];
         setCareTeam(data);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
      },
   });
   return <NewCareTeam careTeam={careTeam} loading={loading} {...props} />;
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
   };
};

export default connect(mapStateToProps, null)(NewCareTeamCont);
