import React, { useState, useEffect, useCallback } from "react";
import Home from "modules/home/component/homeComp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";
import * as contentActions from "../actions/contentActivityActions";

const HomeCont = (props) => {
   const { auth, getPatientContentActivity, setSearchTerm, contentActivitylist, id = false, setInitialState } = props;
   const userId = id ? id : null;
   const contentList = contentActivitylist.list;
   const { searchTerm } = contentActivitylist;
   const viewPersisit = id
      ? localStorage.getItem("viewPatient") === "true" || false
      : localStorage.getItem("view") === "true" || false;
   const [filterby, setFilterBy] = useState("my");
   const [view, setView] = useState(viewPersisit);
   const forSharing = id;

   useEffect(() => {
      getPatientContentActivity(() => {}, true, "", userId, filterby);
      return () => {
         setInitialState();
      };
   }, [filterby]);

   const deb = useCallback(
      debounce((text) => {
         getPatientContentActivity(() => {}, true, text, userId, filterby);
      }, 500),
      []
   );

   const handleSearchText = (text) => {
      deb(text);
   };
   return (
      <Home
         handleSearchText={handleSearchText}
         filterby={filterby}
         setFilterBy={setFilterBy}
         contentList={contentList}
         searchTerm={searchTerm}
         userId={userId}
         setSearchTerm={setSearchTerm}
         view={view}
         setView={setView}
         forSharing={forSharing}
         {...props}
      />
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
      contentActivitylist: state.contentActivitylist,
   };
};

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getPatientContentActivity: contentActions.getPatientContentActivity,
         setSearchTerm: contentActions.setContentSearchTerm,
         setPatientContent: contentActions.setPatientContent,
         reactHeart: contentActions.reactHeart,
         setInitialState: contentActions.setInitialState,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeCont);
