import React, { useState, useEffect } from "react";
import Button from "shared/components/button";
import CustomDatePicker from "shared/components/datePicker/customDatePicker";
import Modal from "shared/components/modal/modal";
import calendarIcon from "images/login/calendar-icon.svg";
import { loginConst } from "shared/constants/loginConst";
import { socketActions, socketSubActions } from "socket/Websocket";
import { formatDate } from "shared/actions/helpers";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { bindActionCreators } from "redux";
import * as actions from "redux/actions/auth.action";
import { connect } from "react-redux";
import { ShowAlert } from "shared/actions/alert";
import { textConst } from "shared/constants/const";
import closeImage from "assets/mdi_close.svg";
import circleoutlined from "images/newdashbord/circleoutlined.svg";

const SwitchAccountPopup = ({ data, savenorthwelluserobj, saveusercredentials, onModalTapped, setShowPopup }) => {
   const [dateOfBirth, setDateOfBirth] = useState(null);
   const [IsLoading, setIsLoading] = useState(false);
   const Signin = loginConst?.patientOnboard?.Signin;
   const SigningIn = loginConst?.patientOnboard?.SigningIn;
   const dob = loginConst?.patientOnboard?.dobEnter;
   const name = data?.name || " ";
   const loginInuser = () => {
      if (!dateOfBirth) {
         ShowAlert(dob, textConst?.error);
         return;
      }
      setIsLoading(true);
      const obj = {
         action: socketActions?.auth,
         subAction: socketSubActions?.validatePatient,
         userId: data?.userId,
         enteredDob: formatDate(dateOfBirth, true),
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings?.status === 1) {
            firebase
               .auth()
               .signInWithCustomToken(resultStatus?.data?.customToken)
               .then((storeDataRedux) => {
                  let userData = { user: resultStatus?.data?.userData };
                  savenorthwelluserobj(JSON.parse(JSON.stringify(storeDataRedux)));
                  saveusercredentials(userData);
                  window.location.href ="/"
               })
               .catch((error) => {
                  setIsLoading(false);
               });
         } else {
            setIsLoading(false);
            ShowAlert(resultStatus?.settings?.message, textConst?.error);
         }
      });
   };

   useEffect(() => {
      window.addEventListener("keypress", submitOnKeyPress);
      return () => {
         window.removeEventListener("keypress", submitOnKeyPress);
      };
   }, []);

   const submitOnKeyPress = (e) => {
      if (e?.key === "Enter" || e?.code === "NumpadEnter") {
         document.getElementById("dateofbirthvalidator") && document.getElementById("dateofbirthvalidator").click();
      }
   };

   return (
      <Modal id="switchAccountPopup" onModalTapped={onModalTapped}>
         <div className="w-[350px] sm:w-[470px] bg-[#ffffff] rounded-[10px] card-shadow p-[40px] items-center justify-center flex-col relative">
            <img
               src={closeImage}
               alt={"close"}
               className="absolute right-5 top-4 w-[24px] h-[24px] cursor-pointer"
               onClick={() => {
                  setShowPopup(null);
               }}
            />
            <div className="flex-1 justify-center sm:w-[379px] w-[270px] sm:text-[26px] text-[20px] font-semibold text-[#043293] tracking-[-0.24px] mb-[14px]">
               <div className="text-center">Please verify {name}'s date of birth</div>
            </div>
            <div className="relative w-full pb-4">
               <CustomDatePicker
                  id="patient-switch-dob"
                  onSelect={(e) => {
                     setDateOfBirth(e?.getTime());
                  }}
                  onChange={(e) => {
                     setDateOfBirth(e?.getTime());
                  }}
                  value={dateOfBirth}
                  maxDate={new Date()}
                  onKeyPress={submitOnKeyPress}
               />
               <img
                  className="absolute cursor-pointer"
                  style={{ right: "15px", bottom: "30px" }}
                  src={calendarIcon}
                  alt=""
                  onClick={() =>
                     document.getElementById("patient-switch-dob") &&
                     document.getElementById("patient-switch-dob").click()
                  }
               />
            </div>
            <div className="gap-[6px] flex justify-start pb-[15.5px]">
               <img src={circleoutlined} alt="circleoutlined"/>
               <div className="text-[15px] text-[#8A8A8E] font-medium ">
                  You’re about to switch account.
               </div>
            </div>
            <div className="flex justify-center items-center">
               <Button
                  id="dateofbirthvalidator"
                  className="flex justify-center items-center w-[252px] h-[48px] text-[18px] font-medium text-[#FFF] opacity-100"
                  disabled={IsLoading ? true : false}
                  onClick={loginInuser}
               >
                  {IsLoading ? SigningIn : Signin}
               </Button>
            </div>
         </div>
      </Modal>
   );
};

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         savenorthwelluserobj: actions.savenorthwelluserobj,
         saveusercredentials: actions.saveusercredentials,
      },
      dispatch
   );
};

export default connect(null, mapDispatchToProps)(SwitchAccountPopup);
