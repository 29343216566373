import React, { memo } from "react";
import {
   getParam,
   openDirection,
   isDirection,
   formatPhoneNumberBackend,
   isDirectionGql,
   openDirectionGql,
} from "shared/actions/helpers";
import ProfileDetails from "shared/components/profile/profileDetails";
import listIcon from "images/careTeam/more.svg";
import { checkParams } from "actions/sharedPatientDetails/sharedPatientDetailsActions";
import { emptyStatesText } from "shared/constants/const";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import CareTeam from "images/emptyStates/careTeam.svg";

const tableHeaderStyle = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
};

const tableHeaderStyleEx = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
};

const tableHeaderStyleSmall = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
};

const TableCareTeam = memo(({ members = [], isLoading = false, openModal = () => {}, isShared }) => {
   if (isLoading)
      return (
         <div className="post-card p-4">
            {Array(10)
               .fill()
               .map((o, index) => (
                  <div
                     key={index}
                     className="flex animate-pulse flex-row items-center h-full justify-center space-x-1 rounded-lg "
                  >
                     <div className="flex flex-col space-y-3 w-full px-2 py-1 rounded-lg">
                        <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                     </div>
                  </div>
               ))}
         </div>
      );
   else if (members.length === 0)
      return (
         <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
            <EmptyStateComp src={CareTeam} headerText={emptyStatesText.emptyCareTeam} />
         </div>
      );
   else
      return (
         <div>
            <table className="w-full hidden sm:table" cellPadding="0" cellSpacing="0" border="0">
               <thead className="text-small">
                  <tr>
                     <th>
                        <div
                           className="table-head"
                           style={{
                              ...tableHeaderStyle,
                              borderTopLeftRadius: "8px",
                              borderLeft: "1px solid #ced4da",
                              paddingLeft: 15,
                           }}
                        >
                           Name
                        </div>
                     </th>
                     <th>
                        <div className="table-head" style={{ ...tableHeaderConst }}>
                           Mobile number
                        </div>
                     </th>
                     <th>
                        <div className="table-head" style={{ ...tableHeaderConst }}>
                           Office number
                        </div>
                     </th>
                     <th>
                        <div className="table-head" style={{ ...tableHeaderConst }}>
                           Email
                        </div>
                     </th>

                     <th>
                        <div
                           className={`table-head`}
                           style={
                              !isShared
                                 ? {
                                      ...tableHeaderStyleEx,
                                      borderRight: "1px solid #ced4da",
                                      borderTopRightRadius: "8px",
                                      paddingLeft: "5px",
                                   }
                                 : {
                                      ...tableHeaderStyleSmall,
                                      borderRight: "1px solid #ced4da",
                                      borderTopRightRadius: "8px",
                                      paddingLeft: "5px",
                                   }
                           }
                        >
                           Action
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {members.map((o, i) => {
                     const { careMember } = o;
                     const contactInfo = careMember?.contactInformation;
                     const initialsApi = careMember?.name?.initials || false;
                     const color = careMember?.colorCode || window.initialColors[0];
                     const name = careMember?.name?.fullName;
                     const mobileNumber = formatPhoneNumberBackend(contactInfo?.mobileNumber);
                     const officeNumber = formatPhoneNumberBackend(contactInfo?.officeNumber);
                     const deparment = careMember?.providerInfo?.department || "";
                     const title = careMember?.providerInfo?.title || "";
                     const showDirection = isDirectionGql(contactInfo) || false;
                     const className = checkParams(title, deparment) ? "py-1" : "py-[16.25px]";
                     const checkToShowMobile = careMember?.providerInfo?.settings?.cellToPatient ? mobileNumber : "-";
                     return (
                        <>
                           <tr
                              key={o?.id || i}
                              onClick={() => {
                                 openModal(o);
                              }}
                              className="cursor-pointer hover:bg-light-blue"
                           >
                              <td className={`pointer ${className}`}>
                                 <ProfileDetails
                                    src={`${process.env.REACT_APP_PROFILE_URL}/${o.careMember?.id}`}
                                    className="ml-4"
                                    title={name}
                                    color={color}
                                    initialsApi={initialsApi}
                                    name={name}
                                    details={[{ title: title }, { title: deparment }]}
                                 />
                              </td>
                              <td className="table-data">{checkToShowMobile}</td>
                              <td className="table-data ">{officeNumber}</td>
                              <td className="table-data ">{getParam(contactInfo?.email)}</td>

                              <td
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    if (showDirection) {
                                       openDirectionGql(contactInfo);
                                    }
                                 }}
                              >
                                 <div className={` ${isShared && "text-center"} table-data pb-pri`}>
                                    {showDirection ? "Get directions" : "-"}
                                 </div>
                              </td>
                           </tr>
                        </>
                     );
                  })}
               </tbody>
            </table>
            <div className="sm:hidden w-full">
               {members.map((o, i) => {
                  const { careMember } = o;
                  const initialsApi = careMember?.name?.initials || false;
                  const color = careMember?.colorCode || window.initialColors[0];
                  const name = careMember?.name?.fullName;
                  const deparment = careMember?.providerInfo?.department || "";
                  const title = careMember?.providerInfo?.title || "";
                  const className = checkParams(title, deparment) ? "py-1" : "py-[16.25px]";

                  return (
                     <div
                        onClick={() => {
                           openModal(o);
                        }}
                        className={`flex items-center justify-between ${className} hover:bg-light-blue`}
                     >
                        <ProfileDetails
                           src={`${process.env.REACT_APP_PROFILE_URL}/${o.careMember?.id}`}
                           title={name}
                           color={color}
                           initialsApi={initialsApi}
                           name={name}
                           details={[{ title: title }, { title: deparment }]}
                        />
                        <img src={listIcon} alt="list" />
                     </div>
                  );
               })}
            </div>
         </div>
      );
});

export default TableCareTeam;
