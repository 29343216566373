import React, { useEffect } from "react";

export default function DropOtionsCustom({ options, setDrop, onChange = () => {}, customDropDown }) {
   useEffect(() => {
      window.addEventListener("click", onClickOutSide);

      return () => {
         window.removeEventListener("click", onClickOutSide);
      };
   }, []);

   const onClickOutSide = (e) => {
      const condition =
         document.getElementById("dropDownCustomMain") &&
         document.getElementById("dropDownCustomMain").contains(e.target);
      if (!condition) {
         setDrop();
      }
   };

   return (
      <div id="dropDownCustomMain">
         {customDropDown
            ? <div onClick={()=>{setDrop()}}>
               {customDropDown()}
               </div>
            : options.map((map, i) => (
                 <div key={map.id || map.val || map} className={`${i !== options.length - 1 && "border-drop-down"} `}>
                    <div
                       onClick={(e) => {
                          e.stopPropagation();
                          setDrop();
                          onChange(map);
                       }}
                       className="  hover:bg-[#0364e61a] flex items-center px-3 py-2 rounded-lg text-[17px] font-medium cursor-pointer"
                    >
                       <div className=" flex items-center text-xs sm:text-lg ml-2">
                          {map?.img && <img src={map.img} alt="edit-icon" className="mr-4" />} {map.text}
                       </div>
                    </div>
                 </div>
              ))}
      </div>
   );
}
