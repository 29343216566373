import { ShowAlert } from "shared/actions/alert";
import copy from "modules/dateOfBirth/images/copy.svg";
import "modules/dateOfBirth/screen/my_profile/mediaview.css";
import MedicalReport from "modules/dateOfBirth/screen/my_profile/markdownRenderer";

const TextViewer = ({ content, loading }) => {
   const removeMarkdownSpecialCharacters = (markdownString) => {
      const markdownRegex = /([*_~`]|^#{1,6}\s|^\[([^\]]+)\]\([^)]+\))/g;
      let cleanString = markdownString.replace(markdownRegex, "");
      cleanString = cleanString.replaceAll("###", "");
      return cleanString;
   };
   const copyContent = () => {
      const texttoCopy = isMarkdown(content) ? removeMarkdownSpecialCharacters(content) : content;
      navigator.clipboard
         .writeText(texttoCopy)
         .then(() => {
            ShowAlert("Copied to clipboard.");
         })
         .catch((error) => {
            ShowAlert("Error copying messages", "error");
         });
   };

   const isMarkdown = (text) => {
      const markdownPatterns = [
         /^#\s/, // Header
         /^\*\s|\d\.\s|\-\s/, // List
         /\[.*\]\(.*\)/, // Link
      ];
      for (const pattern of markdownPatterns) {
         if (pattern.test(text)) {
            return true;
         }
      }
      return false;
   };

   return (
      <>
         <div className="copy-container-button">
            <button onClick={copyContent} className="button-copy">
               <img src={copy} alt="copy-icon" className="copy-button-image" />
               <div className="copy-action">Copy to Clipboard</div>
            </button>
         </div>
            <div className="scrole-hight no-scrollbar">
               {loading ? <div class="flex justify-center justify-items-center w-100">
               <div class="border-t-transparent border-solid animate-spin  rounded-full border-[#043293] border-4 h-10 w-10"></div>
            </div> :
               <MedicalReport content={content} />
            }
            </div>
      </>
   );
};

export default TextViewer;
