import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { configure } from "shared/constants/const";
import PatientContainer from "modules/patientOnboard/container/patientContainer";
import LoginCont from "modules/login/container/loginCont";
import Socket from "socket/Websocket";
import NavbarCont from "modules/navbar/container/navBarContainer";
import SettingsCont from "modules/settings/container/settingsContainer";
import SharedPatientDetailsCont from "modules/sharedPatientDetails/container/sharedPatientDetailsCont";
import ChangeNotificationsCont from "modules/changeNotifications/container/changeNotificationsContainer";
import WithApolloClient from "wrappers/apolloWrapper";
import ScrollToTop from "./scrollToTop";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import DashboardCont from "modules/dashboard/container/DashboardCont";
import NewCareTeamCont from "modules/newCareTeam/container/NewCareTeamCont";
import SharingCont from "modules/sharing/container/SharingCont";
import DobCont from "modules/dateOfBirth/container/DobCont";
import NavbarLogout from "modules/navbar/component/navBarComponentLogout";

window.socket = new Socket();
firebase.initializeApp(configure);

function RouterComp(props) {
   const { auth, darkly } = props;
   const showNotificationSettings = darkly.ldFeatureFlags?.graphqlNotification || false;
   let body = "";
   const isLoggedIn = auth.userCredentials !== null && auth.userCredentials !== undefined;
   const path = window.location.pathname;
   const showNavBar = path.includes("/viewer");
   if (isLoggedIn) {
      body = (
         <div className="mb-7">
            {showNavBar ?
            <NavbarLogout/>:
            <div className="bg-[#fff] fixed top-0 z-[49] left-0 right-0 xl:left-0 xl:right-4">
               <NavbarCont />
            </div>
   }
            <div
               className={`md:px-8 xl:px-0 ${
                  isLoggedIn && "max-w-[1143px]"
               }  ${showNavBar ? "mx-auto" :"mx-auto md:pt-[100px] sm:pt-[88px] pt-[125px] px-6 "}`}
            >
               <Routes>
                  <Route path="/" element={<DashboardCont showDashboard={true} />} />
                  <Route exact path="/care-team" element={<NewCareTeamCont />} />
                  <Route exact path="/sharing" element={<SharingCont />} />
                  <Route exact path="/sharing/:id" element={<SharedPatientDetailsCont />} />
                  <Route exact path="/settings" element={<SettingsCont />} />
                  <Route exact path="/all-spotlight" element={<DashboardCont showAllSpotlight={true} />} />
                  <Route exact path="/folder/:folder_id" element={<DashboardCont showfolder={true} />} />
                  {showNotificationSettings && (
                     <Route exact path="/notifications_settings" element={<ChangeNotificationsCont />} />
                  )}
                  <Route path="*" element={<DashboardCont />} />
                  <Route path="/viewer" element={<DobCont/>}/>
               </Routes>
            </div>
         </div>
      );
   } else {
      body = (
         <div className={`${isLoggedIn && "max-w-[1143px]"} mx-auto`}>
             {showNavBar &&
            <NavbarLogout/>
   }
            <Routes>
               <Route exact path="/sign-up" element={<PatientContainer />} />
               <Route path="/" element={<LoginCont />} />
               <Route path="*" element={<LoginCont />} />
               <Route path="/viewer" element={<DobCont/>}/>
            </Routes>
         </div>
      );
   }

   return (

         <div className={`bg-[#F8FAFB] sm:m-auto ${showNavBar ?(isLoggedIn && "max-w-full"): (isLoggedIn && "max-w-[1240px]")} `}>
         <ToastContainer />
         <BrowserRouter>
            <ScrollToTop />
            {body}
         </BrowserRouter>
      </div>
   );
}

export default WithApolloClient(RouterComp);
