import React, { useState } from "react";
import clock from "images/newdashbord/clock.svg";
import arrow from "images/newdashbord/Back.svg";
import AppointmentPopup from "./AppointmentPopup";
import Avatar from "shared/components/avatar";

const MultipleAppointment = ({
   src = "",
   color,
   initialsApi,
   name,
   hospitalName,
   reason,
   mobileNumber,
   index,
   appointmentLength,
   appointmentTime,
   singleAppoint,
   info,
   isAddress,
}) => {
   const [showPopup, setShowPopup] = useState(null);
   const clickHandler = () => {
      setShowPopup();
   };
   return (
      <>
         <div
            key={index}
            className={`${
               appointmentLength > 1 ? "max-w-[570px] min-w-[280px] " : "w-full"
            } h-[130px] bg-[#fff] mt-[1px] mx-[1px] card-shadow rounded-[10px] p-[16px] flex-1 justify-center items-center cursor-pointer`}
            onClick={() => {
               clickHandler();
            }}
         >
            <div className="flex gap-[8px] h-[20px] justify-start items-center">
               <img src={clock} alt="clock" className="w-[18px] h-[18px]" />
               <div title={appointmentTime} className="text-[#1D1D1F] font-light text-[17px]">{appointmentTime}</div>
            </div>
            <div className="divider my-[16px] h-[1px] bg-[#E0E0E0]"></div>
            <div className="flex justify-between items-center  w-[100%]">
               <div className="flex gap-[16px] items-center">
                  <Avatar
                     src={src}
                     isProvider={true}
                     className="sm:flex-shrink-0 pointer mr-4 h-[48px] w-[48px]"
                     bgColor={color}
                     radius={48}
                     initialsApi={initialsApi}
                     name={name}
                  />
                  <div
                     className={`${
                        singleAppoint
                           ? " W-[250px] sm:w-[450px] md:w-[590px]"
                           : "max-w-[170px] min-w-[100px] lg:max-w-[200px] lg:min-w-[200px] flex-row justify-start"
                     }`}
                  >
                     <div 
                        title={name}
                        className={`text-[#1D1D1F] text-[17px] font-medium ${
                           singleAppoint
                              ? "w-[160px] sm:w-auto md:w-auto truncate"
                              : "w-[50vw] sm:max-w-[170px] min-w-[100px] lg:max-w-[200px] lg:min-w-[200px] truncate"
                        }`}
                     >
                        {name}
                     </div>
                     <div title={reason} className="text-[#8A8A8E] text-[13px] font-medium w-[50vw] sm:max-w-[170px] min-w-[100px] lg:max-w-[200px] lg:min-w-[200px] truncate">
                        {reason}
                     </div>
                  </div>
               </div>
               <img src={arrow} alt="arrow" className="w-[24px] h-[23px]" />
            </div>
         </div>

         {showPopup !== null && (
            <AppointmentPopup
               data={showPopup}
               onModalTapped={() => {
                  setShowPopup(null);
               }}
               setShowPopup={setShowPopup}
               color={color}
               initialsApi={initialsApi}
               name={name}
               hospitalName={hospitalName}
               reason={reason}
               mobileNumber={mobileNumber}
               appointmentTime={appointmentTime}
               info={info}
               isAddress={isAddress}
            />
         )}
      </>
   );
};

export default MultipleAppointment;
