import React, { memo, useState, useEffect, useMemo } from "react";
import audioIcon from "../../../images/newimages/recording-icons/visualizer.svg";
import defaultThumb from "images/newimages/default-thumb.svg";
import playIcon from "images/dashboard/playIcon.svg";
import {
   callMobileNumber,
   isIOS,
   isNumber,
   isValidEmail,
   isValidUrl,
   openLink,
   sendMail,
} from "shared/actions/helpers";
import { useQuery } from "@apollo/client";
import { GET_THUMBNAIL_SIGNED_URL, UPDATE_ITEM_VIEWED_STATUS } from "modules/home/constants/contentConstants";
import { fetchMutation } from "shared/actions/fetchMutationAction";
import { connect } from "react-redux";
import TranscriptionGenerator from "modules/transcription/components/transcriptionGenerator";

export const VideoNew = (props) => {
   const { selectedLanguage } = props;
   const [showCaptions, setShowCaptions] = useState(false);
   useEffect(() => {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      return () => {
         document.removeEventListener("fullscreenchange", handleFullscreenChange);
      };
   }, []);

   const handleFullscreenChange = () => {
      const videoPlayer = document.getElementById("videoPlayer-subtitle");
      if (document.fullscreenElement === videoPlayer) {
         setShowCaptions(true);
      } else {
         setShowCaptions(false);
      }
   };
   const isTranscriptionAvaiable = showCaptions && props.transcriptionData ? true : false;
   return (
      <video
         id="videoPlayer-subtitle"
         style={{
            transform: props.mirrored ? "rotateY(180deg)" : null,
            WebkitTransform: props.mirrored ? "rotateY(180deg)" : null,
            MozTransform: props.mirrored ? "rotateY(180deg)" : null,
            backgroundColor: "black",
            flex: "1",
            width: "100%",
            height: "100%",
            borderTopLeftRadius: "13px",
            borderTopRightRadius: isTranscriptionAvaiable ? "13px" : "",
         }}
         controlsList="nodownload"
         disablePictureInPicture
         {...props}
      >
         {isTranscriptionAvaiable && (
            <>
               {props.transcriptionData?.fileBlobs?.map((s, i) => {
                  const isDefault = s.code === selectedLanguage?.code;
                  return (
                     <track
                        src={URL.createObjectURL(s.blob)}
                        kind="captions"
                        label={s.lang}
                        srclang={s.code}
                        default={isDefault}
                     />
                  );
               })}
            </>
         )}
         {props.children}
      </video>
   );
};
export const Video = (props) => {
   return (
      <video
         id="videoPlayer-subtitle"
         style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            transform: props.mirrored ? "rotateY(180deg)" : null,
            WebkitTransform: props.mirrored ? "rotateY(180deg)" : null,
            MozTransform: props.mirrored ? "rotateY(180deg)" : null,
            backgroundColor: "black",
            height: "100%",
            borderTopLeftRadius: "13px",
            borderTopRightRadius: "13px",
         }}
         controlsList="nodownload"
         disablePictureInPicture
         {...props}
      >
         {props.children}
      </video>
   );
};

export const Audio = (props) => (
   <audio controls className="shadow round-border-l" controlsList="nodownload" {...props} />
);

export const AudioPlaybackView = (props) => (
   <div className="relative h-full w-full flex items-center justify-content-center">
      <div className="absolute w-full h-full">
         <img
            alt="audio"
            style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2))" }}
            className=" bg-light-blue h-full w-full rounded-t-2xl "
            src={audioIcon}
         />
      </div>
      <div className="w-full flex items-center justify-center">
         <audio controls className="shadow round-border-l" controlsList="nodownload" {...props} />
      </div>
   </div>
);

export const AudioPlaybackViewTranscription = (props) => {
   const { transcriptionData = null, selectedLanguage, setSelectedLanguage } = props;
   return (
      <div className={`w-full h-full  relative ${!transcriptionData && "bg-light-blue"} `}>
         {transcriptionData?.transcription ? (
            <div className="transcription-values-audio p-4">
               <TranscriptionGenerator
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  transcriptionData={transcriptionData}
                  type={"audio"}
                  id={"audio-subtitle"}
               />
            </div>
         ) : (
            <img className="w-full h-full mb-5 px-5" src={audioIcon} alt="" />
         )}
         <div className={`w-full flex-center audio-container-position `}>
            <Audio src={props.src} autoPlay={props.isPreview} id="audio-subtitle" className="audio-caption-label" />
         </div>
      </div>
   );
};

const PostView = memo(
   (props) => {
      const { data, className, isPreview = false, PostCard, user, isLibraryFile = false, showTranscription } = props;
      const [signedUrl, setsignedUrl] = useState(null);
      const [thumbnailUrl, setThumbnailUrl] = useState(defaultThumb);
      const [transcriptionData, setTranscriptionBlob] = useState(null);
      const [selectedLanguage, setSelectedLanguage] = useState({ code: "en", lang: "English" });
      const skip = data?.type === "text" || data?.type === "item" || data?.type === "referral" ? true : false;
      const ftype = data?.fileType?.split("/")[0] || data?.type;

      useEffect(() => {
         if (!isPreview) return;
         fetchMutation(UPDATE_ITEM_VIEWED_STATUS, {
            options: {
               eventName: "itemViewed",
               itemId: data?.id,
            },
         });
      }, []);

      useQuery(GET_THUMBNAIL_SIGNED_URL, {
         skip,
         fetchPolicy: "cache-and-network",
         variables: {
            content: {
               id: data.id,
               description: "",
            },
            thumbnail: true,
            includeTranscription: isPreview,
         },
         onCompleted(result) {
            setThumbnailUrl(result.getSignedURL?.thumbnailUrl);
            setsignedUrl(result.getSignedURL?.url);
            if (result.getSignedURL?.Transcription?.hasOwnProperty("en")) {
               let lang = [{ lang: "English", code: "en" }];

               if (showTranscription) {
                  const multi = [
                     { lang: "Spanish", code: "es" },
                     { lang: "Chinese", code: "zh-CN" },
                     { lang: "Hindi", code: "hi" },
                     { lang: "Punjabi", code: "pa" },
                  ];
                  lang = [...lang, ...multi];
               }

               const formatTime = (seconds) => {
                  const date = new Date(null);
                  date.setSeconds(seconds);
                  const timeString = date.toISOString().substr(14, 5);
                  const milliseconds = (seconds % 1).toFixed(3).substr(2);
                  return `${timeString}.${milliseconds}`;
               };
               const transcription = result.getSignedURL?.Transcription;
               let blobs = [];
               for (let i = 0; i < lang.length; i++) {
                  if (transcription[lang[i]?.code]) {
                     const webvttContent = transcription[lang[i]?.code]
                        .map((item) => {
                           const start = formatTime(item.start);
                           const end = formatTime(item.end);
                           const text = item.text;
                           return `${start} --> ${end}\n${text}\n\n`;
                        })
                        .join("");
                     const webCaptions = `WEBVTT \n\n` + webvttContent;
                     const webvttBlob = new Blob([webCaptions], { type: "text/vtt" });
                     blobs.push({ blob: webvttBlob, code: lang[i]?.code, lang: lang[i]?.lang });
                  }
               }
               setTranscriptionBlob({ transcription, fileBlobs: blobs });
            }
         },
      });

      const getMediaURL = useMemo(() => {
         if (isPreview && signedUrl) {
            return signedUrl;
         } else {
            if (data?.type === "referral")
               return `${process.env.REACT_APP_PROFILE_URL}/${user?.enterpriseId}_logo?id=${Date.now()}`;
            else if (!data?.hasThumbnail) return defaultThumb;
            return thumbnailUrl;
         }
      }, [data?.thumbnail, signedUrl, thumbnailUrl]);

      const addDefaultSrc = (e) => {
         e.target.src = defaultThumb;
      };

      const isAudio = ftype?.includes("audio") || ftype === "audio";
      const isItem = data?.type === "item" || data?.type === "text";
      const isImage = ftype?.includes("image") || ftype === "image";
      const isVideo = ftype === "video" || ftype?.includes("video");
      const isApplication = ftype?.includes("application");
      const showPlayIcon = (isAudio || isVideo) && isPreview === false;
      if (isItem) {
         return (
            <div
               className={`w-100 text-[17px] font-medium ${
                  isPreview
                     ? "overflow-x-auto no-scrollbar h-[92vh] lg:h-[66.5vh] mt-10 md:mt-20 mb-0 md:mb-6 w-full px-4"
                     : " text-[#8A8A8E] mx-2 pt-[0.25rem] h-[138px] w-100 custom-new-truncate"
               } `}
            >
               <div className={`${PostCard && "ml-4"}`}>
                  {
                     <div
                        className={`flex flex-wrap ${
                           isPreview ? "h-0 sm:h-[200px] md:h-0" : "h-[138px] flex items-center justify-center "
                        } w-full`}
                     >
                        <pre style={{whiteSpace: "pre-wrap", font: "inherit"}}>
                        {data?.title}
                        </pre>
                     </div>
                  }
                  <div className="whitespace-pre-line">{data?.subTitle}</div>
               </div>
            </div>
         );
      } else if (isImage || !isPreview) {
         let fitType = "cover";
         return (
            <div className={data?.type === "referral" ? "h-100" : " h-full w-full relative  "}>
               {data?.type === "referral" ? (
                  <div className={`${PostCard && "ml-4"} pt-8 px-2 `}>
                     <div className="para-one">{data.titleDescription}</div>
                     <div className="para-two">
                        {data.subTitleDescription}
                        <br />
                     </div>

                     <div className="para-two">see more...</div>
                  </div>
               ) : (
                  <>
                     {isAudio ? null : (
                        <div
                           className={`absolute w-full ${ftype === "application" ? " z-0 h-full " : "h-full"}  ${
                              ftype === "audio" ? "hidden" : "z-10"
                           }`}
                        >
                           <img
                              style={{ objectFit: isPreview ? "contain" : fitType }}
                              alt="thumbnails"
                              className="h-full w-full rounded-[8px]"
                              src={isPreview ? getMediaURL : thumbnailUrl}
                              onError={(e) => {
                                 e.target.src = defaultThumb;
                                 e.target.style.objectFit = "cover";
                                 e.target.style.maxHeight = "100%";
                                 e.target.style.maxWidth = "100%";
                              }}
                           />
                        </div>
                     )}
                  </>
               )}
               {showPlayIcon && (
                  <img
                     src={playIcon}
                     alt="play-icon"
                     className="absolute h-[50px] w-[50px] top-[35%] left-[45%] xl:left-[43%] z-[48]"
                  />
               )}
               {isAudio ? (
                  <div className="absolute w-full h-full">
                     <img
                        alt="audio"
                        style={
                           !isPreview
                              ? {
                                   backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2))",
                                   padding: "20px",
                                }
                              : {}
                        }
                        className=" bg-light-blue h-full w-full rounded-[8px] "
                        src={audioIcon}
                     />
                  </div>
               ) : null}
            </div>
         );
      } else if (isVideo) {
         return (
            <>
               {transcriptionData ? (
                  <div className="container-transcription">
                     <div className="child-video-transcription w-full h-full">
                        <VideoNew
                           id={"videoPlayer-subtitle"}
                           width="100%"
                           height="100%"
                           controls
                           src={getMediaURL}
                           autoPlay={!isPreview}
                           transcriptionData={transcriptionData}
                           selectedLanguage={selectedLanguage}
                        />
                     </div>
                     {transcriptionData && (
                        <div className="transcription-values hidden sm:block">
                           <TranscriptionGenerator
                              transcriptionData={transcriptionData}
                              selectedLanguage={selectedLanguage}
                              setSelectedLanguage={setSelectedLanguage}
                              id={"videoPlayer-subtitle"}
                           />
                        </div>
                     )}
                  </div>
               ) : (
                  <Video width="100%" height="100%" controls src={getMediaURL} autoPlay={isPreview} />
               )}
            </>
         );
      } else if (isApplication) {
         return signedUrl ? (
            isIOS() ? (
               <div className="flex flex-col items-center justify-center bg-slate-900 w-full h-full">
                  <div className="text-white truncate max-w-xs">{data.title}</div>
                  <button
                     onClick={() => (window.location.href = getMediaURL)}
                     className="primary-bg text-white text-sm font-semibold w-[90%] p-2 rounded-md  w-[100%] mt-2 sm:mt-0 sm:w-[120px] sm:ml-3 h-[50px]"
                  >
                     Open
                  </button>
               </div>
            ) : (
               <div className="w-full h-full">
                  <iframe
                     className="iframe-pdf"
                     title="web-frame"
                     src={`${getMediaURL}#zoom=30&scrollbar=0&#toolbar=0&navpanes=0`}
                     onContextMenu={(e) => e.preventDefault()}
                     style={{ zIndex: "9" }}
                  />
               </div>
            )
         ) : (
            <div className="flex animate-pulse items-center">
               <div className="sm:w-[600px] grow bg-gray-200 sm:h-[470px] rounded-md  "></div>
            </div>
         );
      } else if (isAudio) {
         return (
            <AudioPlaybackViewTranscription
               src={getMediaURL}
               isPreview={isPreview}
               transcriptionData={transcriptionData}
               selectedLanguage={selectedLanguage}
               setSelectedLanguage={setSelectedLanguage}
            />
         );
      } else {
         return (
            <img
               className={`w-100 ${className}`}
               src={getMediaURL}
               onError={addDefaultSrc}
               width="100%"
               height="100%"
               alt=""
               style={{
                  maxWidth: isLibraryFile ? "" : "1037.71px", //225px
                  maxHeight: isLibraryFile ? "" : "461.86px", //125px
                  minWidth: isLibraryFile ? "" : "225px",
                  minHeight: isLibraryFile ? "" : "125px",
                  objectFit: "contain",
               }}
            />
         );
      }
   },
   (prev, next) => prev.type === next.type
);

const mapStateToProps = (state) => {
   return {
      showTranscription: state.darkly?.ldFeatureFlags?.transcriptionFlag || false,
   };
};

export default connect(mapStateToProps, null)(PostView);
