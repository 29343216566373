import React, { useState } from "react";
import { settingsConst, validations } from "shared/constants/settingsConst";
import Modal from "shared/components/modal/modal";
import EditDialog from "./editDialog";
import ActivatePatient from "modules/banner/Component/activatePatinent";
import SettingsRow from "./settingsRow";
import {
   getDobFormat,
   formatPhoneNumber,
   checkEmail,
   unformatNumber,
   capitalizeFirstLetter,
} from "shared/actions/helpers";
import { getTitle } from "actions/settings/settingsAction";
import LoadingIndicator from "shared/components/loader";
import EditImage from "./editImage";
import { ShowAlert } from "shared/actions/alert";
import { textConst } from "shared/constants/const";

const SettingsComponent = (props) => {
   const [fname, setFname] = useState("");
   const [mname, setMname] = useState("");
   const [lname, setLname] = useState("");
   const [email, setEmail] = useState("");
   const [deleteButton, setDeleteButton] = useState(false);
   const [disabledEditButton, setDisabledButton] = useState(true);
   const [showEditImage, setShowEditImage] = useState(false);
   const [mobileNumber, setMobileNumber] = useState("");
   const [activatePatient, showActivatePatinet] = useState(false);
   const [selected, setSelected] = useState(null);
   const [showDP, setShowDP] = useState(false);

   const { auth, updatePatientProfile, showHide, show, setShow, loading, setLoading,updateAuth } = props;
   const { user } = auth.userCredentials;
   const patientData = user;

   const title = getTitle(selected);
   const toggle = () => {
      showActivatePatinet(!activatePatient);
   };

   const namePayload = {
      variables: {
         user: {
            name: {
               firstName: fname,
               lastName: lname,
               middleName: mname,
            },
         },
      },
   };

   const emailNumberPayload = {
      variables: {
         user: {
            contactInformation: {
               ...(selected === 4 && { email }),
               ...(selected === 5 && { mobileNumber: "+" + unformatNumber(mobileNumber) }),
            },
         },
      },
   };

   const updateName = () => {
      setLoading(true);
      updatePatientProfile(namePayload);
   };

   const updateEmailNumber = () => {
      setLoading(true);
      updateAuth(emailNumberPayload);
   };

   const validate = () => {
      if (selected === 1) {
         if (fname.length < 2 || lname.length < 2) {
            ShowAlert(validations.nameError, textConst.error);
            return;
         } else updateName();
      } else if (selected === 4) {
         if (!checkEmail(email)) {
            ShowAlert(textConst.email, textConst.error);
            return;
         } else updateEmailNumber();
      } else if (selected === 5) {
         if (unformatNumber(mobileNumber).length < 10) {
            ShowAlert(textConst.phone, textConst.error);
            return;
         } else updateEmailNumber();
      }
   };

   const openDialog = (sel) => {
      if (sel === 2) {
         setShowEditImage(true);
         setShowDP(true);
      } else {
         setShow(!show);
         setSelected(sel);
         if (sel === 1) {
            var name = patientData.name.split(" ");
            if (name.length === 3) {
               setFname(name[0]);
               setMname(name[1]);
               setLname(name[2]);
            } else if (name.length < 3) {
               setFname(name[0]);
               setLname(name[1]);
            }
         } else if (sel === 4) {
            setEmail(patientData.email || "");
         } else if (sel === 5) {
            setMobileNumber(patientData.mobileNo || "");
         }
      }
   };

   const phNumber = patientData?.mobileNo;
   const concatNumber = phNumber?.length > 10 ? phNumber.substring(2) : phNumber;

   const toggleImage = () => {
      setShowEditImage(!showEditImage);
   };
   const patientName = patientData?.name || "";
   const dob = (patientData?.dob && getDobFormat(patientData.dob)) || "";
   const patientEmail = patientData?.email || "";
   const patientMobileNumber = (patientData?.mobileNo && formatPhoneNumber(concatNumber)) || "";
   const initialsApi = patientData.initials;
   const color = patientData.colorCode || window.initialColors[0];
   const name = patientData.name;
   return (
      <>
         {patientData === true ? (
            <div className="w-full h-[75vh] flex items-center justify-center">
               <LoadingIndicator />
            </div>
         ) : (
            <div className="w-full sm:w-7/12 m-auto mt-7 h-100">
               <div className="text-[22px] font-medium text-black pb-3">{settingsConst.settings}</div>
               <SettingsRow
                  disabled={disabledEditButton}
                  deleteButton={deleteButton}
                  setDeleteButton={() => {
                     setDisabledButton(false);
                     setDeleteButton(true);
                  }}
                  showError={() => {
                     setDisabledButton(false);
                  }}
                  patientName={patientName}
                  dob={dob}
                  patientEmail={patientEmail}
                  patientMobileNumber={patientMobileNumber}
                  showHide={showHide}
                  name={name}
                  user={user}
                  color={color}
                  initialsApi={initialsApi}
                  toggle={toggle}
                  openDialog={openDialog}
               />

               {show && (
                  <Modal onModalTapped={showHide}>
                     <EditDialog
                        loading={loading}
                        validate={validate}
                        title={title}
                        onModalTapped={showHide}
                        selected={selected}
                        fname={fname}
                        lname={lname}
                        mname={mname}
                        setFname={(e) => {
                           setFname(capitalizeFirstLetter(e.target.value));
                        }}
                        setMname={(e) => {
                           setMname(capitalizeFirstLetter(e.target.value));
                        }}
                        setLname={(e) => {
                           setLname(capitalizeFirstLetter(e.target.value));
                        }}
                        email={email}
                        setEmail={(e) => {
                           setEmail(e.target.value);
                        }}
                        setMobileNumber={(e) => {
                           setMobileNumber(e.target.value);
                        }}
                        mobileNumber={mobileNumber}
                     />
                  </Modal>
               )}
               {activatePatient && (
                  <Modal onModalTapped={toggle}>
                     <ActivatePatient onModalTapped={toggle} />
                  </Modal>
               )}
               {showEditImage && (
                  <Modal onModalTapped={toggleImage}>
                     <EditImage
                        user={user}
                        onModalTapped={toggleImage}
                        imageAvailable={deleteButton}
                        imageUrl={`${process.env.REACT_APP_PROFILE_URL}/${user?.id}?ver=${Math.random()}`}
                        showDP={showDP}
                        setShowDP={setShowDP}
                     />
                  </Modal>
               )}
            </div>
         )}
      </>
   );
};

export default SettingsComponent;
