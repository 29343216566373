export const loginConst = {
   patientOnboard: {
      createAcc: "Create account",
      createAccSubtitle: "to continue to Playback Health ",
      enterDetails: "Enter details",
      enterDetailsSubtitle: "to continue to Playback Health",
      healthcareFacility: "Choose healthcare facility",
      codeSent: "Please enter the code sent to",
      Signin: "Sign in",
      dateOfbirth: "Date of birth",
      dobEnter: "Please enter your birth date ",
      chooseAccount: "Choose an account",
      welcome: "Welcome",
      details: "Please enter the following information below to create your Playback profile",
      confirmDob: "Confirm your DOB",
      sameDob: "If you have same DOB as the patient, please click to “Continue” below",
      createProfile: "If you have same DOB as the patient, please click to “Continue” below",
      continue: "Continue",
      profile: "Create Profile",
      sure: "Are you sure you wanna save your birth date as ",
      signUp: "Sign up",
      requestSubmit: " does not support self sign-up. Please fill form to request your patient account.",
      requestSignup: "Request sign-up",
      selectHospitalText: "Please select a hospital",
      myAccounts: "My Accounts",
      sharingWithMe: "Sharing with Me",
      SigningIn:"Signing in...."
   },
};

export const placeholders = {
   skip: "Skip this step",
   createContent: "Create account",
   signIn: "Sign in",
   noAccount: "Don't have an account?",
   existingAccount: "Already have an account ? ",
   signin: " sign in",
   createLogin: " create account",
   next: "Next",
   dateOfBirth: "Date of birth",
   email: "Email",
   mobile: "Mobile number",
   fullName: "Full name",
   resend: "resend",
   selectDoctor: "Select Doctor",
   needHelp: "Need help! message us",
   submit: "Submit",
   requestSubmitted: "Your request has been submitted successfully",
   approveNotifications: "Will notify you once approved",
   anotherAccount: "Return to login screen",
};
