import { combineReducers } from "redux";
import auth from "./auth.reducers";
import darkly from "./darklyReducer";
import contentActivitylist from "./patientContentReducer";
import common from "./commonReducer"

const rootReducer = combineReducers({
   auth,
   darkly,
   contentActivitylist,
   common,
});

export default rootReducer;
