import React, { useEffect, useState } from "react";
import { sharingText, emptyStatesText } from "shared/constants/const";
import InviteFamily from "./SharingInvite";
import Modal from "shared/components/modal/modal";
import Button from "shared/components/button";
import { capitalizeFirstLetter, isValidUsNumber, unformatNumber } from "shared/actions/helpers";
import SharingSmallScreenTable from "./SharingSmallScreenTable";
import { useNavigate } from "react-router-dom";
import TableSharing from "./TableSharing";
import { filterFamilyMembers } from "../actions/SharingActions";
import group from "images/newdashbord/group.svg";
import { getToken } from "actions";
import { socketActions, socketSubActions } from "socket/Websocket";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commenActions from "redux/actions/commonAction.js";
import useDebounce from "./useDebounce";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import ShareImg from "images/emptyStates/share.svg";
// import Search from "modules/navbar/component/searchComp";
import TableLoading from "modules/dashboard/allskeleton/TableLoading";
import UserDetailPopup from "./UserDetailPopup";
import LoadingIndicator from "shared/components/loader";

function Sharing(props) {
   const navigate = useNavigate();
   const {
      familyFriendsList,
      setFamilyFriendsList,
      loading,
      removeFamilyMember,
      setSelectedPatient,
      addToFriendsFamily,
      setLoadingInvite,
      loadingInvite,
      invite,
      setInvite,
      setShowPopup,
      sharingList,
      searchKey,
      setSearchKey,
   } = props;
   const [table, setTable] = useState(0);
   const [emailInvite, setEmailInvite] = useState("");
   const [nameInvite, setNameInvite] = useState("");
   const [relation, setRelation] = useState("");
   const [eachCare, setEachCare] = useState(null);
   const [showRemove, setShowRemove] = useState(false);
   const [showDirection, setShowDirection] = useState(false);
   const [selected, setSelected] = useState(null);
   const [showNoMember, setShowNoMenber] = useState(false);
   const [loadingSocket, setLoadingSocket] = useState(true);
   // const [showSearch, setShowSearch] = useState(false);
   const hide = () => {
      setShowDirection(false);
   };
   // const showHideSearch = () => {
   //    setShowSearch(!showSearch);
   // };
   const inviteClick = () => {
      setSelected(null);
      setInvite(!invite);
      setEmailInvite("");
      setNameInvite("");
      setRelation("");
      // setSearchKey("")
   };
   const invitePerson = () => {
      let obj = null;
      if (!isValidUsNumber(emailInvite)) {
         obj = {
            email: emailInvite,
            name: nameInvite,
            relationship: relation?.label || "",
         };
      } else {
         obj = {
            mobileNo:
               (emailInvite?.includes("+1") || emailInvite?.startsWith("1") ? "+" : "+1") + unformatNumber(emailInvite),
            name: nameInvite,
            relationship: relation?.label,
         };
      }
      setLoadingInvite(true);
      addToFriendsFamily(obj);
   };
   const edit = (family) => {
      const email = family?.careMember?.contactInformation?.email || "";
      const phone = family?.careMember?.contactInformation?.mobileNumber || "";
      const name = family?.careMember?.name?.fullName || "";
      const rel = family?.relationship || "";
      const relation = rel !== null && rel !== "" ? { label: rel, text: rel } : "";
      setSelected(family);
      setInvite(true);
      setEmailInvite(email || phone);
      setNameInvite(name);
      setRelation(relation);
   };
   const filteredMembers = filterFamilyMembers(table, familyFriendsList);
   const sharedWithmeData =
      familyFriendsList?.filter((filter) => {
         return !filter?.isCareTeam;
      }) || [];
   const filteredMemberslength = filteredMembers?.length || 0;
   const sharingWithmelength = sharedWithmeData.length || 0;
   const relationship = familyFriendsList?.relationship || "";
   const getRedisID = async (data) => {
      setLoadingSocket(data?.id);
      const token = await getToken();
      var obj = {
         action: socketActions?.auth,
         subAction: socketSubActions?.customRedis,
         token: token,
         userId: data?.id,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            setShowPopup({ name: data?.name, userId: resultStatus?.data?.redisId });
         }
         setLoadingSocket(false);
      });
   };
   const debounceSearch = useDebounce(searchKey, 500);
   const getSearchResults = (inputText) => {
      var text = inputText?.toLowerCase();
      const filterUser =
         sharingList?.filter((filter) => {
            return (
               filter?.careMember?.name?.fullName?.toLowerCase()?.includes(text) ||
               filter?.careMember?.contactInformation?.email?.toLowerCase()?.includes(text) ||
               filter?.careMember?.contactInformation?.mobileNumber?.includes(text) ||
               filter?.careMember?.contactInformation?.officeNumber?.includes(text) ||
               filter?.relationship?.toLowerCase()?.includes(text)
            );
         }) || [];
      setFamilyFriendsList(filterUser);
      if (text?.length > 0 && filterUser?.length === 0) {
         setShowNoMenber(true);
      }
   };
   useEffect(() => {
      getSearchResults(debounceSearch);
   }, [debounceSearch]);

   const renderLoader = () => {
      return (
         <div
            onClick={(e) => {
               e.stopPropagation();
            }}
         >
            <LoadingIndicator className=" w-8 mr-[8px] md:mr-[30px]" />
         </div>
      );
   };
   return (
      <>
         {/* <button id="search-button-xs" className=" sm:hidden w-[0] h-[0] fixed" onClick={showHideSearch} /> */}
         <div className="mt-[30px]">
            <input
               type="text"
               placeholder="Search"
               className="sharing-search-input w-full sm:w-[343px] outline-0"
               value={searchKey}
               onChange={(e) => setSearchKey(e?.target?.value)}
            />
            {filteredMemberslength === 0 && sharingWithmelength === 0 && !loading && (
               <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
                  <EmptyStateComp
                     src={ShareImg}
                     headerText={!showNoMember ? emptyStatesText?.emptyShare : emptyStatesText?.emptyResults}
                     btnText={!showNoMember ? emptyStatesText?.emptyShareBtnTxt : false}
                     description={!showNoMember ? emptyStatesText?.emptyShareText : false}
                     onClick={inviteClick}
                  />
               </div>
            )}
            {loading && <TableLoading sharingloading={true} />}
            {filteredMemberslength > 0 && (
               <>
                  {/* for large screan I am sharing with*/}
                  <div className="hidden md:block">
                     <div className="flex items-center justify-between pt-[30px] pb-[15px]">
                        <div className={`text-[20px] text-[#1D1D1F] font-semibold tracking-[0.442px]`}>
                           {sharingText?.imSharingWith}
                        </div>
                        <div className={``}>
                           <Button onClick={inviteClick} className="w-[167px] font-[17px] font-medium leading-[22.4px]">
                              {sharingText?.addMember}
                           </Button>
                        </div>
                     </div>
                  </div>
                  {/* for small screan I am sharing with */}
                  <div className="flex justify-between md:hidden px-[15px] pt-[30px]">
                     <div className="flex gap-2 justify-center items-center">
                        <img src={group} alt="group" className="w-[17px] h-[18px]" />
                        <div className="text-[#1D1D1F] text-[17px] font-semibold leading-[28px] tracking-[-0.442px]">
                           {sharingText?.imSharingWith}
                        </div>
                     </div>
                     <div className={`md:hidden`}>
                        <div
                           className=" text-[#043293] text-[15px] font-semibold leading-[28px] tracking-[-0.442px] border-b-2 border-[#043293] cursor-pointer"
                           onClick={inviteClick}
                        >
                           {sharingText?.addMember}
                        </div>
                     </div>
                  </div>

                  {/* for large screan I am sharing with */}
                  <div className="w-full hidden md:block">
                     <TableSharing
                        navigate={navigate}
                        table={table}
                        members={filteredMembers}
                        isLoading={loading}
                        remove={removeFamilyMember}
                        edit={edit}
                        inviteClick={inviteClick}
                        setSelectedPatient={setSelectedPatient}
                        openModal={(data) => {
                           setShowDirection(true);
                           setEachCare(data);
                           setSelected(null);
                        }}
                        sharingWith={true}
                        showNoMember={showNoMember}
                     />
                  </div>
               </>
            )}
            {/* for large screan sharing with me */}
            {sharingWithmelength > 0 && (
               <>
                  <div className="text-[20px] text-[#1D1D1F] font-semibold leading-[28px] tracking-[0.442px] hidden md:flex mt-[30px]">
                     {sharingText?.SharingWithMe}
                  </div>
                  <div className="w-full  hidden md:block pt-[16px]">
                     <TableSharing
                        renderLoader={renderLoader}
                        loadingSocket={loadingSocket}
                        navigate={navigate}
                        table={table}
                        members={sharedWithmeData}
                        isLoading={loading}
                        remove={removeFamilyMember}
                        edit={edit}
                        inviteClick={inviteClick}
                        setSelectedPatient={setSelectedPatient}
                        openModal={(data) => {
                           setShowDirection(true);
                           setEachCare(data);
                           setSelected(null);
                        }}
                        getRedisID={getRedisID}
                        showNoMember={showNoMember}
                     />
                  </div>
               </>
            )}
            {/* for small screan */}
            <div>
               {familyFriendsList?.length > 0 && (
                  <div className="w-full md:hidden mt-[15px] mb-[30px]">
                     <SharingSmallScreenTable
                        renderLoader={renderLoader}
                        loadingSocket={loadingSocket}
                        navigate={navigate}
                        table={table}
                        members={familyFriendsList}
                        isLoading={loading}
                        openModal={(data, forShare = true) => {
                           setShowDirection(true);
                           setEachCare(data);
                           setShowRemove(forShare);
                        }}
                        sharingWith={true}
                        getRedisID={getRedisID}
                        sharingWithmelength={sharingWithmelength}
                     />
                  </div>
               )}
            </div>
            {showDirection && (
               <Modal onModalTapped={hide}>
                  <UserDetailPopup
                     edit={edit}
                     share={showRemove}
                     eachCare={eachCare}
                     relationship={relationship}
                     onModalTapped={hide}
                     removeMember={removeFamilyMember}
                     setSelectedPatient={setSelectedPatient}
                     setShowDirection={setShowDirection}
                  />
               </Modal>
            )}
            {invite && (
               <Modal onModalTapped={inviteClick}>
                  <InviteFamily
                     selected={selected}
                     loading={loadingInvite}
                     setLoading={setLoadingInvite}
                     onModalTapped={inviteClick}
                     invitePerson={invitePerson}
                     name={nameInvite}
                     relation={relation}
                     setRelation={setRelation}
                     email={emailInvite}
                     setName={(e) => {
                        setNameInvite(capitalizeFirstLetter(e?.target?.value));
                     }}
                     setEmail={(e) => {
                        setEmailInvite(e);
                     }}
                  />
               </Modal>
            )}
            {/* {showSearch && (
               <Modal onModalTapped={showHideSearch} alter>
                  <Search
                     search={searchKey}
                     onModalTapped={showHideSearch}
                     setSearch={(key) => {
                        setSearchKey(key);
                        getSearchResults(key);
                     }}
                  />
               </Modal>
            )} */}
         </div>
      </>
   );
}
const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      showPopup: state?.common?.showPopup,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         setShowPopup: commenActions?.showDobPopup,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sharing);
