import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";
import * as contentActions from "../actions/DashboardAct";
import DashboardComp from "../component/DashboardComp";
import { useParams } from "react-router-dom";

const DashboardCont = (props) => {
   const {
      getPatientContentActivity,
      setSearchTerm,
      contentActivitylist,
      id = false,
      setInitialState,
      showAllSpotlight = false,
      showfolder = false,
      showDirectory = false,
      showDashboard = false,
   } = props;

   const userId = id ? id : null;
   const contentList = contentActivitylist?.list;
   const breadCrumbData = contentActivitylist?.breadCrumbData;
   const { folder_id } = useParams();
   const { searchTerm } = contentActivitylist;
   const viewPersisit = id
      ? localStorage.getItem("viewPatient") === "true" || false
      : localStorage.getItem("view") === "true" || false;
   const [filterby, setFilterBy] = useState("my");
   const [view, setView] = useState(viewPersisit);
   const forSharing = id;

   useEffect(() => {
      if (showAllSpotlight || showDashboard) {
         getPatientContentActivity(() => {}, true, "", userId, filterby, showAllSpotlight, folder_id);
      }
   }, [showAllSpotlight, showDashboard]);

   useEffect(() => {
      getPatientContentActivity(() => {}, true, "", userId, filterby, showAllSpotlight, folder_id);
   }, []);

   useEffect(() => {
      if (folder_id) {
         getPatientContentActivity(() => {}, true, "", userId, filterby, showAllSpotlight, folder_id);
      }
   }, [folder_id]);

   const deb = useCallback(
      debounce((text) => {
         getPatientContentActivity(() => {}, true, text, userId, filterby, showAllSpotlight, folder_id);
      }, 500),
      [folder_id]
   );
   const handleSearchText = (text) => {
      deb(text);
   };

   return (
      <DashboardComp
         handleSearchText={handleSearchText}
         filterby={filterby}
         setFilterBy={setFilterBy}
         contentList={contentList}
         searchTerm={searchTerm}
         userId={userId}
         setSearchTerm={setSearchTerm}
         view={view}
         setView={setView}
         forSharing={forSharing}
         showAllSpotlight={showAllSpotlight}
         ShowDirectory={showDirectory}
         {...props}
         folder_id={folder_id}
         showfolder={showfolder}
         breadCrumbData={breadCrumbData}
      />
   );
};
const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      contentActivitylist: state?.contentActivitylist,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getPatientContentActivity: contentActions.getPatientContentActivity,
         setSearchTerm: contentActions.setContentSearchTerm,
         setPatientContent: contentActions.setPatientContent,
         reactHeart: contentActions.reactHeart,
         setInitialState: contentActions.setInitialState,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCont);
