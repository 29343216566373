import React, { useEffect } from "react";
import { loginConst } from "shared/constants/loginConst";
import AccountSection from "./accountSelection";

const MultipleAccountComp = ({ setShowMultipleProvider, setSelectedUser, dobNotAvailable, datas }) => {
   const myAccountsLabel = loginConst?.patientOnboard?.myAccounts;
   const sharingWithMeLabel = loginConst?.patientOnboard?.sharingWithMe;
   const PatientAccountsData = datas?.patientAccounts || [];
   const SharingWithMeData = datas?.careTeam || [];
   const showPatientAccounts = datas?.patientAccounts?.length > 0;
   const showSharingWithMe = datas?.careTeam?.length > 0;

   useEffect(() => {
      if (window.innerWidth <= 760) {
         const ele = document.getElementById("div-scroll-top");
         if (ele) {
            ele.classList.remove("scroll-custom");
         }
         return () => {
            ele.classList.add("scroll-custom");
         };
      }
   }, []);

   const clickHandler = (data) => {
      setShowMultipleProvider(false);
      setSelectedUser(data);
      if (!data?.isDOBExist) {
         dobNotAvailable(data);
      }
   };

   return (
         <div className="sm:w-[60%] mx-auto">
            {showPatientAccounts && (
               <AccountSection datas={PatientAccountsData} label={myAccountsLabel} clickHandler={clickHandler} customWidth={true} />
            )}
            {showSharingWithMe && (
               <AccountSection datas={SharingWithMeData} label={sharingWithMeLabel} clickHandler={clickHandler} customWidth={true} />
            )}
      </div>
   );
};

export default MultipleAccountComp;