import React from "react";
import { ReactComponent as LoveSvg } from "images/newdashbord/loveicon.svg";
import { ReactComponent as FilledLoveSvg } from "images/dashboard/redHeart.svg";
import PostView from "./PostView";
import { calculateDatetime, errorToDisplay, getMediaIconNew } from "shared/actions/helpers";
import { getAccessToken } from "actions/index";
import { connect } from "react-redux";
import hospital from "images/iconsNotif/hospital.svg";
import cross from "images/common/cross.svg";
import { ShowAlert } from "shared/actions/alert";
import { reactHeart, UPDATE_MEDIA_STATUS } from "modules/home/actions/contentActivityActions";
import { fetchMutation } from "shared/actions/fetchMutationAction";
import copy from "images/newdashbord/copy.svg";
function Post(props) {
   const {
      data,
      isLoading,
      auth,
      openModal = () => {},
      isPreview = false,
      onModalTapped = () => {},
      yourSpotlight = false,
      setEachPost = () => {},
      getSpotlight = () => {},
   } = props;
   const getDate = calculateDatetime(data?.createdAt);
   const token = getAccessToken();
   const isLoved =
      data?.loves?.some((find) => {
         return find === auth.userCredentials.user.id;
      }) || false;

   const postReact = (e) => {
      e.stopPropagation();
      let key = "mediaId";
      const id = data?.id;
      if (data?.type === "text" || data?.type === "item") key = "itemId";
      let params = { isActivity: true, media: { type: "loves", [key]: id } };
      fetchMutation(
         UPDATE_MEDIA_STATUS,
         params,
         (res) => {
            if (yourSpotlight) {
               getSpotlight();
               const loveData = JSON.parse(JSON.stringify(data));

               if (
                  loveData?.loves?.some((find) => {
                     return find === auth?.userCredentials?.user?.id;
                  }) ||
                  false
               ) {
                  let index = loveData?.loves?.findIndex((find) => {
                     return find === auth?.userCredentials?.user?.id;
                  });
                  loveData?.loves?.splice(index, 1);
               } else {
                  let tempArr = [];
                  tempArr?.push(auth?.userCredentials?.user?.id);
                  loveData.loves = tempArr;
               }

               setEachPost(loveData);
            } else {
               reactHeart(data, auth?.userCredentials?.user?.id);
            }
            // reactHeart(data, auth.userCredentials.user.id);
         },
         (err) => {
            let errMsg = errorToDisplay(err);
            ShowAlert(errMsg, "error");
         }
      );
   };
   const showReact = data?.type !== "referral";

   const copyContent = (text) => {
      navigator.clipboard
         .writeText(text)
         .then(() => {
            ShowAlert("Copied to clipboard.");
         })
         .catch((error) => {
            ShowAlert("Error copying messages", {
               toastId: "error",
            });
         });
   };
   const isItem = data?.type === "item" || data?.type === "text";

   return (
      <div
         key={data.id}
         onClick={() => {
            openModal(data);
         }}
         className={`${
            isPreview ? "lg:w-[600px] w-[100vw] z-[50]  flex-center" : "w-[98%] sm:w-2/4 md:w-[33%]"
         } p-3 pr-0 pl-0 sm:pr-2 sm:pl-2  cursor-pointer`}
      >
         <div
            className={`post-card-post flex flex-col relative ${
               isPreview ? "rounded-[0px] lg:rounded-[15px] " : " rounded-[15px] "
            }`}
         >
            {isPreview && isItem && (
               <div
                  className="absolute bg-white card-shadow px-[20px] py-[6px] z-[70] rounded-[5px] right-[32%] sm:right-[42vw] lg:right-[400px] top-3 cursor-pointer flex gap-[5px] justify-center"
                  onClick={() => copyContent(data.title)}
               >
                  <img src={copy} alt="copy" className=" w-[18px] h-[20px]" />
                  <div className="text-[#043293] text-[13px] font-semibold">Copy text</div>
               </div>
            )}
            {isPreview && (
               <img
                  onClick={onModalTapped}
                  src={cross}
                  alt="cross-btn"
                  className="absolute w-[25px] h-[25px]  bg-white z-[70] rounded-lg p-[5px] right-5 top-5 cursor-pointer"
               />
            )}
            <div
               className={`absolute left-3 top-3 rounded-[5px] time-bg pl-1 pr-1 text-[13px] text-white font-medium ${
                  isPreview ? "z-20" : "z-[30]"
               }`}
            >
               {getDate}
            </div>
            <div
               className={`${isLoading && "loading-shade"} ${
                  isPreview ? " h-[100vh] lg:h-[75vh] flex items-center justify-center" : " h-[220px]"
               }  
                  ${isPreview ? "lg:w-[900px] w-[100vw]" : "sm:w-full"} w-full`}
            >
               <PostView data={data} accessToken={token} isPreview={isPreview} />
            </div>
            <div className={` ${isPreview && "hidden lg:block"}  z-10 post-bottom border-t-[1px] pl-3`}>
               <div title={data?.title} className={`text-[20px] font-medium pl-1 truncate w-[54rem] pt-2`}>
                  {data.title}
               </div>
               <div className="flex items-center justify-between">
                  <div className="flex items-center pb-2 ">
                     <img
                        className="h-[25px] w-[25px] "
                        alt="hospitalIcon"
                        onError={(e) => {
                           e.target.src = hospital;
                        }}
                        src={getMediaIconNew(data?.type, true, data.hospital?.id)}
                     />
                     <div title={data.addedByName} className="font-17 font-medium truncate... ml-3 py-1">
                        {data.provider?.name?.fullName}
                     </div>
                  </div>
                  {showReact && (
                     <div className="mr-6 mt-[-8px]">
                        {isLoved ? <FilledLoveSvg onClick={postReact} /> : <LoveSvg onClick={postReact} />}
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(Post);
