import clock from "images/newdashbord/clock.svg";
import phoneblue from "images/newdashbord/phone.fill.svg";
import locationblue from "images/newdashbord/mdi_location.svg";
import phonegray from "images/newdashbord/phone-gray.svg";
import locationgray from "images/newdashbord/mdi_location-gray.svg";
import Avatar from "shared/components/avatar";
import {openMapDirectionGql } from "shared/actions/helpers";
import { AppointmentText } from "shared/constants/const";

const SingleAppointment = ({
   seeAll,
   src = "",
   color,
   initialsApi,
   name,
   hospitalName,
   reason,
   mobileNumber,
   appointmentTime,
   info,
   isAddress,
}) => {
   return (
      <>
         <div
            className={`${
               seeAll ? "lg:min-h-[120px] lg:max-h-[200px] min:h-[212px] max:h-[400px] card-shadow" : "h-[92px]"
            } lg:flex lg:justify-between flex-row justify-center bg-[#fff] rounded-[10px] lg:px-[30px] px-[16px] py-[16px]`}
         >
            <div className="flex gap-[8px] justify-center items-center">
               <img src={clock} alt="clock" className="w-[18px] h-[18px]" />
               <div className="text-[#1D1D1F] font-light text-[17px]">{appointmentTime}</div>
            </div>
            <div className={`divider ${seeAll ? "lg:h-[60px] lg:w-[1px] h-[1px] w-[100%] lg:p-0  my-4" : "w-[1px] h-[60px] "} bg-[#E0E0E0]`}></div>
            <div className={`${seeAll ? "lg:my-0 mb-[16px] flex-row justify-center items-center" : " justify-center items-center"} flex gap-[16px]`}>
               <Avatar
                  src={src}
                  isProvider={true}
                  className="sm:flex-shrink-0 pointer mr-4 h-[48px] w-[48px]"
                  bgColor={color}
                  radius={48}
                  initialsApi={initialsApi}
                  name={name}
               />
               <div className="w-[220px] flex-row justify-center items-center">
                  <div title={name} className={`text-[#000] text-[17px] font-medium w-[220px] truncate`}>{name}</div>
                  <div title={hospitalName} className={`text-[#8A8A8E] text-[13px] font-medium ${!seeAll && "w-[220px] truncate"}`}>
                     {hospitalName}
                  </div>
                  <div title={reason} className="text-[#8A8A8E] text-[13px] font-medium w-[220px] truncate">{reason}</div>
               </div>
            </div>
            <div
               className={`${
                  seeAll ? "lg:flex-col lg:gap-0 flex gap-5" : "flex gap-[16px]"
               } justify-center items-center`}
            >
               {mobileNumber?.length === 0 ? (
                  <div
                     className={` ${
                        seeAll && "lg:mb-[16px]"
                     } flex gap-[5px] border-[1px] border-[#B5B5B5] w-[148px] h-[36px] px-[22px] justify-center items-center rounded-[8px]`}
                  >
                     <img src={phonegray} alt="phone" className="w-[28px] h-[28px]" />
                     <div className="text-[#B5B5B5] text-[15px] font-medium">{AppointmentText?.call}</div>
                  </div>
               ) : (
                  <div
                     className={` ${
                        seeAll && "lg:mb-[16px]"
                     } cursor-pointer flex gap-[5px] border-[1px] border-[#043293] w-[148px] h-[36px] px-[22px] justify-center items-center rounded-[8px]`}
                  >
                     <a href={`tel:${mobileNumber}`} className="flex gap-[5px]">
                        <img src={phoneblue} alt="phone" className="w-[24px] h-[24px]" />
                        <div className="text-[#043293] text-[15px] font-medium">{AppointmentText?.call}</div>
                     </a>
                  </div>
               )}
               {!isAddress ? (
                  <div
                     className={`flex gap-[5px] border-[1px] border-[#B5B5B5] w-[148px] h-[36px] px-[22px] justify-center items-center rounded-[8px]`}
                  >
                     <img src={locationgray} alt="location" className="w-[24px] h-[24px]" />
                     <div className="text-[#B5B5B5] text-[15px] font-medium">{AppointmentText?.location}</div>
                  </div>
               ) : (
                  <div
                     onClick={() => {
                        openMapDirectionGql(info);
                     }}
                     className={`flex gap-[5px] border-[1px] cursor-pointer border-[#043293] w-[148px] h-[36px] px-[22px] justify-center items-center rounded-[8px]`}
                  >
                     <img src={locationblue} alt="location" className="w-[24px] h-[24px]" />
                     <div className="text-[#043293] text-[15px] font-medium">{AppointmentText?.location}</div>
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default SingleAppointment;
