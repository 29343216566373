import { calculateDatetime } from "shared/actions/helpers";
import star from "images/newdashbord/star.svg";
import { connect } from "react-redux";
import folderLayers from "images/newdashbord/squarelayers.svg";
import { useNavigate } from "react-router";
import { GET_THUMBNAIL_SIGNED_URL } from "modules/dashboard/constants/DashboardConst";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const FolderContainer = (props) => {
   const { data, id, yourSpotlight = false, showAllSpotlight = false, setFolderName = "", setSearchKey = "" } = props;
   const getDate = calculateDatetime(data?.createdAt);
   const [folderCount, setFolderCount] = useState(0);
   const navigate = useNavigate();
   const handleDirectory = () => {
      // const str = showAllSpotlight ? "?isSpotlight=true" : "";
      setFolderName(data?.title);
      // navigate("/folder/" + data.id + str);
      navigate("/folder/" + data?.id);
      setSearchKey("");
   };

   useQuery(GET_THUMBNAIL_SIGNED_URL, {
      fetchPolicy: "network-only",
      variables: {
         content: {
            id: data.id,
            description: "",
         },
         thumbnail: true,
      },
      onCompleted(result) {
         setFolderCount(result.getSignedURL?.dataCount);
      },
   });

   return (
      <div
         id={id}
         className={`${
            yourSpotlight
               ? "min-w-[103%] sm:min-w-[100%] sm:max-w-[103%] md:max-w-[50%] md:min-w-[48%] lg:max-w-[50%] lg:min-w-[48.5%] xl:min-w-[31.5%] flex gap-[30px] pl-[3px] pr-[10.9px] sm:pr-[2px] md:pl-[0.5px] md:pr-[3px] lg:pr-1 xl:pr-[0.8px]"
               : "flex-1 xl:min-w-[30%] xl:max-w-[31.5%] lg:w-[80%] lg:max-w-[48.5%] md:min-w-[47.8%] md:max-w-[20%] w-[50%]"
         }
          bg-[#F8FAFB] cursor-pointer`}
         key={data?.id}
         onClick={handleDirectory}
      >
         <div className="bg-[#FFF] h-[260px] p-[16px] rounded-[16px] card-shadow w-[100%] ">
            {/* folder UI */}

            <div className="rounded-[7px] h-[154px] bg-[#BAD0FA] flex gap-4 justify-center items-center card-shadow mb-[14px] relative">
               {showAllSpotlight && (
                  <div className="absolute top-0 left-[16px] w-[30px] h-[30px] bg-[#fff] rounded-b-[8px] z-[20]">
                     <img src={star} alt="star" className="w-[22px] h-[22px] p-[2px] m-auto mt-[0.1rem]" />
                  </div>
               )}
               <img src={folderLayers} alt="folder" className="h-[78.154px] w-[88.364px] text-[#fff] " />
               {folderCount > 0 && (
                  <div title={data?.datacount} className="text-center font-semibold text-[22px] text-[#fff]">
                     {folderCount} {folderCount === 1 ? "item" : "items"}
                  </div>
               )}
            </div>
            <div>
               <div
                  title={data?.title}
                  className="w-[250px] truncate-line-clamp mb-[4px] text-[#000] text-font-weight leading-[19px] h-[40px]"
               >
                  {data?.title}
               </div>
               <div title={data?.addedByName} className="flex justify-between text-[13px] text-[#8A8A8E]">
                  <div className="w-[48%] truncate">{data?.provider?.name?.fullName}</div>
                  <div className="w-auto truncate flex-row">{getDate}</div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
   };
};

export default connect(mapStateToProps, null)(FolderContainer);
