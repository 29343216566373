import React, { useEffect } from "react";
import { loginConst } from "shared/constants/loginConst";
import SwitchAccountLoader from "./switchAccountLoader";
import AccountSection from "modules/login/component/accountSelection";

const SwitchAccountOptions = ({ accountObject, clickHandler, setOpenDropdown, isLoading,getAccounts }) => {
   const myAccountsLabel = loginConst?.patientOnboard?.myAccounts;
   const sharingWithMeLabel = loginConst?.patientOnboard?.sharingWithMe;
   const patientAccountsLength = accountObject?.patientAccounts?.length > 0;
   const careTeamLength = accountObject?.careTeam?.length > 0;
   useEffect(() => {
      getAccounts()
      window.addEventListener("click", onClickOutSide);
      return () => {
         window.removeEventListener("click", onClickOutSide);
      };
   }, []);
   const onClickOutSide = (e) => {
      const condition =
         document.getElementById("switchAccountDroupdown") &&
         document.getElementById("switchAccountDroupdown").contains(e?.target);
      if (!condition) {
         setOpenDropdown(false);
      }
   };

   return (
      <div
         id="switchAccountDroupdown"
         class="md:max-h-[72vh] max-h-[48vh] absolute right-0 md:left-[70px] md:top-[50px] z-[5000000] left-0 w-[344px] overflow-auto text-[#000] rounded-[10px] opacity-100 drop-shadow-2xl shadow-lg shadow-white-400 bg-white no-scrollbar"
      >
         <div className=" mx-1 my-1 px-2">

         {isLoading ? (
            <SwitchAccountLoader />
         ) : (
            <>
               {patientAccountsLength && (
                  <>
                  <AccountSection
                     datas={accountObject?.patientAccounts || []}
                     clickHandler={clickHandler}
                     label={myAccountsLabel}
                  />
                  <div className="mb-4"></div>
                  </>
               )}
               {(patientAccountsLength && careTeamLength) && <div className="h-[0.5px] divider-color mx-auto"/>}
               {careTeamLength && (
                  <>
                     <AccountSection
                        datas={accountObject?.careTeam || []}
                        clickHandler={clickHandler}
                        label={sharingWithMeLabel}
                     />
                     <div className="mt-4"/>
                  </>
               )}
            </>
         )}
         </div>
      </div>
   );
};

export default SwitchAccountOptions;
