import React from "react";
import Navbar from "modules/navbar/component/navBarComponent";
// import NavbarLogout from "../component/navBarComponentLogout";

import { connect } from "react-redux";

const NavbarCont = (props) => {
   return <Navbar {...props} />;
   // const path = window.location.pathname;
   // const showNavBar = path.includes("/viewer");
   // if (!showNavBar) return <Navbar {...props} />;
   // else return <NavbarLogout />;
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
      darkly: state.darkly,
   };
};

export default connect(mapStateToProps, null)(NavbarCont);
