import React, { useState } from "react";
import { getReferralDetails } from "actions/index";
import TableView from "shared/components/post/postTable";
import XSTable from "shared/components/post/xsTable";
import InfiniteScroll from "shared/components/infinteScroll/infinteScroll";
import Modal from "shared/components/modal/modal";
import { emptyStatesText } from "shared/constants/const";
import ReferralDetailsView from "shared/components/post/referalView";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import HomeImg from "images/emptyStates/home.svg";
import NewPost from "shared/components/newPost/NewPost";
import SpotlightContainerCont from "../container/SpotlightContainerCont";
import allActivity from "images/newdashbord/allActivity.svg";
import star from "images/newdashbord/star.svg";
import arrowhome from "images/newdashbord/Arrow-home.svg";
import AppointmentSectionCont from "../container/AppointmentSectionCont";
import { useNavigate } from "react-router-dom";
import AllActivitySkeleton from "../allskeleton/AllActivitySkeleton";
import FolderContainer from "shared/components/folderContainer/FolderContainer";
import { Link } from "react-router-dom";
import Post from "shared/components/post/Post";

function DashboardComp({
   getPatientContentActivity,
   setSearchTerm,
   contentActivitylist,
   handleSearchText,
   filterby,
   setFilterBy,
   contentList,
   searchTerm,
   userId,
   reactHeart,
   view,
   forSharing,
   showAllSpotlight = false,
   showfolder = false,
   showDirectory = false,
   folder_id,
   breadCrumbData,
}) {
   const [searchKey, setSearchKey] = useState("");
   const [eachPost, setEachPost] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [showReferal, setShowReferalModal] = useState(false);
   const [showSpotlight, setShowSpotlight] = useState(true);
   const [folderName, setFolderName] = useState("");
   const [showAppointment, setShowAppointment] = useState(true);
   // const spotlightFolder = window.location.search;
   const navigate = useNavigate();
   const showHide = () => {
      setShowModal(false);
   };
   const toggleReferal = () => {
      setShowReferalModal(!showReferal);
   };
   const openModal = async (data) => {
      if (data?.type === "referral") {
         let res = await getReferralDetails({ referralId: data?.id });
         setEachPost(res?.data?.data);
         toggleReferal();
      } else {
         setShowModal(true);
         setEachPost(data);
      }
   };
   const handleNavigation = () => {
      navigate("/");
      setSearchKey("");
   };
   return (
      <div>
         <div className="mt-[30px] mb-[30px]">
            {!showAllSpotlight && showAppointment && !showfolder && (
               <AppointmentSectionCont setShowAppointment={setShowAppointment} />
            )}

            <div className={`flex ${forSharing ? "w-full justify-between" : "justify-start"} items-center`}>
               <input
                  type="text"
                  placeholder="Search"
                  className="w-full sm:w-[343px] sharing-search-input outline-0"
                  value={searchKey}
                  onChange={(e) => {
                     handleSearchText(e?.target?.value);
                     setSearchKey(e?.target?.value);
                     setSearchTerm(e?.target?.value);
                     setFilterBy("my");
                  }}
               />
            </div>
         </div>
         {!showAllSpotlight && showSpotlight && !showfolder && !searchKey && (
            <SpotlightContainerCont
               setShowSpotlight={setShowSpotlight}
               reactHeart={reactHeart}
               setFolderName={setFolderName}
               folder_id={folder_id}
               setSearchKey={setSearchKey}
            />
         )}
         <div>
            {!showAllSpotlight && !showfolder ? (
               <div className="flex gap-[8px] mb-[15px] mt-[30px]">
                  <img src={allActivity} alt="allActivity" className="w-[23px] h-[28px]" />
                  <div className="text-[#000] text-container cursor-pointer" onClick={handleNavigation}>
                     All Activity
                  </div>
               </div>
            ) : (
               <div className="flex gap-[5px] items-center mb-[15px] mt-[18px] text-container">
                  {showAllSpotlight && (
                     <>
                        <div className="text-[#6D6D72] cursor-pointer" onClick={handleNavigation}>
                           Home
                        </div>
                        <img src={arrowhome} alt="arrowhome" className="w-[24px] h-[24px]" />
                        <img src={star} alt="allActivity" className="w-[23px] h-[28px]" />
                        <div className="text-[#000] cursor-pointer">Your Spotlight</div>
                     </>
                  )}
                  {showfolder && (
                     <>
                        <div className="text-[#6D6D72] cursor-pointer" onClick={handleNavigation}>
                           Home
                        </div>
                        {breadCrumbData?.map((data, index) => {
                           const name = data?.name || "";
                           const folderId = data?.directoryId || null;
                           const isLast = index === breadCrumbData?.length - 1 || false;
                           return (
                              <>
                                 <img src={arrowhome} alt="arrowhome" className="w-[24px] h-[24px]" />
                                 <div
                                    title={name}
                                    className={`breadcrumb-item cursor-pointer folder-text text-[17px] sm:text-[20px] max-w-[210px] truncate ${
                                       isLast ? "active text-[#000]" : "text-[#6D6D72]"
                                    }`}
                                    key={name}
                                    onClick={() => {
                                       setSearchKey("");
                                    }}
                                 >
                                    {isLast ? (
                                       name
                                    ) : (
                                       <Link title={name} to={"/folder/" + folderId}>
                                          {name}
                                       </Link>
                                    )}
                                 </div>
                              </>
                           );
                        })}
                     </>
                  )}
               </div>
            )}
            {contentActivitylist?.loading ? (
               <AllActivitySkeleton view={view} />
            ) : (
               <InfiniteScroll
                  callBack={(call) => {
                     return getPatientContentActivity(
                        call,
                        false,
                        searchTerm,
                        userId,
                        filterby,
                        showAllSpotlight,
                        folder_id
                     );
                  }}
                  showLoader={contentActivitylist?.hasMore}
               >
                  {view ? (
                     <div className="mt-3">
                        <div className="hidden sm:inline">
                           <TableView
                              posts={contentList}
                              openModal={openModal}
                              isLoading={contentActivitylist?.loading}
                           />
                        </div>
                        <div className="sm:hidden ml-[-5px]">
                           <XSTable
                              posts={contentList}
                              openModal={openModal}
                              isLoading={contentActivitylist?.loading}
                           />
                        </div>
                     </div>
                  ) : (
                     <div className="flex flex-wrap gap-[30px] mx-auto max-w-[1143px]">
                        {contentList.length === 0 ? (
                           <div className="flex items-center justify-center w-full h-[70vh] text-bold text-large text-grey5 no-content-available">
                              <EmptyStateComp src={HomeImg} headerText={emptyStatesText?.emptyHome} />
                           </div>
                        ) : (
                           contentList?.map((map) => {
                              const isFolder = map?.type === "directory";
                              return (
                                 <>
                                    {isFolder ? (
                                       <FolderContainer
                                          showAllSpotlight={showAllSpotlight}
                                          key={map?.id}
                                          data={map}
                                          isLoading={contentActivitylist?.loading}
                                          openModal={openModal}
                                          reactHeart={reactHeart}
                                          showDirectory={showDirectory}
                                          setFolderName={setFolderName}
                                          setSearchKey={setSearchKey}
                                       />
                                    ) : (
                                       <NewPost
                                          showAllSpotlight={showAllSpotlight}
                                          key={map?.id}
                                          data={map}
                                          isLoading={contentActivitylist?.loading}
                                          openModal={openModal}
                                          reactHeart={reactHeart}
                                          showDirectory={showDirectory}
                                       />
                                    )}
                                 </>
                              );
                           })
                        )}
                     </div>
                  )}
               </InfiniteScroll>
            )}
         </div>
         {showModal && (
            <Modal onModalTapped={showHide}>
               <Post
                  showAllSpotlight={showAllSpotlight}
                  isPreview={true}
                  modal
                  data={eachPost}
                  // setEachPost={setEachPost}
                  onModalTapped={showHide}
                  reactHeart={reactHeart}
               />
            </Modal>
         )}
         {showReferal && (
            <Modal onModalTapped={toggleReferal}>
               <ReferralDetailsView isPreview={true} modal referralDetails={eachPost} onModalTapped={toggleReferal} />
            </Modal>
         )}
      </div>
   );
}
export default DashboardComp;
