import Modal from "shared/components/modal/modal";
import closeImage from "images/newdashbord/mdi_close.svg";
import clock from "images/newdashbord/clock.svg";
import Avatar from "shared/components/avatar";
import phoneblue from "images/newdashbord/phone.fill.svg";
import locationblue from "images/newdashbord/mdi_location.svg";
import phonegray from "images/newdashbord/phone-gray.svg";
import locationgray from "images/newdashbord/mdi_location-gray.svg";
import {openMapDirectionGql } from "shared/actions/helpers";
import { AppointmentText } from "shared/constants/const";

const AppointmentPopup = ({
   onModalTapped,
   setShowPopup,
   src = "",
   color,
   initialsApi,
   name,
   hospitalName,
   reason,
   mobileNumber,
   appointmentTime,
   info,
   isAddress,
}) => {
   return (
      <Modal id="switchAccountPopup" onModalTapped={onModalTapped}>
         <div className="md:w-[411px] w-[350px] min-h-[318px] max-[400px] bg-[#fff] rounded-[10px] opacity-100 drop-shadow-2xl shadow-lg shadow-white-400 py-[30px] flex items-center justify-center flex-col relative">
            <img
               src={closeImage}
               alt={"close"}
               className="absolute lg:right-5 right-3 lg:top-4 top-3 w-[24px] h-[24px] cursor-pointer z-[20]"
               onClick={() => {
                  setShowPopup(null);
               }}
            />
            <div className="flex gap-[8.17px] justify-center items-center">
               <img src={clock} alt="clock" className="w-[18px] h-[18px]" />
               <div className="text-[#1D1D1F] font-light text-[22px]">{appointmentTime}</div>
            </div>
            <div className="divider my-[20px] w-[100%] h-[1px] bg-[#E0E0E0]"></div>
            <div className="flex justify-center items-center mb-[16px]">
               <Avatar
                  src={src}
                  isProvider={true}
                  className="sm:flex-shrink-0 pointer h-[48px] w-[48px]"
                  bgColor={color}
                  radius={48}
                  initialsApi={initialsApi}
                  name={name}
               />
            </div>
            <div className="flex-row justify-center items-center text-center mx-[22px]">
               <div
                  title={name}
                  className="text-[#000] text-[20px] font-medium mb-[4px] w-[300px] md:w-[375px] truncate"
               >
                  {name}
               </div>
               <div title={hospitalName} className="text-[#8A8A8E] text-[15px] font-medium mb-[4px]">
                  {hospitalName}
               </div>
               <div title={reason} className="text-[#8A8A8E] text-[15px] font-medium  w-[300px] md:w-[375px]  truncate">
                  {reason}
               </div>
            </div>
            <div className="flex gap-[16px] justify-center items-center mt-[20px]">
               {mobileNumber.length === 0 ? (
                  <div className="flex gap-[5px] border-[1px] border-[#B5B5B5] sm:w-[155px] w-[140px] h-[36px] px-[22px] justify-center items-center rounded-[8px] ">
                     <img src={phonegray} alt="phone" className="w-[28px] h-[28px]" />
                     <div className="text-[#B5B5B5] text-[15px] font-medium">{AppointmentText?.call}</div>
                  </div>
               ) : (
                  <div className="cursor-pointer flex gap-[5px] border-[1px] border-[#043293] sm:w-[155px] w-[140px] h-[36px] px-[22px] justify-center items-center rounded-[8px] ">
                     <a href={`tel:${mobileNumber}`} className="flex gap-[5px]">
                        <img src={phoneblue} alt="phone" className="w-[24px] h-[24px]" />
                        <div className="text-[#043293] text-[15px] font-medium">{AppointmentText?.call}</div>
                     </a>
                  </div>
               )}
               {!isAddress ? (
                  <div className="flex gap-[5px] border-[1px] border-[#B5B5B5] sm:w-[155px] w-[140px] h-[36px] px-[22px] justify-center items-center rounded-[8px]">
                     <img src={locationgray} alt="location" className="w-[24px] h-[24px]" />
                     <div className="text-[#B5B5B5] text-[15px] font-medium">{AppointmentText?.location}</div>
                  </div>
               ) : (
                  <div
                     onClick={() => {
                        openMapDirectionGql(info);
                     }}
                     className="flex gap-[5px] border-[1px] cursor-pointer border-[#043293] sm:w-[155px] w-[140px] h-[36px] px-[22px] justify-center items-center rounded-[8px]"
                  >
                     <img src={locationblue} alt="location" className="w-[24px] h-[24px]" />
                     <div className="text-[#043293] text-[15px] font-medium">{AppointmentText?.location}</div>
                  </div>
               )}
            </div>
         </div>
      </Modal>
   );
};

export default AppointmentPopup;
