import React, { useState } from "react";
import "modules/dateOfBirth/screen/dischargeInstructionSurvey/dis.css";
import closeIcon from "modules/dateOfBirth/images/close.svg";
import loveIcon from "modules/dateOfBirth/images/love.svg";
import { setCookie } from "modules/dateOfBirth/screen/dischargeInstructionSurvey/cookiesActions/cookieActions";
import { configure } from "shared/constants/const";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp, getApps } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

export default function DischargeInstructionsSurvey({ onClose, mediaId }) {
   const [disabled, setDisabled] = useState(false);
   const [showThanks, setShowThanks] = useState(0);
   const [comment, setComment] = useState("");
   const addTrackLog = async (value) => {
      setDisabled(true);
      if (!getApps().length) {
         initializeApp(configure);
      }
      const db = getFirestore();
      const myCollection = collection(db, "dischargeInstructionSurvey");
      const surveyResult = value === 1 ? "thumbsUp" : "thumbsDown";
      const dataToAdd = {
         name: "patientDischargeSurveyResult",
         value: surveyResult,
         mediaId: mediaId,
         date: new Date(),
         comment: comment,
      };
      const addDocRef = await addDoc(myCollection, dataToAdd);
      if (addDocRef?.id) {
         setCookie("showSurveyPopup", "true", 30);
         setShowThanks(1);
         setTimeout(() => {
            onClose();
         }, 2000);
      } else {
         setTimeout(() => {
            onClose();
         }, 2000);
      }
   };
   return (
      <div id="myModal" className="modal">
         <div className={` ${showThanks === 2 ? "modal-content" : "modal-content-feedback"} `}>
            <div onClick={onClose} className="close-icon">
               <img src={closeIcon} alt="close-icon" />
            </div>
            {showThanks === 0 && (
               <div>
                  <div className="experience">
                     How was your experience receiving discharge instructions via Playback Health?
                  </div>
                  <div className="divHover">
                     <div
                        disabled={disabled}
                        onClick={() => {
                           addTrackLog(1);
                        }}
                        className="icon-two"
                     ></div>
                     <div
                        disabled={disabled}
                        onClick={() => {
                           setShowThanks(2);
                        }}
                        className="icon-three"
                     ></div>
                  </div>
               </div>
            )}
            {showThanks === 1 && (
               <div className="flex-container-div">
                  <div className="experience-input margin-adjust">Thank you, we value your feedback!</div>
                  <img src={loveIcon} alt="thanks" className="thanks" />
               </div>
            )}
            {showThanks === 2 && (
               <div className="flex-container-div">
                  <div className="experience-input">
                     What would improve your experience receiving discharge instructions in Playback Health?
                  </div>
                  <div className="cont-input-boxx-button">
                     <textarea
                        value={comment}
                        onChange={(e) => {
                           setComment(e.target.value);
                        }}
                        type="textarea"
                        width={"100%"}
                     />
                     <button
                        disabled={disabled}
                        onClick={() => {
                           addTrackLog(2);
                        }}
                     >
                        {disabled ? "Loading..." : "Submit"}
                     </button>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}
