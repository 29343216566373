import React from "react";
import globe from "images/transcription/globe.svg";
import arrow from "images/transcription/Arrow.svg";
import globeBlack from "images/transcription/globe (1).svg";
import arrowBlack from "images/transcription/Arrow (1).svg";
import selectedLang from "images/transcription/Chevron.svg";
import Dropdowntoggle from "shared/components/dropDown/dropDownCustom";

const LanguageSelectorDropDown = ({ setSelectedLanguage, languages = [], selectedLanguage }) => {
   return (
      <div className="ProfileDropDown transcription-captions round-border-m">
         <div className="pb-2 padding-custom-lang pt-2 ">Dictation languages</div>
         {languages.map((s) => {
            const isSelected = s.code === selectedLanguage.code;
            return (
               <div
                  onClick={() => {
                     setSelectedLanguage(s);
                  }}
                  className={` pointer each-cell-lang ${!isSelected && "padding-custom-lang hover"}`}
                  key={s.code}
               >
                  {isSelected && <img src={selectedLang} alt="selected-lang" className="select-lang-new" />}
                  {s.lang}
               </div>
            );
         })}
      </div>
   );
};

export default function LanguageSelector({ setSelectedLanguage, transcriptionData, selectedLanguage, isVideo }) {
   const getLanguages = () => {
      let arr = [{ code: "en", lang: "English", text: "English" }];
      if (transcriptionData.transcription.hasOwnProperty("es")) {
         arr.push({ code: "es", lang: "Spanish", text: "Spanish" });
      }
      if (transcriptionData.transcription.hasOwnProperty("hi")) {
         arr.push({ code: "hi", lang: "Hindi", text: "Hindi" });
      }
      if (transcriptionData.transcription.hasOwnProperty("pa")) {
         arr.push({ code: "pa", lang: "Punjabi", text: "Punjabi" });
      }
      if (transcriptionData.transcription.hasOwnProperty("zh-CN")) {
         arr.push({ code: "zh-CN", lang: "Chinese", text: "Chinese" });
      }

      return arr;
   };

   const languages = getLanguages();
   return (
      <div className=" w-full flex items-center justify-center">
         <Dropdowntoggle
            anchor={true}
            classNameCustom="top-[49px]"
            customDropDown={() => {
               return (
                  <LanguageSelectorDropDown
                     selectedLanguage={selectedLanguage}
                     languages={languages}
                     setSelectedLanguage={setSelectedLanguage}
                  />
               );
            }}
            onChange={(sel) => {}}
            options={languages}
            view={() => {
               return (
                  <button
                     id="dropdownDefaultButton"
                     data-dropdown-toggle="dropdown"
                     datatoggle="dropdown"
                     className={` button-dropdown-custom flex items-center justify-between ${isVideo ? "button-change-video" : "button-change-audio"}`}
                  >
                     <img src={isVideo ? globe : globeBlack} alt="globe-alt" />
                     <div className="mx-2">{selectedLanguage.lang}</div>
                     <img src={isVideo ? arrow : arrowBlack} alt="arrow-alt" className="mt-1 pr-1" />
                  </button>
               );
            }}
         />
      </div>
   );
}
