import React, { useState} from "react";
import DatePicker from "react-datepicker";
import CalendarLogo from "assets/dob/ic-calendar.svg";
import InputMask from "react-input-mask";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import MediaView from "modules/dateOfBirth/screen/my_profile/mediaView";
import LinkExpire from "modules/dateOfBirth/screen/LinkExpire";
import { ShowAlert } from "shared/actions/alert";

export default function DobComp() {
   const [date, setDate] = useState(null);
   const [loading, setloading] = useState(false);
   const [contentDatas, setContentDatas] = useState(null);
   const [islinkedExpired, setIsLinkedExpired] = useState(false);
   const [searchParams] = useSearchParams();
   const queryId = searchParams.get("jwt");
   const submitOnKeyPress = (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         validateDob();
      }
   };
   const validateDob = () => {
      setloading(true);

      const postData = {
         queryStringParameters: {
            content: queryId,
            dob: moment(date).format("YYYY-MM-DD"),
         },
      };
      axios
         .post(process.env.REACT_APP_CONTENT_VIEW_URL, postData)
         .then((response) => {
            if(response?.data?.statusCode === 200){
               setContentDatas(response?.data);
               setloading(false);
            }
            else if(response?.data?.statusCode === 401){
               ShowAlert(response.data.message, "error")
               setloading(false);
            }
            else if(response?.data?.statusCode === 402){
               console.log("Link expired",response?.data?.statusCode)
               setIsLinkedExpired(true)
               setloading(false);
            }
         })
         .catch((error) => {
            setIsLinkedExpired(true)
            setloading(false);
         });
        
   };
   return (
      <>
      {islinkedExpired ? <LinkExpire/>: 
      <>
         {!contentDatas ? (
            <div className="w-[80%] m-auto sm:w-full flex  justify-center h-[100vh]">
               <div className="pt-12 sm:w-[370px]">
                  <div className="text-[33px] font-bold text-black ">Date of Birth</div>
                  <p className="text-[21px] text-black pb-4 font-semibold mt-[-5px]">
                     {" "}
                     {"Please verify Patient's date of birth"}
                  </p>

                  <div>
                     <div className="relative" id="datetimepicker1">
                        <div>
                           <DatePicker
                              onKeyDown={(e) => {
                                 if (e.keyIdentifier === "U+000A" || e.keyIdentifier === "Enter" || e.keyCode === 13) {
                                    document.getElementById("submit-dob").click();
                                 }
                              }}
                              id="patient-dob "
                              onSelect={(e) => {
                                 setDate(e);
                              }}
                              onChange={(e) => {
                                 setDate(e);
                              }}
                              value={date}
                              selected={date}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              dropdownMode="select"
                              className=" w-[100%] outline-[#f3f4f6] h-[50px] bg-[#f3f4f6] rounded-md md:text-[20px] text-[17px] px-[10px] react-datepicker-ignore-onclickoutside"
                              autoComplete="off"
                              placeholderText="Type a date (e.g. MM/DD/YYYY)"
                              dateFormat="MM/dd/yyyy"
                              maxDate={new Date()}
                              customInput={
                                 <InputMask mask="99/99/9999">
                                    {(inputProps) => <input {...inputProps} onKeyPress={submitOnKeyPress} />}
                                 </InputMask>
                              }
                           />
                        </div>
                        <span className="input-group-append">
                           <span className="date-picker-icon">
                              <img
                                 className="cursor-pointer mt-2 mr-2"
                                 src={CalendarLogo}
                                 alt="Calendar"
                                 onClick={() => document.getElementById("patient-dob").click()}
                              />
                           </span>
                        </span>
                     </div>
                  </div>

                  <button
                     id="submit-dob"
                     type="button"
                     className="bg-[#043293] text-[white] w-full border-[#0063e8] rounded-3xl p-[12px] text-[20px] mt-[15px] font-bold disabled:opacity-[0.65]"
                     disabled={date === null}
                     onClick={() => validateDob()}
                  >
                     {loading ? "Please wait..." : "Submit"}
                  </button>
               </div>
            </div>
         ) : (
            <MediaView contentDatas={contentDatas} loading={loading}/>
         )}
      </>
      }
      </>
   );
}
