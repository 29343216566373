import React, { useEffect, useMemo, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const options = {
   cMapUrl: "cmaps/",
   standardFontDataUrl: "standard_fonts/",
};

export default function Sample({ pdfFile }) {
   useEffect(() => {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   }, []);

   const [numPages, setNumPages] = useState();
   function onDocumentLoadSuccess({ numPages: nextNumPages }) {
      setNumPages(nextNumPages);
   }

   const pdf = useMemo(() => {
      return pdfFile;
   }, [pdfFile]);

   return (
      <div>
         <div className="Example">
            <div className="Example__container">
               <div className="Example__container__document">
                  <Document
                     file={{
                        url: pdf,
                     }}
                     onLoadSuccess={onDocumentLoadSuccess}
                     options={options}
                  >
                     {Array.from(new Array(numPages), (el, index) => {
                        return <Page key={`page_${index + 1}`} pageNumber={index + 1} />;
                     })}
                  </Document>
               </div>
            </div>
         </div>
      </div>
   );
}
