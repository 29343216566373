import React, { memo, useState } from "react";
import { getParam } from "shared/actions/helpers";
import { optionsShare } from "shared/constants/const";
import options from "images/newdashbord/options.svg";
import { removeFriendsFamily } from "../actions/SharingActions";
import rightArrow from "images/newdashbord/arro_right_go.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commenActions from "redux/actions/commonAction.js";
import ActionDropdown from "./ActionDropdown";
import Avatar from "shared/components/avatar";

const tableHeaderStyle = {
   borderRight: "none",
   borderLeft: "none",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   borderRight: "none",
   borderLeft: "none",
   height: 44,
   display: "flex",
   alignItems: "center",
};
const tableHeaderStyleEx = {
   borderRight: "none",
   borderLeft: "none",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
};

const TableSharing = memo(
   ({
      members = [],
      openModal = () => {},
      remove,
      table,
      setSelectedPatient,
      edit,
      sharingWith,
      getRedisID,
      loadingSocket = false,
      renderLoader = () => {},
   }) => {
      const isShare = table === 1;
      const [memberId, setMemberId] = useState("");
      const menberLength = members?.length || 0;
      return (
         <table
            className="w-full hidden sm:table card-shadow rounded-[16px]  bg-[#fff]"
            cellPadding="0"
            cellSpacing="0"
            border="0"
         >
            <thead className="text-small border-b-[1.5px]">
               <tr>
                  <th width="30%">
                     <div
                        className="table-head"
                        style={{
                           ...tableHeaderStyle,
                           borderTopLeftRadius: "8px",
                           paddingLeft: 15,
                        }}
                     >
                        Name
                     </div>
                  </th>
                  <th width={"15%"}>
                     <div className="table-head" style={{ ...tableHeaderConst }}>
                        Mobile
                     </div>
                  </th>
                  <th width={"28%"}>
                     <div
                        className="table-head"
                        style={
                           isShare
                              ? {
                                   ...tableHeaderStyleEx,
                                   borderRight: "1px solid #ced4da",
                                   borderTopRightRadius: "8px",
                                   paddingLeft: "5px",
                                }
                              : { ...tableHeaderConst }
                        }
                     >
                        Email
                     </div>
                  </th>
                  {sharingWith && (
                     <th width={"20%"}>
                        <div className="table-head" style={{ ...tableHeaderConst }}>
                           Relation
                        </div>
                     </th>
                  )}
                  {!isShare && (
                     <>
                        {sharingWith ? (
                           <th width={"20%"}>
                              <div
                                 className="table-head"
                                 style={{
                                    ...tableHeaderStyleEx,
                                    borderTopRightRadius: "8px",
                                    paddingLeft: "5px",
                                 }}
                              ></div>
                           </th>
                        ) : (
                           <th width={"5.5%"}>
                              <div
                                 className="table-head"
                                 style={{
                                    ...tableHeaderStyleEx,
                                    borderTopRightRadius: "8px",
                                    paddingLeft: "20px",
                                 }}
                              >
                                 {/* Action */}
                              </div>
                           </th>
                        )}
                     </>
                  )}
               </tr>
            </thead>
            <tbody>
               {members.map((o, i) => {
                  const { careMember } = o;
                  const contactInfo = careMember?.contactInformation || "";
                  const email = contactInfo?.email || "";
                  const mobile = contactInfo?.mobileNumber || "";
                  const initialsApi = careMember?.name?.initials || false;
                  const color = careMember?.colorCode || window.initialColors[0];
                  const fullName = careMember?.name?.fullName || "";
                  const relation = o?.relationship || "";
                  const userId = careMember?.id || null;
                  const objToSend = { ...o, relation: relation };
                  const lastindex = menberLength === i + 1;
                  return (
                     <tr
                        onClick={() => {
                           if (table === 0) {
                              openModal(objToSend);
                           }
                        }}
                        key={o?.id || i}
                        id={`dropdownOption-${careMember?.id}`}
                        className="hover:bg-light-blue"
                     >
                        <td className={`pointer ${lastindex && "rounded-bl-[16px]"}`}>
                           <div className="flex gap-[8px] items-center pl-[16px]">
                              <Avatar
                                 src={`${process.env.REACT_APP_PROFILE_URL}/${o?.careMember?.id}`}
                                 isProvider={true}
                                 className="md:flex-shrink-0 pointer mr-4 mt-[5px] md:mt-0"
                                 bgColor={color}
                                 radius={38}
                                 initialsApi={initialsApi}
                                 name={fullName}
                              />
                              <div
                                 title={fullName}
                                 className="text-[17px] leading-[20px] w-[150px] md:w-[192px] lg:w-[285px] truncate"
                              >
                                 {getParam(fullName)}
                              </div>
                           </div>
                        </td>
                        <td className="table-data">
                           <div title={mobile} className={`w-[13.8vw] xl:w-[14vh] truncate`}>
                              {getParam(mobile)}
                           </div>
                        </td>
                        <td className="table-data">
                           <div title={email} className="w-[25vw] lg:w-[23.5vw] xl:w-[16.5vw] truncate">
                              {getParam(email)}
                           </div>
                        </td>
                        {sharingWith && (
                           <td className="table-data">
                              <div title={relation} className="w-[8vw] truncate">
                                 {getParam(relation)}
                              </div>
                           </td>
                        )}
                        {!isShare && (
                           <>
                              {sharingWith ? (
                                 <td className={`${lastindex && "rounded-br-[16px]"} md:pl-2 lg:pl-4 h-[71.2px]`}>
                                    <ActionDropdown
                                       options={optionsShare}
                                       anchor={true}
                                       careId={careMember?.id}
                                       memberId={memberId}
                                       setMemberId={setMemberId}
                                       onChange={(clicked) => {
                                          if (clicked?.id === 0) {
                                             edit(o);
                                          } else {
                                             removeFriendsFamily(null, o, remove, setSelectedPatient);
                                          }
                                       }}
                                       view={() => {
                                          return (
                                             <img
                                                src={options}
                                                alt="more-options"
                                                className="w-[24px] h-[24px] cursor-pointer"
                                             />
                                          );
                                       }}
                                    />
                                 </td>
                              ) : (
                                 <td
                                    className={` ${
                                       lastindex && "rounded-br-[16px]"
                                    } h-[71.2px] md:pl-2 lg:pl-4`}
                                    onClick={(e) => {
                                       getRedisID({ id: userId, name: fullName });
                                       e?.stopPropagation();
                                    }}
                                 >
                                    {loadingSocket === userId ? (
                                       renderLoader()
                                    ) : (
                                       <img
                                          src={rightArrow}
                                          alt="rightArrow"
                                          className="h-[28px] w-[28px] cursor-pointer"
                                       />
                                    )}
                                 </td>
                              )}
                           </>
                        )}
                     </tr>
                  );
               })}
            </tbody>
         </table>
      );
   }
);

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      showPopup: state?.common?.showPopup,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         setShowPopup: commenActions?.showDobPopup,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSharing);
