import React, { useState } from "react";
import { textLabels } from "shared/constants/const";
import Modal from "shared/components/modal/modal";
import { getFilteredResults } from "modules/sharedPatientDetails/action/sharedPatientAction";
import SearchField from "modules/sharedPatientDetails/Component/tabs/searchField";
import NewTableCareTeam from "modules/newCareTeam/component/NewTableCareTeam";
import UserDetailsPopup from "modules/newCareTeam/component/UserDetailsPopup";
import users from "images/newdashbord/users.svg";
// import Search from "modules/navbar/component/searchComp";

function NewCareTeam(props) {
   const { careTeam, loading } = props;
   const [searchKey, setSearchKey] = useState("");
   const [showDirection, setShowDirection] = useState(false);
   const [eachCare, setEachCare] = useState(null);
   // const [showSearch, setShowSearch] = useState(false);
   const hide = () => {
      setShowDirection(false);
   };
   // const showHideSearch = () => {
   //    setShowSearch(!showSearch);
   // };
   return (
      <>
         {/* <button id="search-button-xs" className=" sm:hidden fixed" onClick={showHideSearch} /> */}
         <div className={`sm:mt-[30px] mt-[14px]`}>
            <>
               <div className={`w-full`}>
                  <SearchField searchKey={searchKey} setSearchKey={setSearchKey} />
               </div>
               <div className={`flex items-center gap-[5px] mt-[30px] pb-[28px] ml-[10px] md:ml-[0px] md:pb-[0px]`}>
                  <img src={users} alt="users" className="w-[22px] h-[24px]  " />
                  <div className="  text-container">{textLabels?.careTeam}</div>
               </div>
            </>
            <div className={`w-full md:pt-[15px] md:mt-0`}>
               <NewTableCareTeam
                  members={getFilteredResults(searchKey, careTeam)}
                  isLoading={loading}
                  openModal={(data) => {
                     setShowDirection(true);
                     setEachCare(data);
                  }}
               />
            </div>
            {showDirection && (
               <Modal onModalTapped={hide}>
                  <UserDetailsPopup eachCare={eachCare} onModalTapped={hide} setShowDirection={setShowDirection} />
               </Modal>
            )}
            {/* {showSearch && (
               <Modal onModalTapped={showHideSearch} alter>
                  <Search
                     search={searchKey}
                     onModalTapped={showHideSearch}
                     setSearch={(key) => {
                        setSearchKey(key);
                        getFilteredResults(searchKey, careTeam);
                     }}
                  />
               </Modal>
            )} */}
         </div>
      </>
   );
}

export default NewCareTeam;
