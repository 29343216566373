import React from "react";
import Button from "../button";

const EmptyStateComp = ({
   headerText,
   description,
   btnText = false,
   className,
   disabled,
   onClick = () => {},
   src = "",
   isTabZero
}) => {
   return (
      <div className={`flex h-100 flex-col items-center ${className}`}>
         <div className="no-patients-svg">
            <img src={src} alt={headerText} width={100} />
         </div>
         <h2 className={`${!isTabZero && "text-center w-[200px]"} font-bold mt-3`}>{headerText}</h2>
         <h4 className="text-center text-xs w-48 m-2">{description}</h4>
         {btnText && (
            <Button className="w-[120px] m-1" disabled={disabled} onClick={onClick}>
               {btnText}
            </Button>
         )}
      </div>
   );
};

export default EmptyStateComp;
