import React from "react";
import DobComp from "modules/dateOfBirth/component/DobComp";

const DobCont = () => {
   return (
   <div className={`max-w-[1143px] mx-auto`}>
      <DobComp/>
   </div>
   )
};
export default DobCont;
