import React from "react";
import loader from "assets/gif/loader.gif";
import loaderPrimary from "assets/gif/loader (1).gif";
var LoadingIndicator = ({ className = "" }) => {
   return (
      <div className="flex items-center justify-center h-100 w-100">
         <img
            alt="loading"
            src={className ? loaderPrimary : loader}
            className={` ${className ? className : "w-16"} `}
         />
      </div>
   );
};
export default LoadingIndicator;
