import { gql } from "@apollo/client";

export const SET_PATIENT_CONTENT = "SET_PATIENT_CONTENT";
export const SET_CONTENT_SEARCH_TERM = "SET_CONTENT_SEARCH_TERM";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export const GET_PATIENT_CONTENT_ACTIVITY = gql`
   query Query(
      $offset: Int!
      $limit: Int!
      $contentFilter: String
      $patientOnly: Boolean
      $providerOnly: Boolean
      $search: String
      $user: UserInput
      $isSpotlight: Boolean
      $allowDirectory: Boolean
      $directoryId: String
      $allowBreadcrumb: Boolean
   ) {
      getPatientContent(
         offset: $offset
         limit: $limit
         contentFilter: $contentFilter
         patientOnly: $patientOnly
         providerOnly: $providerOnly
         search: $search
         user: $user
         isSpotlight: $isSpotlight
         allowDirectory: $allowDirectory
         directoryId: $directoryId
         allowBreadcrumb: $allowBreadcrumb
      ) {
         totalCount
         breadCrumbData {
            directoryId
            name
         }
         contents {
            title
            type
            id
            createdAt
            isPrintable
            provider {
               name {
                  fullName
               }
               contactInformation {
                  email
               }
               id
            }
            patient {
               id
               name {
                  fullName
               }
               contactInformation {
                  email
               }
            }
            views {
               viewedAt
               viewer {
                  id
                  name {
                     fullName
                  }
                  contactInformation {
                     email
                  }
               }
            }
            hasThumbnail
            loves
            mentions
            tags
            hospital {
               id
               name
            }
            healthSystem {
               id
               name
               patientIdentifiers {
                  number
                  type
                  authority
               }
            }
         }
      }
   }
`;

export const GET_THUMBNAIL_SIGNED_URL = gql`
   query GetSignedURL($thumbnail: Boolean!, $content: ContentInput!) {
      getSignedURL(thumbnail: $thumbnail, content: $content) {
         url
         thumbnailUrl
         dataCount
      }
   }
`;

export const UPDATE_ITEM_VIEWED_STATUS = gql`
   mutation AddActivity($options: AddActivityOptions) {
      addActivity(options: $options) {
         status {
            code
            success
            message
         }
      }
   }
`;

export const GET_DASHBOARD_APPOINTMENTS_DATA = gql`
   query GetAppointments($offset: Int, $limit: Int, $options: GetAppointmentsOptions) {
      getAppointments(offset: $offset, limit: $limit, options: $options) {
         status {
            code
            success
            message
         }
         appointments {
            id
            reason
            hospital {
               name
            }
            patient {
               name {
                  fullName
                  initials
               }
               id
               colorCode
               contactInformation {
                  email
                  mobileNumber
               }
               dateOfBirth
               dob
            }
            provider {
               id
               name {
                  fullName
                  initials
               }
               colorCode
               contactInformation {
                  email
                  mobileNumber
                  address
               }
            }
            department {
               id
               name
            }
            startDateTime
            endDateTime
         }
         totalCount
      }
   }
`;
