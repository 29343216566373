import { removeFriendsFamily } from "modules/shareFamilyFriends/actions/familyActions";
import React from "react";
import { isDirectionGql, openDirectionGql } from "shared/actions/helpers";
import Avatar from "shared/components/avatar";
import { textLabels } from "shared/constants/const";

export default function GetDirections({
   eachCare,
   removeMember = () => {},
   share = false,
   setSelectedPatient,
   onModalTapped,
   edit = () => {},
}) {
   const { careMember } = eachCare;
   const contactInfo = careMember?.contactInformation;
   const initialsApi = careMember?.name?.initials || false;
   const color = careMember?.colorCode || window.initialColors[0];
   const fullName = careMember?.name?.fullName;
   const mobileNo = contactInfo?.mobileNumber || false;
   const officeNumber = contactInfo?.officeNumber || false;
   const email = contactInfo?.email || false;
   const direction = isDirectionGql(contactInfo) || false;
   const isFamilyMember = eachCare?.type === "caremember" || false;
   const checkToShowMobile = careMember?.providerInfo?.settings?.cellToPatient || false;
   return (
      <div className="text-center w-[60%] sm:w-[270px] post-card flex items-center justify-center flex-col pt-5">
         <Avatar
            isProvider={true}
            className="flex-shrink-0 pointer mr-4"
            bgColor={color}
            radius={48}
            initialsApi={initialsApi}
            name={fullName}
            src={`${process.env.REACT_APP_PROFILE_URL}/${careMember?.id}`}
         />
         <div className="font-17 font-medium text-black w-[80%] pb-2 pt-1">{fullName}</div>
         <div className="pt-2 w-full">
            {officeNumber && (
               <a href={`tel:${officeNumber}`}>
                  <div className="pb-pri w-full border-care p-2 cursor-pointer ">
                     {textLabels.careTeamOption[0].text}
                  </div>
               </a>
            )}
            {mobileNo && checkToShowMobile && (
               <a href={`tel:${mobileNo}`}>
                  <div className="pb-pri w-full border-care p-2 cursor-pointer ">
                     {textLabels.careTeamOption[1].text}
                  </div>
               </a>
            )}
            {email && (
               <div
                  onClick={() => (window.location.href = `mailto:${email}`)}
                  className="pb-pri w-full border-care p-2 cursor-pointer "
               >
                  {textLabels.careTeamOption[2].text}
               </div>
            )}
            {direction && (
               <div
                  onClick={() => openDirectionGql(contactInfo)}
                  className="pb-pri w-full border-care p-2 cursor-pointer "
               >
                  {textLabels.careTeamOption[3].text}
               </div>
            )}
            {share && (
               <div
                  onClick={(e) => {
                     if (share) {
                        edit(eachCare);
                        onModalTapped();
                     }
                  }}
                  className="sm:hidden  w-full border-care p-2 cursor-pointer pb-pri "
               >
                  {textLabels.careTeamOption[4].text}
               </div>
            )}
            {(isFamilyMember || share) && (
               <div
                  onClick={(e) => {
                     if (share) {
                        removeFriendsFamily(e, eachCare, removeMember, setSelectedPatient);
                        onModalTapped();
                     } else {
                        removeMember(eachCare);
                     }
                  }}
                  className="sm:hidden  w-full border-care p-2 cursor-pointer text-[#FF3B30] "
               >
                  {textLabels.careTeamOption[5].text}
               </div>
            )}
         </div>
      </div>
   );
}
