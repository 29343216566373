import { formatPhoneNumberBackend, openMapDirectionGql, showAddress } from "shared/actions/helpers";
import Avatar from "shared/components/avatar";
import emailgray from "images/newdashbord/mailgray.svg";
import smartphonegray from "images/newdashbord/smartphonegray.svg";
import phonegray from "images/newdashbord/phonegray.svg";
import locationgray from "images/newdashbord/map-pingray.svg";
import closeImage from "images/newdashbord/mdi_close.svg";

export default function UserDetailsPopup({ eachCare, setShowDirection }) {
   const isDirection = (eachCare) => {
      let pAddrs = eachCare?.careMember?.contactInformation?.address;
      if (pAddrs && (pAddrs?.phone !== "" || pAddrs?.streetAddress !== "" || pAddrs?.city !== "" || pAddrs?.zip !== ""))
         return true;
      else return false;
   };
   const initialsApi = eachCare?.careMember?.name?.initials || false;
   const color = eachCare?.careMember?.colorCode || window.initialColors[0];
   const name = eachCare?.careMember?.name?.fullName || "";
   const title = eachCare?.careMember?.providerInfo?.title || "";
   const mobileNo = eachCare?.careMember?.contactInformation?.mobileNumber || false;
   const officeNo = eachCare?.careMember?.contactInformation?.officeNumber || false;
   const mobileNumber = formatPhoneNumberBackend(eachCare?.careMember?.contactInformation?.mobileNumber) || "";
   const officeNumber = formatPhoneNumberBackend(eachCare?.careMember?.contactInformation?.officeNumber) || "";
   const email = eachCare?.careMember?.contactInformation?.email || false;
   const direction = isDirection(eachCare) || false;
   const address = showAddress(eachCare?.careMember?.contactInformation) || "";
   const department = eachCare?.careMember?.departments?.length > 0 ? eachCare?.careMember?.departments[0]?.name : "";
   const hospital = eachCare?.careMember?.hospitals?.length > 0 ? eachCare?.careMember?.hospitals[0]?.name : "";
   const showInformationsection = email || mobileNo || officeNo || direction || false;

   return (
      <div
         className={`relative text-center w-[311px] md:w-[375px] post-card pt-[30px] pb-[13px] px-[16px] bg-slate-500`}
      >
         <img
            src={closeImage}
            alt={"close"}
            className="absolute right-3 top-3 w-[24px] h-[24px] cursor-pointer"
            onClick={() => {
               setShowDirection(null);
            }}
         />
         <div
            className={`${
               !showInformationsection && "pb-3"
            } w-[277px] md:w-[340px] flex items-center justify-center flex-col`}
         >
            <div className="flex justify-items-center items-center mr-[16px]">
               <Avatar
                  src={`${process.env.REACT_APP_PROFILE_URL}/${eachCare?.careMember?.id}`}
                  isProvider={true}
                  className="w-[69px] h-[69px] flex-shrink-0 pointer ml-[5px]"
                  bgColor={color}
                  radius={60}
                  isHospImg={true}
                  initialsApi={initialsApi}
                  name={name}
               />
            </div>
            <div className="flex-row justify-center items-center w-[277px] md:w-[340px] mt-[30px]">
               <div title={name} className="text-[22px] font-bold text-black leading-[25px] w-[100%] pb-2">
                  {name}
               </div>
               <div title={title} className="text-[13px] font-medium text-[#8A8A8E] leading-[23px] w-[100%] truncate">
                  {title}
               </div>
               <div
                  title={department}
                  className="text-[13px] font-medium text-[#8A8A8E] leading-[23px] w-[100%] truncate"
               >
                  {department}
               </div>
            </div>
         </div>
         {showInformationsection && (
            <>
               <div className="h-[2px] bg-[#EAEAEA] w-full mt-[18px]">{/* devider */}</div>
               <div className="pt-[18px]">
                  {email && (
                     <div className="flex gap-[16px] mb-[20px]">
                        <img src={emailgray} alt="emailgray" className="w-[24px] h-[24px]" />
                        <div
                           title={email}
                           onClick={() => (window.location.href = `mailto:${email}`)}
                           className="pb-pri w-[100%] truncate cursor-pointer flex justify-start "
                        >
                           {email}
                        </div>
                     </div>
                  )}
                  {mobileNo && (
                     <div className="flex gap-[16px] mb-[20px]">
                        <img src={smartphonegray} alt="smartphonegray" className="w-[24px] h-[24px]" />
                        <a href={`tel:${mobileNo}`}>
                           <div title={mobileNumber} className="pb-pri cursor-pointer">
                              {mobileNumber}
                           </div>
                        </a>
                     </div>
                  )}
                  {officeNo && (
                     <div className="flex gap-[16px] mb-[20px]">
                        <img src={phonegray} alt="phonegray" className="w-[24px] h-[24px]" />
                        <a href={`tel:${officeNo}`}>
                           <div title={officeNumber} className="pb-pri cursor-pointer ">
                              {officeNumber}
                           </div>
                        </a>
                     </div>
                  )}

                  {direction && (
                     <div className="flex gap-[16px] mb-[20px]">
                        <img src={locationgray} alt="locationgray" className="w-[24px] h-[24px]" />
                        <div
                           title={hospital}
                           onClick={() => {
                              openMapDirectionGql(eachCare?.careMember?.contactInformation);
                           }}
                           className="pb-pri w-[100%] truncate cursor-pointer flex justify-start"
                        >
                           {address}
                        </div>
                     </div>
                  )}
                  {/* {(isFamilyMember || share) && (
               <div
                  onClick={(e) => {
                     if (share) {
                        removeMember(e, eachCare);
                     } else {
                        removeMember(eachCare);
                     }
                  }}
                  className="md:hidden  w-full border-care p-2 cursor-pointer text-[#FF3B30] "
               >
                  {textLabels.careTeamOption[4].text}
               </div>
            )} */}
               </div>
            </>
         )}
      </div>
   );
}
