import React, { useEffect } from "react";
import { logout } from "actions/index";
import { showSwal } from "shared/actions/alert";
import { textConst } from "shared/constants/const";

export default function DropOtions({ options, setDrop, navigate }) {
   useEffect(() => {
      window.addEventListener("click", onClickOutSide);

      return () => {
         window.removeEventListener("click", onClickOutSide);
      };
   }, []);

   const onClickOutSide = (e) => {
      const condition =
         document.getElementById("dropDownCustom") && document.getElementById("dropDownCustom").contains(e.target);
      if (!condition) {
         setDrop();
      }
   };

   return (
      <div id="dropDownCustom">
         {options.map((map, i) => (
            <div key={map.id}>
               <div
                  onClick={() => {
                     setDrop();
                     if (map.link === "/logout") {
                        showSwal(textConst.logoutHead, textConst.logout, () => {
                           logout();
                        });
                     } else {
                        navigate(map.link);
                     }
                  }}
                  className="flex items-center p-4 pl-6 rounded-lg text-[18px] font-medium cursor-pointer"
               >
                  <div>
                     <img src={map.img} alt="img-drop" />
                  </div>
                  <div className="text-[15px] sm:text-lg ml-4"> {map.text}</div>
               </div>
            </div>
         ))}
      </div>
   );
}
