import * as launchdarkly from "redux/actions/darklyAction";
import * as LDClient from "launchdarkly-js-client-sdk";
import { v4 as uuid } from "uuid";
import { store } from "redux/store";

export const initializePendo = (userData) => {
   var obj = {};
   if (process.env.NODE_ENV === "production") {
      obj = {
         visitor: {
            visitorId: "Patient-id",
            id: "Patient-id",
            fullName: "UserName",
            isPatient: true,
            email: "email",
            department: "departmentName",
            hospital: "hospitalName",
            departmentId: "departmentId",
            hospitalId: "hospitalId",
            createdAt: userData?.user?.createdAt,
         },

         account: {
            accountId: "accountId",
            accountid: "accountId",
            id: "accountId",
         },
      };
   } else {
      obj = {
         visitor: {
            visitorId: "Patient-id",
            id: "Patient-id",
            fullName: "UserName",
            isPatient: true,
            email: "email",
            department: "departmentName",
            hospital: "hospitalName",
            departmentId: "departmentId",
            hospitalId: "hospitalId",
            createdAt: userData?.user?.createdAt,
         },

         account: {
            accountId:  "accountId",
            accountid: "accountId",
            id: "accountId",
         },
      };
   }
   window.pendo.initialize(obj);
};

export async function initializeLDClient(userInfo) {
   let email = userInfo?.email;
   const user = {
      key: uuid(),
      custom: {
         hospitalName: "",
         enterpriseName: "",
         departmentName: userInfo?.department,
         emailDomain: email?.substring(email?.indexOf("@") + 1),
         role: userInfo?.role,
         deviceType: "web",
      },
   };

   const client = LDClient.initialize(`${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}`, user);
   // will fetch status of flags once the client is initialized and ready to serve.
   client.on("ready", () => {
      const flags = client.allFlags(); // get the flag
      store.dispatch(launchdarkly.setLdFeatureFlags(flags)); // store them in redux
   });
   // Will Fetch updated status of flags whenever a flag is changed.
   client.on("change", () => {
      const flags = client.allFlags(); // get the flags
      store.dispatch(launchdarkly.setLdFeatureFlags(flags)); // store them in redux
   });
}

export const initiaLize = (user) => {
   initializeLDClient(user);
};
