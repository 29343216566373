import PostView from "../post/PostView";
import { calculateDatetime, errorToDisplay } from "shared/actions/helpers";
import { getAccessToken } from "actions";
import { fetchMutation } from "shared/actions/fetchMutationAction";
import { reactHeart, UPDATE_MEDIA_STATUS } from "modules/home/actions/contentActivityActions";
import { ShowAlert } from "shared/actions/alert";
import star from "images/newdashbord/star.svg";
import { ReactComponent as LoveSvg } from "images/love-icon.svg";
import { ReactComponent as FilledLoveSvg } from "images/dashboard/redHeart.svg";
import { connect } from "react-redux";

const NewPost = (props) => {
   const {
      data,
      isLoading,
      auth,
      openModal = () => {},
      isPreview = false,
      id,
      handleClick = () => {},
      yourSpotlight = false,
      showAllSpotlight = false,
      getSpotlight = () => {},
   } = props;
   const getDate = calculateDatetime(data?.createdAt);
   const token = getAccessToken();
   const showReact = data?.type !== "referral";
   const postReact = (e) => {
      e.stopPropagation();
      let key = "mediaId";
      const id = data?.id || null
      if (data?.type === "text" || data?.type === "item") key = "itemId";
      let params = { isActivity: true, media: { type: "loves", [key]: id } };
      fetchMutation(
         UPDATE_MEDIA_STATUS,
         params,
         (res) => {
            if (yourSpotlight) {
               getSpotlight();
            } else {
               reactHeart(data, auth?.userCredentials?.user?.id);
            }
         },
         (err) => {
            let errMsg = errorToDisplay(err);
            ShowAlert(errMsg, "error");
         }
      );
   };
   const isLoved =
      data?.loves?.some((find) => {
         return find === auth?.userCredentials?.user?.id;
      }) || false;

   return (
      <div
         id={id}
         className={`${
            yourSpotlight
               ? "min-w-[103%] sm:min-w-[100%] sm:max-w-[103%] md:max-w-[50%] md:min-w-[48%] lg:max-w-[50%] lg:min-w-[48.5%] xl:min-w-[31.5%] flex gap-[30px] pl-[3px] pr-[10.9px] sm:pr-[2px] md:pl-[0.5px] md:pr-[3px] lg:pr-1 xl:pr-[0.8px]"
               : "flex-1 xl:min-w-[30%] xl:max-w-[31.5%] lg:w-[80%] lg:max-w-[48.5%] md:min-w-[47.8%] md:max-w-[20%] w-[50%]"
         }
          bg-[#F8FAFB] cursor-pointer`}
         key={data?.id}
         onClick={() => {
            openModal(data);
            handleClick(2);
         }}
      >
         <div className="bg-[#FFF] h-[260px] p-[16px] rounded-[16px] card-shadow w-[100%] ">
            <div className="relative rounded-[8px] h-[154px] mb-[14px] card-shadow">
               {showAllSpotlight && (
                  <div className="absolute top-0 left-[16px] w-[30px] h-[30px] bg-[#fff] rounded-b-[8px] z-[20]">
                     <img src={star} alt="star" className="w-[22px] h-[22px] p-[2px] m-auto mt-[0.1rem]" />
                  </div>
               )}
               {showReact && (
                  <div className="absolute bottom-[10px] right-[10.333px] w-[30px] h-[30px] bg-[#FFF] rounded-[8px] card-shadow z-[20] flex justify-center items-center">
                     {isLoved ? <FilledLoveSvg onClick={postReact} /> : <LoveSvg onClick={postReact} />}
                  </div>
               )}
               <div
                  className={`${isLoading && "loading-shade"} ${
                     isPreview ? "h-[154px] sm:h-[154px] flex items-center justify-center " : " h-[154px]"
                  } w-full `}
               >
                  <PostView data={data} accessToken={token} isPreview={isPreview} />
               </div>
            </div>
            <div>
               <div
                  title={data?.title}
                  className="w-[250px] truncate-line-clamp mb-[4px] text-[#000] text-font-weight leading-[19px] h-[40px]"
               >
                  {data?.title}
               </div>
               <div title={data?.addedByName} className="flex justify-between text-[13px] text-[#8A8A8E]">
                  <div className="w-[76%] truncate">{data?.provider?.name?.fullName}</div>
                  <div className="w-auto truncate flex-row">{getDate}</div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
   };
};

export default connect(mapStateToProps, null)(NewPost);
