import moment from "moment";

export const getAppointmentTime = (dateValue) => {
   const date = moment(dateValue).format("MM/DD/YYYY");
   let currentDate = moment(new Date()).format("MM/DD/YYYY").toString();
   let oneDate = moment(dateValue);
   let dayName = oneDate.format("dddd");
   let appointmentDate = moment(dateValue).format("MM/DD/YYYY").toString();
   let diffInDays = Math.abs(moment().diff(date, "days"));
   let duration = moment.duration(moment(new Date()).diff(appointmentDate));
   let diffInHours = Math.abs(duration.asHours());
   let titleLabel = moment(date).format("MM/DD/YYYY");
   if ((diffInDays === 1 && diffInHours >= 24 && diffInHours <= 48) || (diffInDays > 1 && diffInDays <= 5)) {
      titleLabel = dayName + " at " + moment(dateValue).format("h:mm A");
   } else if (diffInDays === 0 && currentDate === appointmentDate) {
      titleLabel = "Today" + " at " + moment(dateValue).format("h:mm A");
   } else if ((diffInDays === 0 && currentDate !== appointmentDate) || diffInDays === 1) {
      titleLabel = "Tomorrow" + " at " + moment(dateValue).format("h:mm A");
   } else if (diffInDays === 6 && dayName !== moment(new Date()).format("dddd")) {
      titleLabel = dayName + " at " + moment(dateValue).format("h:mm A");
   } else {
      titleLabel =
         moment(date).format("MMMM") + ", " + moment(date).format("DD") + " at " + moment(dateValue).format("h:mm A");
   }
   return titleLabel;
};
