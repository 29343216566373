import set from "images/common/sett.svg";
import log from "images/common/lg.svg";
import notif from "images/newimages/profile-action-icons/profile-notification-icon.svg";
import edit from "images/common/edit.svg";
import dele from "images/common/delete.svg";

export const textConst = {
   error: "error",
   fullname: "Please enter full name",
   validFullName: "Please enter a valid name",
   empty: "Please enter either mobile no or email",
   email: "Please enter a valid email",
   phone: "Please enter a valid number",
   dob: "Please enter date of birth",
   qrCode: "Show your provider this QR code to activate your account.",
   rel: "Please select a relation",
   family: "Invited successfully",
   logout: "Are you sure you want to logout ?",
   remove: "Are you sure you want to remove ",
   logoutHead: "Logout",
   removeHead: "Remove",
   yes: "Yes",
   no: "no",
   somthing: "Something went wrong",
   removeProvider: "Are you sure you want to remove ",
   doctor: "Please select doctor.",
};

export const textLabels = {
   save: "Save",
   changePhoto: "Change Photo",
   name: "Name",
   edit: "Edit",
   update: "Update",
   number: "Mobile number/Email",
   rel: "Relationship (Optional)",
   invite: "Invite Family & Friends",
   family: "Family/Friends",
   sharedOptions: ["Shared by me", "Shared with me"],
   careTeam: "Care Team",
   sendInvite: "Send Invite",
   cancel: "Cancel",
   careTeamOption: [
      {
         text: "Call office",
      },
      {
         text: "Call mobile",
      },
      {
         text: "Send email",
      },
      {
         text: "Get directions",
      },
      {
         text: "Edit",
      },
      {
         text: "Remove",
      },
   ],
   activityFilterOption: [
      {
         text: "Everyone",
         val: "all",
      },
      {
         text: "My Health",
         val: "my",
      },
      {
         text: "Family & Friends",
         val: "sharedWithMe",
      },
   ],
};

export const emptyStatesText = {
   emptyHome: "No content available",
   emptyCareTeam: "No members",
   emptyShare: "Share with family/friends",
   emptyShareText: "Add friends/family member to daily updates.",
   emptyShareBtnTxt: "Add members",
   emptyResults: "No results found.",
   emptySharingWith: "It appears that nobody is currently sharing with you"
};

export const dropDownOptions = [
   { text: "Settings", id: 0, img: set, link: "/settings" },
   { text: "Log Out", id: 2, img: log, link: "/logout" },
];

export const dropDownOptionsRender = [
   { text: "Settings", id: 0, img: set, link: "/settings" },
   { text: "Notifications", id: 0, img: notif, link: "/notifications_settings" },
   { text: "Log Out", id: 2, img: log, link: "/logout" },
];

export const options = [
   { text: "Home", id: 0, link: "/" },
   { text: "Care Team", id: 1, link: "/care-team" },
   { text: "Sharing", id: 2, link: "/sharing" },
];

export const optionsShare = [
   { id: 0, text: "Edit", img: edit },
   { id: 1, text: "Remove", img: dele },
];
export const configure = {
   apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
   authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
   storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
   projectId: process.env.REACT_APP_FIREBASEPROJECTID,
};

export const sharingText = {
   addMember : "  Add member",
   imSharingWith: "I am sharing with",
   SharingWithMe: "Sharing with me"
}

export const AppointmentText = {
   appointments: "Appointments",
   call: "Call",
   location: "Location",
   seeAll: "See all"

}