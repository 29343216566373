import * as types from "redux/actions/commonAction";

const INITIAL_STATE = {
   showPopup: null
};

export default function common(state = INITIAL_STATE, action) {
   switch (action.type) {
      case types.SET_DATEOFBIRTH_STATE:
         return {
            ...action.payload,
            showPopup:action.payload
         };
      default:
         return state;
   }
}
