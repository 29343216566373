import React, { useEffect, useState } from "react";
import "modules/dateOfBirth/screen/my_profile/mediaview.css";
import PDFViewer from "modules/dateOfBirth/screen/my_profile/pdfViewer";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { getCookie } from "modules/dateOfBirth/screen/dischargeInstructionSurvey/cookiesActions/cookieActions";
import DischargeInstructionsSurvey from "modules/dateOfBirth/screen/dischargeInstructionSurvey/dischargeInstructionsSurvey";
import TextViewer from "modules/dateOfBirth/screen/my_profile/TextViewer";

export const configure = {
   apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
   authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
   projectId: process.env.REACT_APP_FIREBASEPROJECTID,
};
const MediaView = ({ contentDatas, loading }) => {
   const [showDischargeInstSurvey, setShowDischargeInstSurvey] = useState(false);
   const contentTitle = contentDatas?.data?.media?.title;
   const contentDescriptions = contentDatas?.data?.media?.subTitle;
   const mediaType = contentDatas?.data?.media?.type;
   const medaiUrl = contentDatas?.data?.media?.signedUrl;
   const comments = contentDatas?.data?.customData?.comments || "";
   const mediaId = contentDatas?.data?.media?.mediaId;

   useEffect(() => {
      let contentData = contentDatas?.data || false;
      if (contentData) {
         const title = contentData?.media?.title;
         const cookieValue = getCookie("showSurveyPopup");
         setTimeout(() => {
            if (title.toLowerCase().includes("discharge instructions") && cookieValue === null) {
               setShowDischargeInstSurvey(true);
            }
         }, 5000);
      }
   }, [contentDatas]);

   return (
      <>
         {loading ? (
            <div style={{ height: "70vh" }} className="flex justify-center items-center">
               <div className="border-t-transparent border-solid animate-spin rounded-full border-[#043293] border-4 h-20 w-20"></div>
            </div>
         ) : (
            <>
               {showDischargeInstSurvey && (
                  <DischargeInstructionsSurvey
                     mediaId={mediaId}
                     onClose={() => {
                        setShowDischargeInstSurvey(false);
                     }}
                  />
               )}
               {mediaType?.includes("pdf") ? (
                  <div className="w-[90%] mx-auto md:w-[90%] xl:w-[100%]">
                     <div className="content-box-pdf">
                        <div>
                           <div className="media-wrapper-pdf">
                              <div className="media-head truncate">{contentTitle}</div>
                           </div>
                        </div>
                     </div>

                     <div style={{ height: "90vh" }} className=" sm:hidden  overflow-scroll">
                        <PDFViewer pdfFile={medaiUrl} name={contentTitle} />
                     </div>
                     <div style={{ height: "90vh" }} className=" hidden sm:block">
                        <embed title={contentTitle} src={medaiUrl} width="100%" height="100%"></embed>
                     </div>
                     {comments?.trim() && (
                        <div className="content-box-comment w-[100%] sm:w-[100%]">
                           <div className="content-media-info-main-div">
                              <div className="content-meida-title">Message</div>
                              <div className="content-media-desc">{comments}</div>
                           </div>
                        </div>
                     )}
                  </div>
               ) : (
                  <div className="relative-cont">
                     {medaiUrl ? (
                        <div className="content-box">
                           <div className="container">
                              <div className="media-wrapper">
                                 <div className="media-head">{contentTitle}</div>
                                 <div className={mediaType?.includes("audio") ? "media-body audio-body" : "media-body"}>
                                    {mediaType?.includes("video") ? (
                                       <video width="80%" height="65%" controls src={medaiUrl}></video>
                                    ) : (
                                       ""
                                    )}
                                    {mediaType?.includes("audio") ? (
                                       <div className="audio-player-custom-safari audio-player-custom">
                                          <AudioPlayer src={medaiUrl} />
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                    {mediaType?.includes("item") ? (
                                       <>
                                          <div className="alert alert-info" style={{ "background-color": "#d3d3d3" }}>
                                             <p style={{ color: "#1E1C1C" }}>{contentDescriptions}</p>
                                          </div>
                                       </>
                                    ) : (
                                       ""
                                    )}
                                    {mediaType?.includes("else") ? (
                                       medaiUrl ? (
                                          <embed style={{ height: "0", width: "0" }} src={medaiUrl} />
                                       ) : (
                                          <iframe
                                             title={contentTitle}
                                             src={medaiUrl}
                                             width="100%"
                                             height="100%"
                                          ></iframe>
                                       )
                                    ) : (
                                       ""
                                    )}
                                    {mediaType?.includes("image") ? (
                                       <img
                                          style={{
                                             objectFit: "contain",
                                          }}
                                          alt=""
                                          src={medaiUrl}
                                          width="auto"
                                          height="auto"
                                       />
                                    ) : (
                                       ""
                                    )}
                                    {/* {mediaType?.includes("pdf") ? (
                                       <div style={{ height: "90vh" }}>
                                          <div
                                             style={{ height: "90vh" }}
                                             className="sm:block md:hidden overflow-scroll"
                                          >
                                             <PDFViewer pdfFile={medaiUrl} name={contentTitle} />
                                          </div>
                                          <div style={{ height: "90vh" }} className="sm:hidden md:block">
                                             <embed
                                                title={contentTitle}
                                                src={medaiUrl}
                                                width="100%"
                                                height="100%"
                                             ></embed>
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )} */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div className="container content-box-text">
                           <div className="media-head-text">
                              <TextViewer content={contentTitle} loading={loading} />
                           </div>
                        </div>
                     )}
                     {comments?.trim() && (
                        <div className="content-box">
                           <div className="container">
                              <div className="content-media-info-main-div">
                                 <div className="content-meida-title">Message</div>
                                 <div className="content-media-desc">{comments}</div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               )}
            </>
         )}
      </>
   );
};
export default MediaView;
