import React, { memo } from "react";
import { getParam, openMapDirectionGql } from "shared/actions/helpers";
import { emptyStatesText } from "shared/constants/const";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import CareTeam from "images/emptyStates/careTeam.svg";
import emailblue from "images/newdashbord/mailblue.svg";
import smartphoneblue from "images/newdashbord/smartphoneblue.svg";
import phoneblue from "images/newdashbord/phoneblue.svg";
import locationblue from "images/newdashbord/map-pinblue.svg";
import emailgray from "images/newdashbord/mailgray.svg";
import smartphonegray from "images/newdashbord/smartphonegray.svg";
import phonegray from "images/newdashbord/phonegray.svg";
import locationgray from "images/newdashbord/map-pingray.svg";
import arrow from "images/newdashbord/Back.svg";
import Avatar from "shared/components/avatar";
import UserDetails from "modules/newCareTeam/component/UserDetails";
import TableLoading from "modules/dashboard/allskeleton/TableLoading";

const tableHeaderStyle = {
   background: "#FFF",
   height: 49,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   background: "#FFF",
   height: 49,
   display: "flex",
   alignItems: "center",
};
const tableHeaderStyleSmall = {
   background: "#FFF",
   height: 49,
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
};
const NewTableCareTeam = memo(({ members = [], isLoading = false, openModal = () => {} }) => {
   const isDirection = (careMember) => {
      let pAddrs = careMember?.address;
      if (pAddrs && (pAddrs?.phone !== "" || pAddrs?.streetAddress !== "" || pAddrs?.city !== "" || pAddrs?.zip !== ""))
         return true;
      else return false;
   };
   const memberLength = members?.length || 0;
   if (isLoading)
      return (
         <div>
            <TableLoading />
         </div>
      );
   else if (memberLength === 0)
      return (
         <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
            <EmptyStateComp src={CareTeam} headerText={emptyStatesText?.emptyCareTeam} />
         </div>
      );
   else
      return (
         <>
            <table
               className="w-full hidden md:table card-shadow rounded-[16px] bg-[#fff]"
               cellPadding="0"
               cellSpacing="0"
               border="0"
            >
               <thead className="text-small">
                  <tr>
                     <th className="w-[35%]">
                        <div
                           className="table-head border-b-2 border-[#E0E0E0]"
                           style={{
                              ...tableHeaderStyle,
                              borderTopLeftRadius: "16px",
                              paddingLeft: 16,
                           }}
                        >
                           Name
                        </div>
                     </th>
                     <th className="w-[15%]">
                        <div className="table-head border-b-2 border-[#E0E0E0]" style={{ ...tableHeaderConst }}>
                           Title
                        </div>
                     </th>
                     <th className="w-[20%]">
                        <div className="table-head border-b-2 border-[#E0E0E0]" style={{ ...tableHeaderConst }}>
                           Department
                        </div>
                     </th>
                     <th>
                        <div
                           className={`table-head border-b-2 border-[#E0E0E0]`}
                           style={{ ...tableHeaderStyleSmall, borderTopRightRadius: "16px", paddingLeft: "15px" }}
                        >
                           Actions
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {members?.map((o, i) => {
                     const { careMember } = o;
                     const contactInfo = careMember?.contactInformation;
                     const email = careMember?.contactInformation?.email || "";
                     const phoneNo = careMember?.contactInformation?.mobileNumber || "";
                     const officeNo = careMember?.contactInformation?.officeNumber || "";
                     const initialsApi = careMember?.name?.initials || false;
                     const color = careMember?.colorCode || window?.initialColors[0];
                     const name = careMember?.name?.fullName || "";
                     const title = careMember?.providerInfo?.title || "";
                     const showDirection = isDirection(contactInfo) || false;
                     const deparments = careMember?.departments?.length > 0 ? careMember?.departments[0]?.name : "";
                     const length = memberLength === i + 1;
                     return (
                        <tr
                           key={o?.id || i}
                           onClick={(e) => {
                              openModal(o);
                              e.stopPropagation();
                           }}
                           className="hover:bg-light-blue"
                        >
                           <td className={`pointer py-3 ${length && "rounded-bl-[16px]"}`}>
                              <div className="flex gap-[8px] items-center pl-[16px]">
                                 <Avatar
                                    src={`${process.env.REACT_APP_PROFILE_URL}/${o.careMember?.id}`}
                                    isProvider={true}
                                    className="md:flex-shrink-0 pointer mr-4 mt-[5px] md:mt-0"
                                    bgColor={color}
                                    radius={38}
                                    initialsApi={initialsApi}
                                    name={name}
                                 />
                                 <div
                                    title={name}
                                    className="text-[17px] leading-[20px] w-[150px] md:w-[192px] lg:w-[285px] truncate"
                                 >
                                    {getParam(name)}
                                 </div>
                              </div>
                           </td>
                           <td className="table-data">
                              <div title={title} className="w-[90px] md:w-[107px] lg:w-[145px] truncate">
                                 {getParam(title)}
                              </div>
                           </td>
                           <td className="table-data">
                              <div title={deparments} className="w-[120px] md:w-[167px] lg:w-[235px] truncate">
                                 {getParam(deparments)}
                              </div>
                           </td>
                           <td
                              onClick={(e) => {
                                 e.stopPropagation();
                              }}
                              className={`pr-[16px] table-data ${length && "rounded-br-[16px]"}`}
                           >
                              <div className={`table-data`}>
                                 <div className="flex justify-end gap-[30px] lg:gap-[50px]">
                                    <div>
                                       {email ? (
                                          <img
                                             src={emailblue}
                                             alt="emailblue"
                                             className="w-[24px] h-[24px] cursor-pointer"
                                             onClick={() => (window.location.href = `mailto:${email}`)}
                                          />
                                       ) : (
                                          <img src={emailgray} alt="emailgray" className="w-[24px] h-[24px]" />
                                       )}
                                    </div>
                                    <div>
                                       {phoneNo ? (
                                          <a href={`tel:${phoneNo}`}>
                                             <img src={smartphoneblue} alt="smartphone" className="w-[24px] h-[24px]" />
                                          </a>
                                       ) : (
                                          <img
                                             src={smartphonegray}
                                             alt="smartphonegray"
                                             className="w-[24px] h-[24px]"
                                          />
                                       )}
                                    </div>
                                    <div>
                                       {officeNo ? (
                                          <a href={`tel:${officeNo}`}>
                                             <img src={phoneblue} alt="officeNo" className="w-[24px] h-[24px]" />
                                          </a>
                                       ) : (
                                          <img src={phonegray} alt="officeNo" className="w-[24px] h-[24px]" />
                                       )}
                                    </div>
                                    <div>
                                       {showDirection ? (
                                          <div
                                             onClick={() => {
                                                openMapDirectionGql(careMember?.contactInformation);
                                             }}
                                          >
                                             <img src={locationblue} alt="locationblue" className="w-[24px] h-[24px]" />
                                          </div>
                                       ) : (
                                          <img src={locationgray} alt="locationgray" className="w-[24px] h-[24px]" />
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </table>
            {/* user details popup */}
            <div className="md:hidden w-full bg-[#fff] card-shadow rounded-[10px] py-[15px]">
               {members?.map((o) => {
                  const { careMember } = o;
                  const initialsApi = careMember?.name?.initials || false;
                  const color = careMember?.colorCode || window.initialColors[0];
                  const name = careMember?.name?.fullName || "";
                  const deparment = careMember?.departments?.length > 0 ? careMember?.departments[0]?.name : "";
                  const title = careMember?.providerInfo?.title || "";
                  return (
                     <div
                        onClick={() => {
                           openModal(o);
                        }}
                        className={`flex items-center justify-between hover:bg-light-blue xsm:w-[88%] px-[16px] py-[8px]`}
                     >
                        <UserDetails
                           src={`${process.env.REACT_APP_PROFILE_URL}/${o?.careMember?.id}`}
                           title={name}
                           color={color}
                           initialsApi={initialsApi}
                           name={name}
                           details={[{ title: title }, { title: deparment }]}
                           careteam={true}
                        />
                        <img src={arrow} alt="arrow" className="w-[24px] h-[24px]" />
                     </div>
                  );
               })}
            </div>
         </>
      );
});

export default NewTableCareTeam;
