import React, { memo } from "react";
import { textLabels } from "shared/constants/const";
import rightArroe from "images/newdashbord/arro_right_go.svg";
import group from "images/newdashbord/group.svg";
import options from "images/newdashbord/options.svg";
import back from "images/newdashbord/Back.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commenActions from "redux/actions/commonAction.js";
import SharingUserDetails from "./SharingUserDetails";

const TableEach = ({
   members,
   openModal,
   share = false,
   sharingWith,
   getRedisID,
   sharingWithMeSM = false,
   table,
   loadingSocket = false,
   renderLoader = () => {},
}) => {
   const imSharingWithData = members?.filter((filter) => {
      return share ? !filter?.isCareTeam : filter?.isCareTeam;
   });

   return (
      <div className={`md:hidden w-full cursor-pointer mb-[30px] card-shadow bg-[#fff] rounded-[10px] `}>
         {members
            .filter((filter) => {
               return share ? !filter?.isCareTeam : filter?.isCareTeam;
            })
            .map((o, i) => {
               const { careMember } = o;
               const contactInfo = careMember?.contactInformation;
               const initialsApi = careMember?.name?.initials || false;
               const color = careMember?.colorCode || window.initialColors[0];
               const fullName = careMember?.name?.fullName || "";
               const isEmail = contactInfo?.email || false;
               const relation = o?.relationship || "";
               const detailsArr = [{ title: isEmail }];
               if (sharingWith) {
                  detailsArr.push({ title: relation });
               }
               const userId = careMember?.id || null;
               const fastinddex = i === 0;
               const lastindex = i === imSharingWithData?.length - 1;
               return (
                  <div
                     key={o?.id || o?.name}
                     onClick={() => {
                        if (share) {
                           openModal(o, false);
                        } else {
                           openModal(o);
                        }
                     }}
                     className={`flex items-center justify-between py-[7px] md:py-3 hover:bg-light-blue 
                     ${fastinddex && "pt-4"}  ${lastindex && "pb-4"}
                        `}
                  >
                     <SharingUserDetails
                        src={`${process.env.REACT_APP_PROFILE_URL}/${careMember?.id}`}
                        className="ml-3"
                        title={fullName}
                        post={o.type}
                        color={color}
                        initialsApi={initialsApi}
                        name={fullName}
                        details={detailsArr}
                        sharing={true}
                        sharingWith={sharingWith}
                        table={table}
                        sharingWithMeSM={sharingWithMeSM}
                     />
                     {sharingWith ? (
                        <img
                           className="mr-3 w-[20px] h-[20px]"
                           src={options}
                           alt="list"
                           onClick={(e) => {
                              if (share) {
                                 e?.stopPropagation();
                                 openModal(o, false);
                              }
                           }}
                        />
                     ) : (
                        <>
                           {loadingSocket === userId ? (
                              renderLoader()
                           ) : (
                              <div
                                 className="pr-1 md:mr-0 py-[15px] pl-5 "
                                 onClick={(e) => {
                                    if (share) {
                                       getRedisID({ id: userId, name: fullName });
                                       e?.stopPropagation();
                                    }
                                 }}
                              >
                                 <img className="w-[28px] h-[28px] lg:block hidden" src={rightArroe} alt="rightArroe" />
                                 <img className="w-[28px] h-[28px] block lg:hidden" src={back} alt="back" />
                              </div>
                           )}
                        </>
                     )}
                  </div>
               );
            })}
      </div>
   );
};

const SharingSmallScreenTable = memo(
   (
      {
         members = [],
         isLoading = false,
         openModal = () => {},
         navigate,
         auth,
         getRedisID,
         sharingWithmelength,
         loadingSocket = false,
         renderLoader = () => {},
      },
      sharingWith,
      table
   ) => {
      if (isLoading)
         return (
            <div className="post-card p-4">
               {Array(10)
                  .fill()
                  .map((index) => (
                     <div
                        key={index}
                        className="flex animate-pulse flex-row items-center h-full justify-center space-x-1 rounded-lg "
                     >
                        <div className="flex flex-col space-y-3 w-full px-2 py-1 rounded-lg">
                           <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                        </div>
                     </div>
                  ))}
            </div>
         );
      else
         return (
            <div>
               <TableEach members={members} openModal={openModal} sharingWith={sharingWith} auth={auth} table={table} />
               {sharingWithmelength > 0 && (
                  <>
                     <div className="flex gap-2 md:hidden px-[15px] justify-start items-center mb-[15px]">
                        <img src={group} alt="group" className="w-[17px] h-[18px]" />
                        <div className="text-[#1D1D1F] text-[17px] font-semibold leading-[28px] tracking-[-0.442px]">
                           {textLabels?.sharedOptions[1]}
                        </div>
                     </div>
                     <TableEach
                        renderLoader={renderLoader}
                        loadingSocket={loadingSocket}
                        members={members}
                        openModal={openModal}
                        share={true}
                        navigate={navigate}
                        auth={auth}
                        getRedisID={getRedisID}
                        table={table}
                        sharingWithMeSM={true}
                     />
                  </>
               )}
            </div>
         );
   }
);

const mapStateToProps = (state) => {
   return {
      auth: state?.auth,
      showPopup: state?.common?.showPopup,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         setShowPopup: commenActions?.showDobPopup,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(SharingSmallScreenTable);
