import React, { useState } from "react";
import { getReferralDetails } from "actions/index";
import filter from "images/dashboard/Filter.svg";
import alter from "images/dashboard/list 2.svg";
import Post from "shared/components/post/Post";
import TableView from "shared/components/post/postTable";
import XSTable from "shared/components/post/xsTable";
import InfiniteScroll from "shared/components/infinteScroll/infinteScroll";
import Modal from "shared/components/modal/modal";
import SkeletonPost from "shared/components/post/postSkeleton";
import Dropdowntoggle from "shared/components/dropDown/dropDownCustom";
import { emptyStatesText, textLabels } from "shared/constants/const";
import ReferralDetailsView from "shared/components/post/referalView";
import toggleVector from "images/dashboard/four.svg";
import Search from "modules/navbar/component/searchComp";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import HomeImg from "images/emptyStates/home.svg";

function Home({
   getPatientContentActivity,
   setSearchTerm,
   contentActivitylist,
   handleSearchText,
   filterby,
   setFilterBy,
   contentList,
   searchTerm,
   userId,
   reactHeart,
   view,
   setView,
   forSharing,
}) {
   const [searchKey, setSearchKey] = useState("");
   const [eachPost, setEachPost] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [showReferal, setShowReferalModal] = useState(false);
   const [showSearch, setShowSearch] = useState(false);

   const showHide = () => {
      setShowModal(false);
   };
   const showHideSearch = () => {
      setShowSearch(!showSearch);
   };

   const toggleReferal = () => {
      setShowReferalModal(!showReferal);
   };

   const openModal = async (data) => {
      if (data.type === "referral") {
         let res = await getReferralDetails({ referralId: data.id });
         setEachPost(res.data.data);
         toggleReferal();
      } else {
         setShowModal(true);
         setEachPost(data);
      }
   };

   return (
      <div>
         <button id="search-button-xs" className=" sm:hidden w-[0] h-[0] fixed" onClick={showHideSearch} />
         <div className="flex justify-between items-center mt-4 ">
            <div className="pl-5 text-2xl text-black font-medium">
               {/* {activity.length > 1 && calculateDateDay(activity[0].createdAt)} */}
            </div>
            <div className={`flex ${forSharing ? "w-full justify-between" : "justify-end"} items-center`}>
               <input
                  type="text"
                  placeholder="Search"
                  className=" hidden sm:inline search-input-profile outline-0"
                  value={searchKey}
                  onChange={(e) => {
                     handleSearchText(e.target.value);
                     setSearchKey(e.target.value);
                     setSearchTerm(e.target.value);
                     setFilterBy("all");
                     // setIsLoading(true);
                  }}
               />
               {/* {!forSharing && (
                  <Dropdowntoggle
                     onChange={(sel) => {
                        setFilterBy(sel.val);
                        setSearchKey("");
                     }}
                     options={textLabels.activityFilterOption}
                     view={() => {
                        return <img src={filter} alt="filter" className="ml-4 cursor-pointer filter-back p-[8px]" />;
                     }}
                  />
               )} */}

               <img
                  onClick={() => {
                     setView(!view);
                     if (forSharing) {
                        localStorage.setItem("viewPatient", !view);
                     } else {
                        localStorage.setItem("view", !view);
                     }
                  }}
                  src={!view ? alter : toggleVector}
                  alt="swap"
                  className="ml-2 mr-3 cursor-pointer filter-back p-[7px]"
               />
            </div>
         </div>
         <div className="pt-3">
            {contentActivitylist.loading ? (
               <SkeletonPost view={view} />
            ) : (
               <InfiniteScroll
                  callBack={(call) => {
                     return getPatientContentActivity(call, false, searchTerm, userId, filterby);
                  }}
                  showLoader={contentActivitylist.hasMore}
               >
                  {view ? (
                     <div className="mt-3">
                        <div className="hidden sm:inline">
                           <TableView
                              posts={contentList}
                              openModal={openModal}
                              isLoading={contentActivitylist.loading}
                           />
                        </div>
                        <div className="sm:hidden ml-[-5px]">
                           <XSTable posts={contentList} openModal={openModal} isLoading={contentActivitylist.loading} />
                        </div>
                     </div>
                  ) : (
                     <div className="flex items-center flex-wrap pr-0 pl-0">
                        {contentList.length === 0 ? (
                           <div className="flex items-center justify-center w-full h-[60vh] text-bold text-large text-grey5 no-content-available">
                              <EmptyStateComp src={HomeImg} headerText={emptyStatesText.emptyHome} />
                           </div>
                        ) : (
                           contentList.map((map) => {
                              // let labelPersist = "";
                              // const label = calculateDateDay(map.createdAt);
                              // const showLabel = label !== labelPersist;
                              // if (showLabel) {
                              //    labelPersist = label;
                              // }
                              return (
                                 <Post
                                    key={map.id}
                                    data={map}
                                    isLoading={contentActivitylist.loading}
                                    openModal={openModal}
                                    reactHeart={reactHeart}
                                 />
                              );
                           })
                        )}
                     </div>
                  )}
               </InfiniteScroll>
            )}
         </div>
         {showModal && (
            <Modal onModalTapped={showHide}>
               <Post isPreview={true} modal data={eachPost} onModalTapped={showHide} reactHeart={reactHeart} />
            </Modal>
         )}

         {showReferal && (
            <Modal onModalTapped={toggleReferal}>
               <ReferralDetailsView isPreview={true} modal referralDetails={eachPost} onModalTapped={toggleReferal} />
            </Modal>
         )}
         {showSearch && (
            <Modal onModalTapped={showHideSearch} alter>
               <Search
                  search={searchKey}
                  onModalTapped={showHideSearch}
                  setSearch={(key) => {
                     setSearchKey(key);
                  }}
               />
            </Modal>
         )}
      </div>
   );
}

export default Home;
