import { store } from "redux/store";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import {
   GET_PATIENT_CONTENT_ACTIVITY,
   SET_PATIENT_CONTENT,
   SET_CONTENT_SEARCH_TERM,
   SET_INITIAL_STATE,
} from "../constants/DashboardConst";
import { errorToDisplay } from "shared/actions/helpers";
import { ShowAlert } from "shared/actions/alert";

import { gql } from "@apollo/client";

export const UPDATE_MEDIA_STATUS = gql`
   mutation UpdateMedia($isActivity: Boolean, $isDelete: Boolean, $media: ContentInput) {
      updateMedia(isActivity: $isActivity, isDelete: $isDelete, media: $media) {
         message
      }
   }
`;

export const setPatientContent = (object) => {
   return {
      type: SET_PATIENT_CONTENT,
      payload: object,
   };
};

export const setContentSearchTerm = (object) => {
   return {
      type: SET_CONTENT_SEARCH_TERM,
      payload: object,
   };
};

export const setInitialState = () => {
   return {
      type: SET_INITIAL_STATE,
   };
};

export const getPatientContentActivity = (
   callBack = () => {},
   initialCall = false,
   searchText = "",
   patientId = null,
   filterKey = "all",
   showAllSpotlight = false,
   folder_id = ""
) => {
   return (dispatch) => {
      let reduxStore = store.getState();
      const { contentActivitylist } = reduxStore;
      const { limit } = contentActivitylist;
      let activityObj = {};
      if (initialCall) {
         store.dispatch(
            setPatientContent({
               ...contentActivitylist,
               list: [],
               loading: true,
               offset: 0,
               totalCount: 0,
            })
         );
      }
      activityObj = {
         limit: limit,
         offset: initialCall ? 0 : contentActivitylist.offset,
         ...(patientId && {
            user: {
               id: patientId,
            },
         }),
         patientOnly: true,
         providerOnly: false,
         search: searchText,
         contentFilter: "my",
         isSpotlight: showAllSpotlight,
         allowDirectory: true,
         directoryId: folder_id,
         allowBreadcrumb: true,
      };
      fetchQuery(
         GET_PATIENT_CONTENT_ACTIVITY,
         activityObj,
         (res) => {
            const contentData = res?.data?.getPatientContent?.contents || [];
            const totalCount = res?.data?.getPatientContent?.totalCount;
            const breadCrumbData = res?.data?.getPatientContent?.breadCrumbData?.reverse() || [];
            const totalResults = initialCall ? contentData : [...contentActivitylist?.list, ...contentData];
            callBack();
            store.dispatch(
               setPatientContent({
                  ...contentActivitylist,
                  list: initialCall ? contentData : totalResults,
                  loading: false,
                  offset: initialCall
                     ? contentActivitylist?.limit
                     : contentActivitylist?.offset + contentActivitylist?.limit,
                  totalCount: totalCount,
                  breadCrumbData: breadCrumbData,
                  hasMore: totalResults?.length < totalCount,
               })
            );
         },
         (err) => {
            setPatientContent({
               ...contentActivitylist,
               loading: false,
            });
            let msg = errorToDisplay(err);
            ShowAlert(msg, "error");
         }
      );
   };
};

export const reactHeart = (data, id) => {
   let reduxStore = store.getState();
   const { contentActivitylist } = reduxStore;
   const contentList = contentActivitylist.list;
   let i = contentList.findIndex((index) => {
      return index.id === data.id;
   });
   var arrToMap = contentList.map((s) => s);
   if (
      arrToMap[i].loves?.some((some) => {
         return some === id;
      })
   ) {
      let index = arrToMap[i].loves.findIndex((find) => {
         return find === id;
      });
      arrToMap[i].loves.splice(index, 1);
   } else {
      let tempArr = [];
      tempArr.push(id);
      arrToMap[i].loves = tempArr;
   }
   store.dispatch(
      setPatientContent({
         ...contentActivitylist,
         list: arrToMap,
      })
   );
};
