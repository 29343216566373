import React, { useEffect, useState } from "react";
import PatientLogin from "modules/patientOnboard/components/PatientLogin";
import { socketActions, socketSubActions } from "socket/Websocket";
import LoadingIndicator from "shared/components/loader";
import { ShowAlert, showSwal } from "shared/actions/alert";
import { checkEmail, unformatNumber, formatDate } from "shared/actions/helpers";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { textConst } from "shared/constants/const";
import { useNavigate } from "react-router-dom";
import PatientContainer from "modules/patientOnboard/container/patientContainer";
import { loginConst } from "shared/constants/loginConst";
import {
   checkUserExistsSocial,
   createProfileDob,
   resendOtpLoginMain,
   UpdateDobAndLogin,
} from "actions/login/loginActions";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

const Login = (props) => {
   const navigate = useNavigate();
   const localEmail = localStorage.getItem("localEmail") || "";
   const localPhone = localStorage.getItem("localPhone") || "";
   const fireBaseId = localStorage.getItem("firebaseId") || null;
   const isPhoneOrigin = localEmail === "" || false;
   const [step, setStep] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [savedEmail, setSavedEmail] = useState(localEmail);
   const [dateOfBirth, setDateOfBirth] = useState(null);
   const [dateNew, setDateNew] = useState(null);
   const [savedPhoneNumber, setSavedPhoneNumber] = useState(localPhone);
   const [isSocial, setIsSocial] = useState(fireBaseId);
   const [sameProvider, setSameProvider] = useState(null);
   const [otp, setOtp] = useState("");
   const [isPhone, setIsPhone] = useState(isPhoneOrigin);
   const [user, setUser] = useState(null);
   const [signup, setSignUp] = useState(false);
   const [fullName, setFullName] = useState("");
   const [selectedUser, setSelectedUser] = useState(null);
   const [renderDob, setRenderDob] = useState(false);
   const isSocialLogin = localStorage.getItem("socialLogin") === "true" || false;
   const { savenorthwelluserobj, saveusercredentials } = props;
   const [innerLoader, setInnerLoader] = useState(true);
   const [countAccount, setCountAccount] = useState(null);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [step]);

   const checkUrlAuth = () => {
      const localEmail = localStorage.getItem("localEmail") || "";
      const localPhone = localStorage.getItem("localPhone") || "";
      const fireBaseId = localStorage.getItem("firebaseId") || null;
      if (fireBaseId) {
         setStep(4);
      } else if (localEmail || localPhone) {
         checkUserExists();
      }
      localStorage.removeItem("localEmail");
      localStorage.removeItem("firebaseId");
      localStorage.removeItem("localPhone");
   };
   const checkUserExists = () => {
      const phoneNumberWithoutFormat = unformatNumber(savedPhoneNumber);
      let obj = {};
      let sendOtp = {};
      if (isPhone) {
         if (phoneNumberWithoutFormat.length < 10) {
            ShowAlert(textConst.phone, textConst.error);
            return;
         }
         obj = {
            action: socketActions.auth,
            subAction: socketSubActions.checkUser,
            phone: phoneNumberWithoutFormat.substring(1),
         };
         sendOtp = {
            action: socketActions.auth,
            subAction: socketSubActions.authUserPhone,
            mobileNo: "+" + phoneNumberWithoutFormat,
         };
      } else {
         if (!checkEmail(savedEmail)) {
            ShowAlert(textConst.email, textConst.error);
            return;
         }
         obj = {
            action: socketActions.auth,
            subAction: socketSubActions.checkUser,
            email: savedEmail,
         };
         sendOtp = {
            action: socketActions.auth,
            subAction: socketSubActions.authUserEmail,
            emailId: savedEmail,
         };
      }
      setIsLoading(true);
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.data?.isUserExist) {
            window.socket.send(sendOtp, (result) => {
               setIsLoading(false);
               if (result?.settings?.status === 1) {
                  setUser(result.data);

                  setStep(3);
               } else if (result?.settings?.status === 0) {
                  ShowAlert(result.settings.message, textConst.error);
               } else {
                  ShowAlert(result?.message, textConst.error);
               }
            });
         } else if (resultStatus?.data?.isUserExist === false) {
            setIsLoading(false);
            showSwal(
               "",
               resultStatus.settings.message,
               () => {
                  setSignUp(true);
               },
               textConst.yes,
               textConst.no
            );
         } else {
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message, textConst.error);
         }
      });
   };

   const LoginInuser = (selectedUser = null) => {
      setIsLoading(true);
      const obj = {
         action: socketActions.auth,
         subAction: socketSubActions.validatePatient,
         userId: selectedUser ? selectedUser?.userId : user.userId,
         enteredDob: formatDate(dateOfBirth, true),
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            firebase
               .auth()
               .signInWithCustomToken(resultStatus.data.customToken)
               .then((storeDataRedux) => {
                  let userData = { user: resultStatus.data.userData };
                  savenorthwelluserobj(JSON.parse(JSON.stringify(storeDataRedux)));
                  saveusercredentials(userData);
                  navigate("/");
               })
               .catch((error) => {
                  console.log(error);
               });
         } 
         else if (resultStatus?.settings.status === 2) {
            ShowAlert(resultStatus.settings.message, textConst.error);
            setIsLoading(false);
            setStep(0)
            setOtp("")
            setDateOfBirth(null)
            setIsPhone(true);
            setSavedEmail("");
            setSavedPhoneNumber("");
            
         } 
         else {
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message, textConst.error);
            setRenderDob(true);
         }
      });
   };

   const verifyOtp = () => {
      setIsLoading(true);
      const obj = {
         action: socketActions.auth,
         subAction: socketSubActions.verifyOTPV2,
         userId: user.userId,
         enteredOtp: otp,
      };
      window.socket.send(obj, (resultStatus) => {
         setIsLoading(false);
         setInnerLoader(true);
         if (resultStatus?.settings.status === 1) {
            if (resultStatus?.data?.isDOBExist) {
               nextStep();
            } else {
               setStep(5);
               setFullName(resultStatus?.data?.name);
            }
         } else {
            ShowAlert(resultStatus.settings.message, textConst.error);
         }
      });
   };

   const nextStep = () => {
      setStep(step + 1);
   };
   useEffect(() => {
      if (isSocialLogin) {
         setIsLoading(true);
      }
      checkSocialLogin();
      checkUrlAuth();
   }, []);

   const checkSafariSocialLogin = (googleLogin = true) => {
      let provider;
      localStorage.setItem("socialLogin", "true");
      if (googleLogin) {
         provider = new GoogleAuthProvider();
      } else {
         provider = new OAuthProvider("apple.com");
      }
      const auth = getAuth();
      signInWithPopup(auth, provider)
         .then((result) => {
            if (result) {
               const email = result.user.email;
               setSavedEmail(email);
               setFullName(result?.user?.displayName || "");
               localStorage.removeItem("socialLogin");
               setIsLoading(true);
               firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (idToken) {
                     checkUserExistsSocial(idToken, (res) => {
                        if (res.data.isUserExist) {
                           setUser(res.data);
                           setIsLoading(false);
                           setStep(4);
                           setIsSocial(idToken);
                        } else {
                           setIsLoading(false);
                           showSwal(
                              "",
                              res.settings.message,
                              () => {
                                 setSignUp(true);
                              },
                              textConst.yes,
                              textConst.no
                           );
                        }
                     });
                  });
            } else {
               if (isSocialLogin) {
                  setIsLoading(false);
                  localStorage.removeItem("socialLogin");
               }
            }
         })
         .catch(function (error) {
            console.log({ error });
         });
   };

   const checkSocialLogin = () => {
      firebase
         .auth()
         .getRedirectResult()
         .then((result) => {
            if (result.credential) {
               const email = result.user.email;
               setSavedEmail(email);
               setFullName(result?.user?.displayName || "");
               localStorage.removeItem("socialLogin");
               firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (idToken) {
                     checkUserExistsSocial(idToken, (res) => {
                        if (res.data.isUserExist) {
                           setUser(res.data);
                           setIsLoading(false);
                           setStep(4);
                           setIsSocial(idToken);
                        } else {
                           setIsLoading(false);
                           showSwal(
                              "",
                              res.settings.message,
                              () => {
                                 setSignUp(true);
                              },
                              textConst.yes,
                              textConst.no
                           );
                        }
                     });
                  });
            } else {
               if (isSocialLogin) {
                  setIsLoading(false);
                  localStorage.removeItem("socialLogin");
               }
            }
         })
         .catch(function (error) {
            console.log({ error });
         });
   };

   const moveBack = () => {
      if (isSocial && step === 4) {
         setStep(0);
         setIsSocial(false);
      } else {
         setStep(step - (step === 3 ? 3 : 1));
      }
   };

   const backToLogin = () => {
      setSignUp(false);
   };

   const resendOtpLogin = () => {
      setIsLoading(true);
      resendOtpLoginMain(isPhone, savedEmail, savedPhoneNumber, () => {
         setIsLoading(false);
      });
   };

   const createProfile = (selectedUser = null) => {
      const userNew = selectedUser ? selectedUser : user;
      createProfileDob(
         userNew,
         dateOfBirth,
         true,
         fullName,
         () => {
            setIsLoading(!isLoading);
         },
         () => {
            LoginInuser(selectedUser);
         },
         (err) => {
            setStep(6);
            setIsLoading(false);
            setSameProvider(err.data.user);
         }
      );
   };

   const updateDob = (selectedUser = null) => {
      const userNew = selectedUser ? selectedUser : user;
      UpdateDobAndLogin(
         userNew,
         dateOfBirth,
         dateNew,
         false,
         fullName,
         () => {
            setIsLoading(!isLoading);
         },
         () => {
            LoginInuser(selectedUser);
         },
         (err) => {
            setIsLoading(false);
            ShowAlert(err.settings.message, "error");
         }
      );
   };
   const dobNotAvailable = (data) => {
      setFullName(data?.name);
      setStep(5);
   };
   const Signin = loginConst.patientOnboard.Signin;
   const createAccSubtitle = loginConst.patientOnboard.createAccSubtitle;
   const dob = loginConst.patientOnboard.dateOfbirth;
   const codeSent = loginConst.patientOnboard.codeSent;
   const chooseAccount = loginConst.patientOnboard.chooseAccount;
   const showLoader = isLoading;
   return (
      <div
         className={` ${showLoader ? "flex" : "sm:flex"} ${step === 0 ? "pt-10" : "pt-1"} ${
            signup ? "p-0 pt-0" : "p-5"
         }  sm:pt-0  justify-center items-center min-h-screen bg-white sm:base-back`}
      >
         {showLoader ? (
            <LoadingIndicator />
         ) : signup ? (
            <PatientContainer
               fullNameFromFirebase={fullName}
               email={savedEmail}
               phone={savedPhoneNumber}
               backToLogin={backToLogin}
            />
         ) : (
            {
               0: (
                  <PatientLogin
                     login
                     isPhone={isPhone}
                     setIsPhone={() => {
                        setIsPhone(!isPhone);
                        setSavedEmail("");
                        setSavedPhoneNumber("");
                     }}
                     nextStepHandler={checkUserExists}
                     healthcareTitle={Signin}
                     healthcareContent={createAccSubtitle}
                     step={step}
                     savedPhoneNumber={savedPhoneNumber}
                     setSavedPhoneNumber={setSavedPhoneNumber}
                     savedEmail={savedEmail}
                     setSavedEmail={setSavedEmail}
                     checkSafariSocialLogin={checkSafariSocialLogin}
                  />
               ),
               3: (
                  <PatientLogin
                     login
                     savedEmail={savedEmail}
                     savedPhoneNumber={savedPhoneNumber}
                     nextStepHandler={verifyOtp}
                     healthcareTitle={codeSent}
                     step={step}
                     otp={otp}
                     setOtp={setOtp}
                     goBack={moveBack}
                     resendOtp={resendOtpLogin}
                  />
               ),
               4: (
                  <PatientLogin
                     isSocial={isSocial}
                     countAccount={countAccount}
                     setCountAccount={setCountAccount}
                     setOtp={setOtp}
                     setStep={setStep}
                     innerLoader={innerLoader}
                     setInnerLoader={setInnerLoader}
                     user={user}
                     setRenderDob={setRenderDob}
                     renderDob={renderDob}
                     selectedUser={selectedUser}
                     setSelectedUser={setSelectedUser}
                     nextStepHandler={LoginInuser}
                     healthcareTitle={dob}
                     healthcareContent={`Please verify ${selectedUser?.name}’s date of birth `}
                     chooseAccountTitle={chooseAccount}
                     step={step}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     goBack={() => {
                        moveBack();
                        setDateNew(null);
                        setDateOfBirth(null);
                        setOtp("");
                     }}
                     customHandler={() => {
                        setDateNew(null);
                        setDateOfBirth(null);
                        setOtp("");
                        setStep(3);
                     }}
                     savedEmail={savedEmail}
                     savedPhoneNumber={savedPhoneNumber}
                     isPhone={isPhone}
                     dobNotAvailable={dobNotAvailable}
                  />
               ),
               5: (
                  <PatientLogin
                     selectedUser={selectedUser}
                     setSelectedUser={setSelectedUser}
                     fullName={fullName}
                     setFullName={setFullName}
                     nextStepHandler={createProfile}
                     healthcareTitle={loginConst.patientOnboard.welcome}
                     healthcareContent={loginConst.patientOnboard.details}
                     step={step}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     goBack={() => {
                        setDateNew(null);
                        setDateOfBirth(null);
                        setStep(4);
                     }}
                     btnText={loginConst.patientOnboard.profile}
                  />
               ),
               6: (
                  <PatientLogin
                     selectedUser={selectedUser}
                     setSelectedUser={setSelectedUser}
                     nextStepHandler={updateDob}
                     healthcareTitle={loginConst.patientOnboard.confirmDob}
                     healthcareContent={`It seems like you entered ${sameProvider?.name}’s DOB. Please Enter your DOB`}
                     step={step}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     goBack={() => {
                        moveBack();
                        setDateNew(null);
                        setDateOfBirth(null);
                     }}
                     dateNew={dateNew}
                     setDateNew={setDateNew}
                     btnText={loginConst.patientOnboard.continue}
                  />
               ),
            }[step]
         )}
      </div>
   );
};

export default Login;
